import React, { useState, useEffect, useCallback, useMemo } from "react";
import { MultiTable, Auth, useRedirect } from "admin-frontend";
import {
  Layout,
  Card,
  FormLayout,
  TextField,
  Modal,
  Select,
  Tooltip,
  Checkbox,
  Subheading,
  OptionList,
  Badge,
  Tag,
  Icon,
  DataTable,
  Heading,
  Button,
  Page as OldPage,
  Spinner
} from "admin-frontend";
import { useParams } from "react-router-dom";
import { SearchMajor, CircleDisabledMajor, AddMajor } from "admin-frontend";
import { Page } from "../../components/Page";
import { LocalizedTextField } from "../../components/Localization";
import { Stack } from "admin-frontend";
import { productFields, titleCase } from "../../components/Fields";
import { DateField, RelativeDateField, getRelativeDateLabel } from "../../components/DateFields";
import { useLocation } from "react-router-dom";
import { useSpotFetch } from "../../useSpotFetch";
import { localeFormat } from "../../components/Localization";

export function OrphanedFacets() {
  const authFetch = useSpotFetch();
  const redirect = useRedirect();

  const [orphanedFacets, setOrphanedFacets] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [facetCategories, setFacetCategories] = useState({});
  const [suppressedFacets, setSuppressedFacets] = useState([]);

  useEffect(() => {
    if (!orphanedFacets) {
      setIsLoading(true);
      authFetch("/api/facets/orphaned").then((r) => {
        setOrphanedFacets(r.facets);
        setIsLoading(false);
      });
    }
  }, [orphanedFacets]);

  const isChanged = Object.keys(facetCategories).length > 0 || suppressedFacets.length > 0;

  return (<Page
    title="Unassigned Facets"
    resourceName={{singular: "unassigned facet", plural: "unassigned facets" }}
    subtitle="A page where you can see active facets that have values that aren't assigned."
    isFullyLoading={isLoading && !orphanedFacets}
    isChanged={isChanged}
    onSave={() => {
      setIsLoading(true);
      const facetHash = {};
      Object.keys(facetCategories).forEach((idx) => {
        if (suppressedFacets.indexOf(facetCategories[idx].facet_id) == -1) {
          if (!facetHash[facetCategories[idx].facet_id])
            facetHash[facetCategories[idx].facet_id] = { orphaned: [], id: facetCategories[idx].facet_id }
          facetHash[facetCategories[idx].facet_id].orphaned.push({ idx: facetCategories[idx].idx, value: facetCategories[idx].value })
        }
      });
      suppressedFacets.forEach((id) => facetHash[id] = { id: id, orphaned: false });
      return authFetch('/api/facets/orphaned', { json: { facets: Object.values(facetHash) } }).then((r) => {
        setFacetCategories({});
        setSuppressedFacets([]);
        setOrphanedFacets(r.facets);
        setIsLoading(false);
        if (r.facets.length == 0)
          redirect('/catalog/facets');
      });
    }}
    onBack={() => { redirect('/catalog/facets'); }}
    localization
  >
    {(currentTheme, currentLocale) => {
      return (<Card title='List of Unassigned Facet Values' sectioned>
        <div
          className={`multi-table`}
        >
          <table>
            <tr>
              <th>Facet</th>
              <th>Value</th>
              <th>Parent Value</th>
            </tr>
            {orphanedFacets && orphanedFacets.flatMap((facet) => {
              const isSuppressed = suppressedFacets.indexOf(facet.id) != -1;
              return facet.orphaned.map((v,idx) => (
                <tr>
                  {idx == 0 && (<td rowSpan={facet.orphaned.length}><Stack alignment="center" spacing="extraLoose">
                    {!isSuppressed &&
                      <Tooltip content="Suppress unassigned warnings for this facet entirely." dismissOnMouseOut>
                        <Button size="slim" onClick={() => {
                          setSuppressedFacets([...suppressedFacets, facet.id]);
                        }}><Icon source={CircleDisabledMajor} color="base"/></Button>
                      </Tooltip>
                    }
                    {isSuppressed &&
                      <Tooltip content="Unsuppress unassigned warnings for this facet." dismissOnMouseOut>
                        <Button size="slim" onClick={() => {
                          setSuppressedFacets(suppressedFacets.filter((f) => f != facet.id));
                        }}><Icon source={AddMajor} color="base"/></Button>
                      </Tooltip>
                    }
                    <div style={{ marginLeft: "8px" }}>{localeFormat(currentLocale, facet.name)}</div>
                  </Stack></td>)}
                  <td>{localeFormat(currentLocale, v)}</td>
                  <td>
                    <Select
                      searchable
                      disabled={isLoading || isSuppressed}
                      options={[{ label: "Unassigned", value: "" }, ...facet.values.map((v, vidx) => { return { label: localeFormat(currentLocale, v), value: vidx } }), { label: "+ New Value", value: "+" }]}
                      onChange={(val) => {
                        setFacetCategories({ ...facetCategories, [idx]: { facet_id: facet.id, idx: val, value: localeFormat(currentLocale, v) } })
                      }}
                      value={facetCategories[idx] != null ? facetCategories[idx].idx : ""}
                    />
                  </td>
                </tr>
              ));
            })}
          </table>
        </div>
      </Card>);
    }}
  </Page>);
}
