import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import "admin-frontend/src/styles.scss"

import { App, Frame, Redirect, InstallPage, PermissionsPage, Billing, RouteContext, Auth, SideNavigation, TopNavigation, useIsEmbedded, OverlayManager } from "admin-frontend";

import { ProductSplits } from './Tabs/GlobalProperties/ProductSplits';
import { ProductMerges } from './Tabs/GlobalProperties/ProductMerges';
import { CatalogProcessing } from './Tabs/CatalogProcessing';
import { ArtificialIntelligence } from "./Tabs/ArtificialIntelligence";
import { SortOptions } from './Tabs/GlobalProperties/SortOptions';
import { MetafieldPolicy } from './Tabs/GlobalProperties/MetafieldPolicy';
import { CustomFields } from './Tabs/GlobalProperties/CustomFields';
import { ProductWeights } from './Tabs/GlobalProperties/ProductWeights';
import { Facets } from './Tabs/Facets/Facets';
import { EditFacet } from './Tabs/Facets/EditFacet';
import { OrphanedFacets } from './Tabs/Facets/OrphanedFacets';
import { FacetGroups } from './Tabs/Facets/FacetGroups';
import { SynonymGroups, OneWaySynonyms, SearchWeights, Personalizations, Redirects, SearchPins, SearchOverrides, PopularSearches, SearchConstants } from './Tabs/SearchOptions';
import { Recommendations } from './Tabs/Recommendations';
import { Badges } from './Tabs/Badges';
import { Banners } from './Tabs/Banners';
import { Swatches } from "./Tabs/Swatches";
import { Reservations } from './Tabs/Reservations';
import { Bundles } from './Tabs/Bundles';
import { BoostRules } from './Tabs/BoostRules';
import { Reporting, Explorer, Journeys } from './Tabs/Reporting';
import { Subscriptions } from './Tabs/Subscriptions';
import { SetupPreview, SetupTheme, SetupStatus, SetupClone, SetupAudit, SetupFavorites } from './Tabs/Setup';
import { CollectionRulesets } from './Tabs/CollectionDetails';
import { Rulesets, Scheduling } from './Tabs/GlobalProperties/Ruleset';
import { Waiting } from './Waiting';
import { Legal, TermsOfService } from "./Tabs/Legal";
import { Integrations } from "./Tabs/Integrations";
import { QuickStart } from './Tabs/QuickStart';
import { StatusContextProvider } from "./components/StatusContext";
import { IconMap } from "./components/IconMap";
import { Category } from "./components/Category";
import { NotFound } from './NotFound';

import "./index.css"

import { AddCodeMajor, SortAscendingMajor, AnalyticsMajor, SettingsMajor, SearchMajor, DragDropMajor, DnsSettingsMajor, ProfileMajor,
ExchangeMajor, MaximizeMajor, TipsMajor, CustomersMajor, DesktopMajor, CollectionsMajor, ViewportWideMajor, ThumbsUpMajor, DetailedPopUpMajor, LockMajor,
ThemesMajor, StoreStatusMajor, OutgoingMajor, LegalMajor, PinMinor, AffiliateMajor, Columns3Major, ExternalMinor, ViewportNarrowMajor, CalendarMajor,
IconsMajor, PinMajor, ReplaceMajor, CircleInformationMajor, PageMajor, ChatMajor, VocabularyMajor, KeyMajor, PopularMajor, CategoriesMajor, GiftCardMajor, TypeMajor, EmailNewsletterMajor, ChannelsMajor,
FeaturedCollectionMajor } from 'admin-frontend';

function Spot() {
  const [profile] = Auth.useProfile();
  const embedded = useIsEmbedded();

  let routes = useMemo(() => {
    return (profile && profile.shop.ready && !profile.shop.disabled && profile.shop.subscriptions.length > 0 ? [
      { path: "/login", access: "anonymous", icon: KeyMajor, name: "Login", render: () => <InstallPage/> },
      { path: "/catalog/facets/orphaned", render: () => <OrphanedFacets/> },
      { path: "/catalog/facets/:facetId", render: () => <EditFacet /> },
      { path: "/catalog/groups/:facetGroupId", render: () => <FacetGroups/> },
      { path: "/collections", render: () => <Redirect preserveParameters to='/merchandising/collections'/> },
      { path: "/merchandising/badges/groups/:badgeGroupId", render: () => <Badges/> },
      { path: "/merchandising/banners/:bannerId", render: () => <Banners/> },
      { path: "/merchandising/badges/:badgeId", render: () => <Badges/> },
      { path: "/analytics/journeys/:journeyId", render: () => <Journeys/> },
      { path: "/setup/integrations/:integration", render: () => <Integrations/> },
      { path: "/merchandising/boost_rules/:boostRuleId", render: () => <BoostRules/> },
      { path: "/merchandising/recommendations/:recommendationId", render: () => <Recommendations/> },
      { path: '/merchandising/rulesets/:rulesetId', render: () => <Rulesets/> },
      { path: '/search/personalizations/:personalizationId', render: () => <Personalizations/> },
      {
        name: 'Product Information',
        description: "Manage every aspect of your product catlog and its relationship to Spot.",
        icon: AddCodeMajor,
        path: "/catalog",
        exact: true,
        render: () => <Category/>,
        items: [{
          name: "Facets",
          icon: DragDropMajor,
          path: "/catalog/facets",
          description: "Create facets your customers can use to narrow down their collection or search results.",
          render: () => <Facets />
        }, {
          name: "Facet Groups",
          icon: DetailedPopUpMajor,
          description: "Organize your facets into ordered groups to present to your customers.",
          path: "/catalog/groups",
          render: () => <FacetGroups/>
        }, {
          name: 'Sort Options',
          icon: SortAscendingMajor,
          description: "Set up ways that your customers can sort through your catalog.",
          path: '/catalog/sort_options',
          render: () => <SortOptions/>
        }, {
          name: 'Custom Fields',
          icon: AddCodeMajor,
          description: "Extend your catalog and clean up your data using Liquid expressions.  Custom Fields can be used as facets, sort orders, weights and more.",
          path: '/catalog/fields',
          render: () => <CustomFields/>
        }, {
          name: 'Product Weights',
          icon: TipsMajor,
          path: '/catalog/product_weights',
          description: "This page allows you to set weights for individual products or fields, to increase their ranking on search and collection pages.",
          render: () => <ProductWeights/>
        }, {
          name: 'Color Swatches',
          description: "Optionally specify colors or images to use as swatches in Spot facets, and breadcrumbs.",
          icon: IconsMajor,
          path: '/catalog/swatches',
          render: () => <Swatches/>
        }]
      },
      { 
        name: "Merchandising", 
        description: "Merchandise all your search terms, collections and other views of your product catalog.",
        icon: SettingsMajor, 
        path: "/merchandising",
        exact: true,
        render: () => <Category/>,
        items: [{
          name: 'Display Rules',
          description: "Configure display rules that can be applied to collections, searches, or dynamically by URL.",
          icon: DesktopMajor,
          path: '/merchandising/rulesets',
          render: () => <Rulesets/>
        }, {
          name: 'Smarter Collections',
          description: "Fine control over collection merchandising and presentation.",
          icon: CollectionsMajor,
          path: '/merchandising/collections',
          render: () => <CollectionRulesets/>
        }, {
          name: 'Scheduling',
          description: "Allows you to schedule different display rules to automatically activate at different times.",
          icon: CalendarMajor,
          path: '/merchandising/scheduling',
          render: () => <Scheduling/>
        }, {
          name: 'Product Splits',
          description: "Display separate tiles for each catalog attribute you choose to split.",
          icon: ViewportWideMajor,
          path: '/merchandising/splits',
          render: () => <ProductSplits/>
        }, {
          name: 'Product Merges',
          description: "Treat multiple products as a single product tile, based on a common attribute (up to 2000 variants per merged product).",
          icon: ViewportNarrowMajor,
          path: '/merchandising/merges',
          render: () => <ProductMerges/>
        }, {
          name: "Boost Rules",
          description: "Tell Spot what catalog attributes should be used to boost products in collection, search results and recommendations.",
          icon: SortAscendingMajor,
          path: "/merchandising/boost_rules",
          render: () => <BoostRules/>
        }, {
          name: "Recommendations",
          description: "Configure recommendation panes to drive additional sales from your product pages.",
          icon: ThumbsUpMajor,
          path: "/merchandising/recommendations",
          render: () => <Recommendations/>
        }, {
          name: "Badges",
          description: "Edit the badges displayed on products based on varying conditions.",
          icon: CategoriesMajor,
          path: "/merchandising/badges",
          render: () => <Badges/>
        }, {
          name: "Banners",
          description: "Edit the promotional banners that can be displayed on collections.",
          icon: FeaturedCollectionMajor,
          path: "/merchandising/banners",
          render: () => <Banners/>
        }
      ]},
      { 
        name: "Search Options", 
        description: "Tweak how your search algoirthm functions.",
        icon: SearchMajor, 
        path: "/search",
        exact: true,
        render: () => <Category/>,
        items: [
          {
            name: 'Synonym Groups',
            description: "Search terms in a synonym group are treated as equal to each other (searching for 'pants' or 'slacks' would be equivalent).",
            icon: ExchangeMajor,
            path: '/search/synonym_groups',
            render: () => <SynonymGroups/>
          }, {
            name: 'One-Way Synonyms',
            description: "Make synonyms equal to a single search term, but not the reverse ('hat' and 'cap' would find 'beanie', but 'beanie' would not find 'hat' or 'cap')",
            icon: MaximizeMajor,
            path: '/search/one_way_synonyms',
            render: () => <OneWaySynonyms/>
          }, {
            name: 'Field Weights',
            description: 'Let Spot know which fields are more important for search.',
            icon: TipsMajor,
            path: '/search/search_weights',
            render: () => <SearchWeights/>
          },  {
            name: 'Constants',
            description: "Let Spot know how heavily you want search keywords to match terms in different ways.",
            icon: TipsMajor,
            path: '/search/search_constants',
            render: () => <SearchConstants/>
          },  {
            name: 'Personalizations',
            description: "Set up personalization schemes, and apply them in display rules to create a curated search experience per-customer.",
            icon: CustomersMajor,
            path: '/search/personalizations',
            render: () => <Personalizations/>
          }, {
            name: 'Redirects',
            description: "Send customers searching for the below terms to specific pages or collections.",
            icon: ExternalMinor,
            path: '/search/redirects',
            render: () => <Redirects/>
          }, {
            name: 'Popular Searches',
            description: "Control how Spot suggests related search terms, and add manual suggested terms to prioritize highly.",
            icon: TypeMajor,
            path: '/search/popular',
            render: () => <PopularSearches/>
          },  {
            name: 'Pins',
            description: "Pin specific products to always appear at the top of specific searches.",
            icon: PinMajor,
            path: '/search/pins',
            render: () => <SearchPins/>
          },  {
            name: 'Overrides',
            description: "Customize every aspect of the results page for specific search terms.",
            icon: ReplaceMajor,
            path: '/search/overrides',
            render: () => <SearchOverrides/>
          }, {
            name: "Preview",
            description: "This page allows you to preview search, filter, and facet results, based on the settings you've created in the Spot control panel. You can also click a product to examine its details in the context of this query, if you're searching (though be warned, this is experimental, and may not be entirely accurate).",
            icon: SearchMajor,
            path: '/setup/preview',
            render: () => <SetupPreview/>
          }
        ] 
      },
      { 
        name: "Analytics", 
        description: "Get feedback about the performance of your store.",
        icon: AnalyticsMajor, 
        path: "/analytics",
        exact: true,
        render: () => <Category/>,
        items: [
          { 
            name: "Dashboard", 
            description: "Review key metrics to help you tweak the search experience on your store.",
            icon: Columns3Major, 
            path: "/analytics/reporting", 
            render: () => <Reporting /> 
          },
          { 
            name: "Metric Explorer",
            description: "Explore metrics from your storefront, and add them to your dashboard.",
            icon: AffiliateMajor, 
            path: "/analytics/explorer", 
            render: () => <Explorer /> 
          },
          { 
            name: "Customer Journeys", 
            description: "Follow the individual actions of your customers as they navigate through your store.",
            icon: PopularMajor,
            path: "/analytics/journeys",
            render: () => <Journeys /> 
          }
        ]
      },
      { 
        name: "Setup",
        description: "Set up billing, and technical aspects of your shop.",
        exact: true,
        path: "/setup",
        render: () => <Category/>,
        icon: DnsSettingsMajor,
        items: [
          {
            name: "Account",
            description: "Manage your shop's subscription to Spot.",
            icon: ProfileMajor,
            path: "/setup/account",
            exact: true,
            render: () => <Subscriptions termsOfService={<TermsOfService/>} termsOfServiceURL={"/setup/legal"}/>
          }, {
            name: "Bundles",
            description: "Manage your shop's fine-grained plan details.",
            icon: GiftCardMajor,
            path: "/setup/account/bundles",
            render: () => <Bundles/>
          }, {
            name: 'Catalog Processing',
            description: "Modify exactly which bits of your site's data is synchronized and indexed in Spot.",
            icon: SettingsMajor,
            path: '/setup/catalog',
            render: () => <CatalogProcessing/>
          }, {
            name: 'Artifical Intelligence',
            description: "Enchance your store with powerful artficial intelligence features.",
            icon: ChannelsMajor,
            path: '/setup/ai',
            render: () => <ArtificialIntelligence/>
          }, {
            name: 'Metafield Policy',
            description: "Specify how your product metafields are synchronized into to Spot, if at all.",
            icon: LockMajor,
            path: '/setup/metafields',
            render: () => <MetafieldPolicy/>
          }, {
            name: 'Theme Integration',
            description: "Provides an easy way to integrate spot into your theme.",
            icon: ThemesMajor,
            path: '/setup/theme',
            render: () => <SetupTheme/>
          }, {
            name: 'External Integrations',
            description: "Control Spot's integrations with systems other than Shopify.",
            icon: ThemesMajor,
            path: '/setup/integrations',
            render: () => <Integrations/>
          }, {
            name: "Favorites",
            description: "Manage your list of pinned links in Spot.",
            icon: PinMajor,
            path: '/setup/favorites',
            render: () => <SetupFavorites/>
          },  {
            name: "Status",
            description: "Control Spot's integrations with systems other than Shopify.",
            subtitle: "Check on the details of how your shop is set up with Spot, and how Spot's indexing and caching systems are interacting with Shopify.",
            icon: StoreStatusMajor,
            path: '/setup/status',
            render: () => <SetupStatus/>
          }, ...(profile.shop.scalable ? [{
            name: "Reservations",
            description: "Allows you to schedule periods where your shop requires extra capacity.",
            icon: PinMinor,
            path: '/setup/reservations',
            render: () => <Reservations/>
          }] : []), ...(profile.user.other_shops && Object.keys(profile.user.other_shops).length > 0 ? [{
            name: "Cloning",
            description: "Clone settings from another store you've logged into recently.",
            icon: PinMinor,
            path: '/setup/cloning',
            render: () => <SetupClone/>
          }] : []), {
            name: "Permissions",
            description: "Set up permissions for your staff members on your shop in Spot.",
            icon: KeyMajor,
            path: '/setup/permissions',
            render: () => <PermissionsPage/>
          }, {
            name: "Quick Start Wizard",
            description: "A helpful guide to getting Spot setup on your store.",
            icon: OutgoingMajor,
            path: '/setup/quick_start',
            render: () => <QuickStart/>
          }, {
            name: "Auditing",
            description: "Consult a log of all actions taken on the Spot system by users, for at least 30 days.",
            icon: StoreStatusMajor,
            path: '/setup/audit',
            render: () => <SetupAudit/>
          }, {
            name: "Legal",
            description: "The Spot terms of service.",
            icon: LegalMajor,
            path: '/setup/legal',
            render: () => <Legal/>
          },
        ]
      },
      { name: "Help", icon: CircleInformationMajor, items: [
        {
          name: "Quick Start Guide",
          icon: PageMajor,
          url: "https://" + window.location.hostname + "/docs/quickstart"
        },
        {
          name: "Change Log",
          icon: EmailNewsletterMajor,
          url: "https://" + window.location.hostname + "/docs/changelog"
        },
        /* Removed this until Jeff actually writes it.
        {
          name: "User Guide",
          icon: PageMajor,
          url: "https://support.moddapps.com/hc/en-us/categories/360005049294-Spot"
        },*/
        {
          name: "Developer Docs",
          icon: VocabularyMajor,
          url: "https://" + window.location.hostname + "/docs"
        },
        ...(profile.user.support ? [{
          name: "Icon Map",
          path: '/help/icons',
          icon: VocabularyMajor,
          render: () => {
            return <IconMap/>
          }
        }] : []),
        {
          name: "Contact Us",
          icon: ChatMajor,
          onAction: () => {
            if (window.zE) {
              window.zE.activate();
            } else {
              let b64 = btoa(
                encodeURIComponent(
                  JSON.stringify({
                    webWidget: {
                      chat: {},
                      contactForm: {},
                    },
                  })
                )
              );
              if (window.zESettings) {
                b64 = btoa(encodeURIComponent(JSON.stringify(window.zESettings)));
              }
              window.open(
                `https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=moddapps.zendesk.com&settings=${b64}&locale=en-ca&title=Web%20Widget%20Live%20Chat`,
                'ChatWindow',
                'width=400,height=675'
              );
            }
          }
        }
      ]},
      { path: "/account", exact: true, render: () => <Redirect to="/setup/account"/> },
      { path: "/", exact: true, render: () => <Waiting/> },
      { path: "*", render: () => <NotFound/> }
    ] : [
        { path: "/login", access: "anonymous", icon: KeyMajor, name: "Login", render: () => <InstallPage/> },
        { path: "/account", exact: true, render: () => <Redirect to="/setup/account"/> },
        ...(profile && !profile.shop.subscriptions.length > 0 ? [{ icon: ProfileMajor, name: "Account", path: "/setup/account", render: () => <Subscriptions termsOfService={<TermsOfService/>} termsOfServiceURL={"/setup/legal"}/>  }] : []),
        { name: "Legal",  access: "all", icon: LegalMajor, path: '/setup/legal', render: () => <Legal/> },
        { ...(profile && profile.shop.subscriptions.length > 0 && profile.shop.disabled ? { name: "Status" } : {}), icon: SettingsMajor, path: "/", render: () => <Waiting/> },
        { exact: false, access: "anonymous", path: "/", render: () => <Redirect to="https://moddapps.com/pages/spot"/> }
    ]);
  }, [profile]);

  const favoriteRoutes = useMemo(() => profile?.user?.favorites.map((f) => ({
    name: f.title,
    path: f.url
  })), [profile]);

  return (<RouteContext routes={[{ path: "/subscriptions/onetime", access: "all", render: () => <Billing/> }, ...routes]}>
    <OverlayManager>
      <StatusContextProvider>
        <Frame topNavigation={<TopNavigation/>} sideNavigation={<SideNavigation routes={embedded && (favoriteRoutes || routes)}/>}/>
      </StatusContextProvider>
    </OverlayManager>
  </RouteContext>);
}

ReactDOM.render(
  <React.StrictMode>
    <App>
      <Spot/>
    </App>
  </React.StrictMode>,
  document.getElementById("root")
);

