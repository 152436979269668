import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Button,
  ButtonGroup,
  TextContainer,
  Heading,
  Layout,
  Icon,
  Modal,
  Banner,
  Link,
} from "admin-frontend";
import { Auth, useRedirect, Stack, CircleTickMajor, Card, CircleInformationMajor } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { QuickSaleMajor } from "admin-frontend";
import { useLocation } from "react-router-dom";
import { Page } from "../components/Page";
import { SetupThemeSelector } from "../Tabs/Setup";
import { useQuickStartStatus, useThemes } from "../components/StatusContext";

function VerticalTabs({ tabs, tabSelected, onSelect }) {
  return (<Card>
    <Stack wrap={false}>
      <Stack spacing="none" style={{minWidth: "300px"}} vertical>
        {tabs.map((tab, idx) => (
          <Button key={`tab-${idx}`} fullWidth style={{ padding: "24px", borderRadius: 0, ...(tabSelected == idx ? {
            border: "1px solid transparent",
            backgroundColor: "#fff",
            color: "rgb(0, 110, 82)",
            borderLeft: "3px solid rgb(0, 110, 82)",
          } : {
            backgroundColor: "#f8f8f8f8",
            border: "1px solid #eee",
            borderLeft: "3px solid transparent"
          }) }} textAlign="left" plain onClick={() => onSelect(idx)}>
            <Stack spacing="extraLoose" alignment="center" wrap={false}>
              <Icon
                source={tab.complete ? CircleTickMajor : CircleInformationMajor}
                color={tab.complete ? "primary" : "subdued"}
              />
              <Stack.Item>{tab.content}</Stack.Item>
            </Stack>
          </Button>
        ))}
      </Stack>
      <Stack.Item fill style={{ padding: "12px" }}>{tabs && tabs[tabSelected || 0] && tabs[tabSelected || 0].panel}</Stack.Item>
    </Stack>
  </Card>);
}

function QuickStartBarButton({
  url,
  statusId,
  path,
  children,
  idx,
}) {
  const redirect = useRedirect();
  const [quickStartStatus, setQuickStartStatus] = useQuickStartStatus();
  return (
    <Button
      onClick={() => {
        setQuickStartStatus({ ...quickStartStatus, tab: idx });
        if (path.indexOf("/setup/quick_start") !== 0)
          redirect("/setup/quick_start");
      }}
      primary={quickStartStatus[statusId]}
      pressed={
        (path.indexOf("/setup/quick_start") === 0 && idx === quickStartStatus.tab) ||
        path.indexOf(url) === 0
      }
    >
      {children}
    </Button>
  );
}

export function QuickStartBar() {
  const redirect = useRedirect();
  const [finishOpen, setFinishOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const path = useLocation().pathname;
  const authFetch = useSpotFetch();
  const [quickStartStatus, setQuickStartStatus] = useQuickStartStatus();

  useEffect(() => {
    if (quickStartStatus["facets"] === undefined) {
      authFetch("/api/setup/quickstart")
        .then((r) => {
            setQuickStartStatus(r);
        });
    }
  }, [quickStartStatus, authFetch, setQuickStartStatus]);

  function finishStatusBar() {
    setIsLoading(true);
    authFetch("/api/setup/quickstart", { method: "POST" }).then((r) => {
      setFinishOpen(false);
      setQuickStartStatus({ ...quickStartStatus, active: false });
      setIsLoading(false);
      if (path === "/setup/quick_start")
        redirect("/reporting");
    });
  }

  return (
    quickStartStatus &&
    path.indexOf("/setup/quick_start") !== 0 &&
    path !== "/" && (
      <>
        <Modal
          open={finishOpen}
          onClose={() => {
            setFinishOpen(false);
          }}
          title="Finish Quickstart"
          primaryAction={{
            content: "Finish",
            loading: isLoading,
            onAction: () => {
              finishStatusBar();
            },
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => {
                setFinishOpen(false);
              },
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                You have not finished setting up your site. Are you sure you
                want to finish the quick start?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <div
          style={{
            backgroundColor: "#fff",
            borderTop: "1px solid #CCC",
            width: "100%",
            padding: "12px",
            position: "fixed",
            bottom: "0",
            zIndex: "100000",
          }}
        >
          <Stack>
            <Stack.Item fill>
              <Stack spacing="extraLoose">
                <Button
                  destructive
                  pressed={path.indexOf("/setup/quick_start") === 0}
                  onClick={() => redirect("/setup/quick_start") }
                >
                  <Icon size="small" source={QuickSaleMajor} color="base" />
                  <div>Quick Start</div>
                </Button>
                <QuickStartBarButton
                  path={path}
                  url="/setup/catalog"
                  idx={1}
                  statusId="permissions"
                  border
                >
                  Step 1: Permissions
                </QuickStartBarButton>
                <QuickStartBarButton
                  path={path}
                  url="/catalog/facets"
                  statusId="facets"
                  idx={2}
                  border
                >
                  Step 2: Facets
                </QuickStartBarButton>
                <QuickStartBarButton
                  path={path}
                  url="/catalog/groups"
                  statusId="facet_groups"
                  idx={3}
                  border
                >
                  Step 3: Facet Groups
                </QuickStartBarButton>
                <QuickStartBarButton
                  path={path}
                  url="/merchandising/rulesets"
                  statusId="rulesets"
                  idx={4}
                  border
                >
                  Step 4: Ruleset
                </QuickStartBarButton>
                <QuickStartBarButton
                  path={path}
                  url="/setup/theme"
                  statusId="assets"
                  idx={5}
                >
                  Step 5: Theme Extension
                </QuickStartBarButton>
                <Button
                  onClick={() => {
                    if (
                      quickStartStatus.assets &&
                      quickStartStatus.facets &&
                      quickStartStatus.facet_groups &&
                      quickStartStatus.rulesets
                    ) {
                      finishStatusBar();
                    } else setFinishOpen(true);
                  }}
                  plain
                >
                  Finish
                </Button>
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      </>
    )
  );
}

export function QuickStart() {
  const redirect = useRedirect();
  function getTabIndex(quickStartStatus) {
    if (!quickStartStatus || quickStartStatus.facets === undefined) return null;
    if (!quickStartStatus.permissions) return 0;
    if (!quickStartStatus.facets) return 2;
    if (!quickStartStatus.facet_groups) return 3;
    if (!quickStartStatus.rulesets) return 4;
    if (!quickStartStatus.theme) return 5;
    return 6;
  }
  const [quickStartStatus, setQuickStartStatus] = useQuickStartStatus();
  const themes = useThemes();

  const [profile] = Auth.useProfile();
  const [selectedTheme, setSelectedTheme] = useState(
    themes && themes[0] ? parseInt(themes[0].id) : 0
  );
  const [selectedThemeObject, setSelectedThemeObject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [processOrders, setProcessOrders] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [processingUpdatedAt, setProcessingUpdatedAt] = useState(null);
  const [processingAutoUpdated, setProcessingAutoUpdated] = useState(false);

  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!isFinished) {
      if (quickStartStatus) {
        if (quickStartStatus["active"] === false)
          setQuickStartStatus({
            ...quickStartStatus,
            active: true,
            permissions: /orders/.test(profile.shop.scope),
            tab: 0
          });
        else if (quickStartStatus["facets"] !== undefined && quickStartStatus.tab === undefined)
          setQuickStartStatus({
            ...quickStartStatus,
            permissions: /orders/.test(profile.shop.scope),
            tab: getTabIndex(quickStartStatus),
          });
      }
    }
  }, [quickStartStatus, profile, isFinished, setQuickStartStatus]);

  useEffect(() => {
    if (selectedTheme === 0 && themes && themes[0]) {
      setSelectedTheme(parseInt(themes[0].id));
    }
  }, [themes, selectedTheme]);

  useEffect(() => {
    authFetch('/api/setup/quickstart').then((r) => {
      setQuickStartStatus(r);
    })
  }, []);

  const updateProcessOrders = useCallback(
    (processOrders) => {
      setIsLoading(true);
      return authFetch(
        "/api/setup/processing",
        processOrders !== null
          ? { json: { process_orders: processOrders, updated_at: processingUpdatedAt } }
          : {}
      )
        .then((r) => {
          setIsLoading(false);
          setProcessOrders(r.process_orders);
          setProcessingUpdatedAt(r.updated_at);
        });
    },
    [authFetch, setIsLoading, setProcessOrders, processingUpdatedAt]
  );

  const [hadProcessOrderUpdate] = useState(params.get("process_order_update"))

  useEffect(() => {
    if (hadProcessOrderUpdate && processingUpdatedAt && !processingAutoUpdated) {
      updateProcessOrders(/orders/.test(profile.shop.scope));
      setProcessingAutoUpdated(true);
      setQuickStartStatus({
        ...quickStartStatus,
        tab: 1
      });
    }
  }, [hadProcessOrderUpdate, profile, updateProcessOrders]);

  useEffect(() => {
    if (processOrders === null) updateProcessOrders(null);
  }, [processOrders, updateProcessOrders]);

  const tabs = [
    {
      content: "Welcome",
      complete: quickStartStatus,
      panel: (
        <TextContainer>
          <Heading>Welcome to Spot!</Heading>
          <p>
            Welcome to Spot's quickstart guide. This is the fastest way to get
            Spot up and running on your store.
          </p>
          <p>
            If you have a custom theme, you'll probably want to engage your
            theme developer to help you install Spot. If you have an
            out-of-the-box theme, Spot may work correctly automatically.
          </p>
          <p>
            All decisions made here can be changed later, so don't worry if
            you're unsure about something.
          </p>
          <p>
            Our support team is always up for discussing whatever your needs might be,
            and how Spot can support them; so please, feel free to reach out to us at any
            time through the chat in the bottom right corner of the screen, or via email
            at <a href='mailto:spot@moddapps.com'>spot@moddapps.com</a>! <b>Setup is free!</b>
          </p>
          <Button
            primary
            onClick={() => {
              setQuickStartStatus({ ...quickStartStatus, tab: quickStartStatus.tab + 1 });
            }}
          >
            Get Started!
          </Button>
        </TextContainer>
      ),
    },
    {
      content: "Choose Permissions",
      complete: quickStartStatus && quickStartStatus.permissions,
      panel: (
        <TextContainer>
          <Heading>Spot Permissions</Heading>
          <p>
            By default, Spot chooses a minimal set of permissions to ask for. By
            default, we only ask to read your product catalog, and theme.
          </p>
          <p>
            If you want Spot to do more for your store, you can selectively ask
            it to grab more data from your store, such as your order data, which
            will allow Spot to use sales-based metrics to power your search and
            collection filtering (like allowing customers to sort by
            bestselling).
          </p>
          <p>
            This is <b>optional</b> but recommended for an optimal search
            experience. You can also adjust this later.
          </p>
          <ButtonGroup>
            <Button onClick={() => redirect("/setup/catalog")}>
              View More Details
            </Button>
            {quickStartStatus && !quickStartStatus.permissions && (
              <Button
                onClick={() => {
                  setQuickStartStatus({
                    ...quickStartStatus,
                    permissions: true,
                    tab: quickStartStatus.tab + 1,
                  });
                }}
              >
                Skip this Step
              </Button>
            )}
          </ButtonGroup>
        </TextContainer>
      ),
    },
    {
      content: "Setup Facets",
      complete: quickStartStatus && quickStartStatus.facets,
      panel: (
        <TextContainer>
          <Heading>Add facets to your store</Heading>
          <p>
            Facets determine what categories your customers can filter products
            by.
          </p>
          <p>
            This isn't required to be set up, but in almost all cases, you'll
            want to provide your customers with a list of attributes for your
            customers to filter your catalog by.
          </p>
          <p>
            If you don't already have facets, you can{" "}
            <b>have Spot can make a best guess</b> at what your catalog would be
            good to filter by, by going through a list of common facets, like
            Product Type, Color, Vendor, etc..., and determining if it's
            relevant for your catalog, then adding it. It won't be too
            cluttered, but it also probably won't be optimal.
          </p>
          <p>
            Or, alternatively you can <b>customize your facets</b> manually, in
            which case you'll be taken to the facet page to set them up.
          </p>
          <p>You can always tweak your facets manually later, of course.</p>
          <ButtonGroup>
            <Button
              primary
              disabled={quickStartStatus && quickStartStatus.facets}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                authFetch("/api/setup/quickstart/default_facets", {
                  method: "POST",
                }).then((r) => {
                  setIsLoading(false);
                  setQuickStartStatus({ ...quickStartStatus, facets: true, tab: quickStartStatus.tab + 1 });
                });
              }}
            >
              Have Spot Make a Best Guess
            </Button>
            <Button
              onClick={() => redirect("/catalog/facets")}
            >
              Customize Facets
            </Button>
          </ButtonGroup>
          {quickStartStatus && !quickStartStatus.facets && (
            <Button
              onClick={() => {
                setQuickStartStatus({ ...quickStartStatus, facets: true, tab: quickStartStatus.tab + 1 });
              }}
            >
              Skip this Step
            </Button>
          )}
        </TextContainer>
      ),
    },
    {
      content: "Setup Facet Groups",
      complete: quickStartStatus && quickStartStatus.facet_groups,
      panel: (
        <TextContainer>
          <Heading>Group facets together</Heading>
          <p>
            Group your facets together to let Spot know how you want them
            displayed to customers.
          </p>
          <p>
            If you have no facet groups, but have facets, you can ask spot to{" "}
            <b>automatically create facet group</b>, in which case we'll simply
            take all your facets, and add them to one big default group.
          </p>
          <p>
            Or, you can <b>customize facet groups</b> manually, in which case
            you'll be taken to the facet group page to tweak things manually.
          </p>
          <ButtonGroup>
            <Button
              primary
              disabled={quickStartStatus && (quickStartStatus.facet_groups || !quickStartStatus.facets)}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                authFetch("/api/setup/quickstart/default_facet_groups", {
                  method: "POST",
                }).then((r) => {
                  setIsLoading(false);
                  setQuickStartStatus({
                    ...quickStartStatus,
                    facet_groups: true,
                    tab: quickStartStatus.tab + 1,
                  });
                });
              }}
            >
              Automatically Create Facet Groups
            </Button>
            <Button
              onClick={() => redirect("/catalog/groups")}>
              Customize Facet Groups
            </Button>
          </ButtonGroup>
          {quickStartStatus && !quickStartStatus.facet_groups && (
            <Button
              onClick={() => {
                setQuickStartStatus({ ...quickStartStatus, facet_groups: true, tab: quickStartStatus.tab + 1 });
              }}
            >
              Skip this Step
            </Button>
          )}
        </TextContainer>
      ),
    },
    {
      content: "Setup Ruleset",
      complete: quickStartStatus && quickStartStatus.rulesets,
      panel: (
        <TextContainer>
          <Heading>Setup rulesets</Heading>
          <p>
            Rulesets are used to determine the experience Spot will provide on
            your store as a whole. It's made up of a facet group,
            recommendations, sort options, product splits and possibly some
            custom rules.
          </p>
          <p>
            You must set a default ruleset to use across your website and on
            your search page.
          </p>
          <p>
            If you have no rulesets, you can have Spot a{" "}
            <b>automatically create ruleset</b> for you, in which case we'll
            simply create a single ruleset, add your facets, and apply it
            globally by default.
          </p>
          <p>
            Or, you can have spot <b>customize rulesets</b> manually, in which
            case you'll be taken to the ruleset page to tweak things manually.
          </p>
          <ButtonGroup>
            <Button
              primary
              disabled={quickStartStatus && (quickStartStatus.rulesets || !quickStartStatus.facet_groups || !quickStartStatus.facets)}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                authFetch("/api/setup/quickstart/default_rulesets", {
                  method: "POST",
                }).then((r) => {
                  setIsLoading(false);
                  setQuickStartStatus({ ...quickStartStatus, rulesets: true, tab: quickStartStatus.tab + 1 });
                });
              }}
            >
              Automatically Create Rulesets
            </Button>
            <Button onClick={() => redirect("/catalog/rulesets")}>
              Customize Rulesets
            </Button>
          </ButtonGroup>
          {quickStartStatus && !quickStartStatus.rulesets && (
            <Button
              onClick={() => {
                setQuickStartStatus({ ...quickStartStatus, rulesets: true, tab: quickStartStatus.tab + 1 });
              }}
            >
              Skip this Step
            </Button>
          )}
        </TextContainer>
      ),
    },
    {
      content: "Setup Theme",
      complete: quickStartStatus && quickStartStatus.assets,
      panel: (
        <TextContainer>
          <Heading>Add Spot assets to your theme</Heading>
          <p>
            Spot comes with a Javascript SDK and a helpful section that you can
            install onto your theme.
          </p>
          <p>
            This SDK can be placed onto your theme as a theme extension, or
            as a set of files uploaded onto your theme.
            You can use the dialog below to view the main snippet that you can
            manually upload to your theme, alongside the <Link external url='https://spot.moddapps.com/spot.js'>Spot SDK</Link>.
          </p>
          {selectedThemeObject && selectedThemeObject["2.0"] && (<Banner status="success">
            The recommended way of using Spot out of the box for this theme,
            is to use the Spot theme extension. You can view our guide for setting
            up the theme extension <a target="_blank" href='/docs/quickstart#spot-quickstart-theme-extension'>here</a>.
          </Banner>)}
          {selectedThemeObject && selectedThemeObject["2.0"] && (<Banner status="warning">
            If you want to set up a theme store 2.0 theme manually without the theme section, please hit "View More Details",
            and follow the instructions on that page.
          </Banner>)}
          {selectedThemeObject && selectedThemeObject.role === "main" && <Banner status="critical">
            Please note that in the quick start, you cannot apply Spot to your
            live theme; it must only be applied to a preview theme, which you
            can then push live if you're happy with it.
          </Banner>}
          <ButtonGroup>
            <SetupThemeSelector
              hideConfiguration
              hideMinification
              themes={themes}
              showLive
              disableLive
              disabled={selectedThemeObject && selectedThemeObject["2.0"]}
              selectedTheme={selectedTheme}
              onUpload={() => {
                setQuickStartStatus({ ...quickStartStatus, assets: true, tab: quickStartStatus.tab + 1 });
              }}
              setSelectedTheme={(theme_id, theme) => {
                setSelectedTheme(theme_id);
                setSelectedThemeObject(theme);
              }}
            />
            <Button onClick={() => redirect("shopify://admin/themes") }>
              Manage Themes
            </Button>
            <Button onClick={() => redirect("/setup/theme")}>
              View More Details
            </Button>
            <Button onClick={() => { setQuickStartStatus({ ...quickStartStatus, assets: true, tab: quickStartStatus.tab + 1 }); }}>
              Mark as Completed
            </Button>
          </ButtonGroup>
        </TextContainer>
      ),
    },
    {
      content: "Finishing Up",
      complete:
        quickStartStatus &&
        quickStartStatus.assets &&
        quickStartStatus.facets &&
        quickStartStatus.facet_groups &&
        quickStartStatus.rulesets,
      panel: (
        <TextContainer>
          <Heading>Finishing up</Heading>
          <p>
            <span>
              And, you're done! Your website should now be fully integrated with
              Spot. Why not test it out by trying a{" "}
            </span>
            <Link
              external={true}
              url={
                "https://" +
                profile.shop.shop_origin +
                "/search" +
                (selectedTheme ? "?preview_theme_id=" + selectedTheme : "")
              }
            >
              search
            </Link>
            <span>
              {selectedThemeObject &&
                " (preview for theme " + selectedThemeObject.name + ")"}
              , or browing your{" "}
            </span>
            <Link
              external={true}
              url={
                "https://" +
                profile.shop.shop_origin +
                "/collections/all" +
                (selectedTheme ? "?preview_theme_id=" + selectedTheme : "")
              }
            >
              collections
            </Link>
            <span>
              {selectedThemeObject &&
                " (preview for theme " + selectedThemeObject.name + ")"}
              ?
            </span>
            .
          </p>
          <p>
            There are also a number of other sections of Spot you may want to
            investigate that will help you improve the user experience, such as
            setting up{" "}
            <Link url="/merchandising/recommendations">recommendations</Link>,{" "}
            <Link url="/search/synonym_groups">synonym groups</Link> or{" "}
            <Link url="/search/one_way_synonyms">one-way-synonyms</Link>. Look
            around, and explore!
          </p>
          <Button
            primary
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              authFetch("/api/setup/quickstart", { method: "POST" }).then(
                (r) => {
                  setIsLoading(false);
                  setIsFinished(true);
                  setQuickStartStatus({ ...quickStartStatus, active: false });
                  redirect("/analytics/reporting");
                }
              );
            }}
          >
            Finish!
          </Button>
        </TextContainer>
      ),
    },
  ];

  return (
    <Page
      isFullyLoading={!quickStartStatus || quickStartStatus.tab === undefined}
    >
      {quickStartStatus && quickStartStatus.tab !== null && (
        <VerticalTabs
          tabSelected={(quickStartStatus && quickStartStatus.tab) || 0}
          tabs={tabs}
          onSelect={(idx) => {
            setQuickStartStatus({ ...quickStartStatus, tab: idx });
          }}
        />
      )}
    </Page>
  );
}
