import React, { useEffect } from 'react';
import {
  Auth,
  Page,
  Modal,
  TextContainer,
  useRedirect,
  useCreateModal,
  useLocation
} from 'admin-frontend';
import { stripPageUrl } from "./components/Page";
import { useSpotFetch } from "./useSpotFetch";


export function NotFound() {
  const createModal = useCreateModal();
  const [profile, , invalidateProfile] = Auth.useProfile();
  const location = useLocation();
  const redirect = useRedirect();
  const authFetch = useSpotFetch();
  useEffect(() => {
    const pageUrl = stripPageUrl(location);
    if (profile?.user?.favorites.filter((f) => f.url == pageUrl).length > 0) {
      createModal({
        title: "Invalid Favorite",
        secondaryActions: [{ content: "Close", onAction: () => { redirect('/'); } }],
        primaryAction: { content: "Delete", onAction: () => {
          authFetch('/api/global/merchandising', { json: { favorites: profile.user.favorites.filter((f) => f.url != pageUrl) } }).then((r) => {
            invalidateProfile();
            redirect('/');
          });
        }},
        render: () => {
          return (<Modal.Section>
            <TextContainer>
              <p>
                This favorite no longer links to a valid page. Do you want to delete it?
              </p>
            </TextContainer>
          </Modal.Section>);
        }
      });
    }
  }, [profile]);
  return (
    <Page
      title="404"
      subtitle={"There's no page at this address. Check the URL and try again, or use one of the navigation options above."}
    >
    </Page>
  );
}
