import React, {useState, useEffect, useCallback, useRef } from 'react';
import {
  Card,
  Button,
  Icon,
  Layout,
  Tag,
  TextField,
  Form,
  Spinner,
  Select,
  Label,
  Banner,
  Modal,
  ColorPicker,
  DropZone,
  Heading,
  TextContainer,
} from 'admin-frontend';
import { AuthorizationBanner, Stack, useRedirect } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { DeleteMajor, RefreshMajor, PlusMinor, CircleDisabledMajor } from 'admin-frontend';
import { Page } from "../components/Page"

export function updateSwatch(swatch, authFetch) {
  if (swatch.deleted)
    return authFetch('/api/swatches/' + swatch.id, { method: "DELETE" });
  if (!swatch.updated)
    return new Promise((resolve) => { resolve(swatch); })
  const formData  = new FormData();
  if (swatch.file)
    formData.append("file", swatch.file);
  if (swatch.temporary) {
    swatch.name = [...swatch.name, swatch.temporary];
    delete swatch["temporary"];
  }
  if (swatch.name)
    formData.append("name", JSON.stringify(swatch.name));
  if (swatch.color)
    formData.append("color", swatch.color);
  if (swatch.category)
    formData.append("category", swatch.category);
  if (swatch.finalized != null)
    formData.append("finalized", swatch.finalized);
  return authFetch("/api/swatches" + (swatch.id ? "/" + swatch.id : ""), { method: "POST", body: formData }).then((r) => Promise.resolve(r.swatches[0]));
}

export function getSwatchCSS(swatch) {
  if (swatch.color)
    return { backgroundColor: "#" + swatch.color };
  return { backgroundImage: "url('" + swatch.image + "')" };
}

export function SwatchText({ swatch, children }) {
  if (!swatch)
    return children;
  return (<Stack wrap={false} alignment='center'>
    <span style={swatch && getSwatchCSS(swatch)} className='swatch-preview-small'></span>
    <span className='swatch-text'>{children}</span>
  </Stack>);
}

export function SwatchSelector({ allowColor = true, allowImage = true, allowNames = true, value, onChange, label, category, swatches, setSwatches, ...props }) {
  const [localSwatches, localSetSwatches] = useState(null);
  const _swatches = swatches || localSwatches;
  const _setSwatches = setSwatches || localSetSwatches;
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const redirect = useRedirect();
  const [newSwatchOpen, setNewSwatchOpen] = useState(false);
  const [newSwatch, setNewSwatch] = useState(null);

  const authFetch = useSpotFetch();
  useEffect(() => {
    if (open) {
      authFetch("/api/swatches", { query: { category: category } }).then((r) => {
        _setSwatches(r.swatches);
        setIsLoading(false);
      });
    }
  }, [open]);
  const swatch = _swatches && _swatches.filter((s) => s.id == value)[0];

  const openButton = (
    <Button key={"openSwatch"} onClick={() => { setOpen(true); }} loading={isLoading} {...props}>
      <SwatchText swatch={swatch}>{!value ? "No " + (category || "Swatch") : (swatch ? swatch.name[0] : "Unnamed Swatch")}</SwatchText>
    </Button>
  );

  return (<>
    <Modal primaryAction={{ content: "Add Swatch", disabled: !newSwatch || !newSwatch.id || newSwatch.updated || newSwatch.status != 0, onAction: () => { 
      updateSwatch({ ...newSwatch, finalized: true, updated: true }, authFetch).then((r) => {
        setNewSwatchOpen(false); 
        setSwatches([...swatches, r]);
        onChange(r.id); 
      });
    } }} open={newSwatchOpen} key={"modalAddSwatch"} onClose={() => { setNewSwatchOpen(false); }} title={<Stack alignment="center" spacing="loose">
      <span>Create a New Swatch</span>
      <Button square plain onClick={() => {
        if (newSwatch.color) {
          setNewSwatch({ ...newSwatch, color: undefined, updated: true });
        } else {
          setNewSwatch({ ...newSwatch, color: "ff0000", updated: true });
        }
      }}><Icon source={RefreshMajor} /></Button>
    </Stack>}>
      
      <SwatchPicker allowNames={allowNames} allowColor={allowColor} allowImage={allowImage} setSwatch={(swatch) => {
        setNewSwatch(swatch);
        if (swatch.updated) {
          updateSwatch(swatch, authFetch).then((r) => {
            setNewSwatch(r);
          });
        }
      }} swatch={newSwatch}/>
    </Modal>
    <Modal key={"modalSwatch"}
      open={open}
      onClose={() => { setOpen(false); }}
      title={"Select a Swatch"}
    >
      <Stack vertical spacing="extraLoose">
        <Button fullWidth onClick={() => { onChange(null); setOpen(false); }}>
          <Stack alignment='center'><Icon source={CircleDisabledMajor}/><span>No Swatch</span></Stack>
        </Button>
        <Stack style={{ padding: "1rem 0" }}>
          {_swatches && _swatches.map((swatch) => {
            return (<div className='swatch-preview-card'>  
              <Button plain className='swatch-preview-card-delete' onClick={() => authFetch('/api/swatches/' + swatch.id, { method: "DELETE" }).then((r) => {
                _setSwatches(_swatches.filter((s) => s.id != swatch.id));
                if (value == swatch.id && onChange)
                  onChange(null);
              })} square><Icon source={DeleteMajor}/></Button>
              <Button plain onClick={() => { onChange(swatch.id); setOpen(false); }}>
                <Stack vertical>
                  <div>
                    <div className='swatch-preview-large' style={swatch && getSwatchCSS(swatch)} key={"swatch-" +swatch.id}></div>
                  </div>
                  {allowNames && <div style={{ textAlign: "center" }}>{swatch.name[0] || "Unnamed"}</div>}
                </Stack>
              </Button>
            </div>);
          })}
        </Stack>
        <Button fullWidth onClick={() => { setNewSwatch({ name: [], category: category }); setNewSwatchOpen(true); setOpen(false); }}>
          <Stack alignment='center'><Icon source={PlusMinor}/><span>Add Swatch</span></Stack>
        </Button>
      </Stack>
    </Modal>

    {label && <Label>{label}</Label>}
    {openButton}
  </>)
}

export function SwatchPicker({ setSwatch, swatch, allowNames = true, allowColor = true, allowImage = true }) {
  const [error, setError] = useState(null);
  const checkInterval = useRef(null);
  const authFetch = useSpotFetch();
  const loading = !swatch || swatch.status == 2 || swatch.status == 1 || swatch.loading;

  useEffect(() => {
    if (checkInterval.current)
      clearInterval(checkInterval);
    if (loading && swatch && swatch.id) {
      checkInterval.current = setInterval(() => {
        authFetch('/api/swatches/' + swatch.id).then((r) => {
          setSwatch({ ...swatch, ...r.swatches[0] });
        });
      }, 2000);
    } else if (swatch && !swatch.id && swatch.file) {
      // create provisional swatch
      updateSwatch(swatch, authFetch).then((r) => {
        setSwatch({ ...r, updated: true })
      });
    }
    return () => {
      if (checkInterval.current)
        clearInterval(checkInterval.current);
    }
  }, [loading]);
  
  return (<Stack vertical>
    {loading && <Stack alignment="center" distribution="center"><div style={{textAlign: "center"}}>Uploading to Shopify...<br/><br/><Spinner size="large"/></div></Stack>}
    {!loading && (swatch.color ? (
      <Stack vertical>
        <ColorPicker className='swatch-picker' fullWidth onChange={(v) => setSwatch({ ...swatch, updated: true, color: v.replace(/^#/, "") })} color={"#" + swatch.color} />
        <TextField onChange={(v) => {
          if (/^[a-f0-9]*$/.test(v))
            setSwatch({ ...swatch, color: v });
        }} value={"#" + swatch.color}/>
      </Stack>
    ) : (
      <Stack vertical>
        <DropZone allowMultiple={false} style={swatch.image && { backgroundImage: "url(" + swatch.image + ")", backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat' }} accept="image/*" onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
          setSwatch({ ...swatch, file: acceptedFiles[0], updated: true, error: null });
        } }>
          {swatch.error ? ((<Banner
            title="There was an error uploading your image."
            status="critical"
          >
            <p>
              Error uploading your image: {swatch.error}
            </p>
          </Banner>)) : (!swatch.image && <DropZone.FileUpload type="image" />)}
        </DropZone>
        <TextField style={{ textAlign: "center" }}disabled value={swatch.status != 0 && swatch.status ? "Uploading..." : swatch.image}/>
      </Stack>
    ))}
    {!loading && <Stack vertical>
      <Stack>
        {swatch.name.map((name) => (<Tag key={name} onRemove={() => {
          setSwatch({ ...swatch, name: swatch.name.filter((n) => n !== name), updated: true });
        }}>{name}</Tag>))}
      </Stack>
      {allowNames && <Form onSubmit={() => {
        setSwatch({ ...swatch, name: [...swatch.name, swatch.temporary], temporary: null, updated: true });
      }}>
        <TextField error={error && ("Duplicate name value: " + error)} value={swatch.temporary || ""} onChange={(v) => {
          setSwatch({ ...swatch, temporary: v, updated: true });
        }} placeholder="Color name, press enter when done."/>
      </Form>}
    </Stack>}
  </Stack>);
}

export function SwatchCard({ setSwatch, swatch, category }) {
  return (<Card
    title={swatch.color ? "Solid Swatch" : "Image Swatch"}
    actions={[
      { content: <Button square plain onClick={() => {
        if (swatch.color) {
          setSwatch({ ...swatch, color: undefined, updated: true });
        } else {
          setSwatch({ ...swatch, color: "ff0000", updated: true });
        }
      }}><Icon source={RefreshMajor} /></Button> },
      {content: <Button square plain onClick={() => { setSwatch({ ...swatch, updated: true, deleted: true }) }} square><Icon source={DeleteMajor}/></Button> }
    ]} sectioned>
      <SwatchPicker swatch={swatch} setSwatch={setSwatch}/>
  </Card>);
}

export function Swatches() {
  const [isLoading, setIsLoading] = useState(true);
  let [swatches, setSwatches] = useState(null);
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [defaultNames, setDefaultNames] = useState(null);
  const [errors, setErrors] = useState({});

  const authFetch = useSpotFetch();

  useEffect(() => {
    authFetch("/api/swatches", { query: { category: "facet" } }).then((r) => {
      setSwatches(r.swatches);
      setIsLoading(false);
    });
  }, [authFetch, setSwatches]);

  useEffect(() => {
    if (defaultNames == null) {
      setDefaultNames({});
      authFetch("/api/facets", { }).then((r) => {
        const [colorFacet] = r.facets.filter((f) => /colou?r/i.test(f.name));
        if (colorFacet) {
          authFetch("/api/facets/" + colorFacet.id).then((r) => {
            var hash = {};
            const f = r.facet;
            if (f.values) {
              f.values.forEach((fv) => {
                if (fv.value) {
                  if (typeof(fv.value) == "object") {
                    fv.value.forEach((v) => {
                      hash[v.toLowerCase().replace(/[^a-z0-9]/g, "")] = fv.name;
                    });
                  } else {
                    hash[fv.value.toLowerCase().replace(/[^a-z0-9]/g, "")] = fv.name;
                  }
                }
              });
            }
            setDefaultNames(hash);
          });
        }
      });
    }
  // eslint-disable-next-line
  }, [authFetch, setDefaultNames]);

  const getDefaultNames = useCallback((file) => {
    const transformed = file.name.toLowerCase().replace(/[^a-z0-9]/g, "");
    let names = [];
    if (defaultNames) {
      Object.keys(defaultNames).forEach((v) => {
        if (transformed.indexOf(v) !== -1)
          names.push(defaultNames[v]);
      });
    }
    return names;
  // eslint-disable-next-line
  }, [defaultNames]);

  const checkSwatchesApplyError = useCallback((swatches) => {
    var names = {};
    var errorIdx = null;
    swatches.filter((s) => !s.deleted).forEach((s, idx) => {
      s.name.forEach((name) => {
        if (names[name] !== undefined) {
          errorIdx = idx;
          setErrors({ [errorIdx]: name });
        }
        names[name] = idx;
      });
    })
    return errorIdx;
  });

  return (<Page
    permission="setup"
    audit={{resource: "Swatch"}}
    disableThemes
    onSave={swatches && swatches.filter((e) => e.updated).length > 0 && (() => {
      if (checkSwatchesApplyError(swatches) === null) {
        setIsLoading(true);
        setErrors({});
        authFetch("/api/swatches", { json: { swatches: swatches.filter((e) => e.updated).map((s) => ({ ...s, finalized: true })) } }).then((r) => {
          const newSwatchHash = Object.fromEntries(r.swatches.filter((s) => s.id).map((s) => [s.id, s]));
          const swatchHash = Object.fromEntries(swatches.filter((s) => s.id).map((s) => [s.id, s]));
          setSwatches([...swatches.filter((s) => s.id && !swatchHash[s.id].deleted).map((s) => newSwatchHash[s.id] || s), ...r.swatches.filter((s) => !swatchHash[s.id])]);
          setIsLoading(false);
        })
      }
    })}
    isLoading={swatches && swatches.filter((s) => s.status).length > 0}
    isChanged={swatches && swatches.filter((e) => e.updated || !e.finalized).length > 0}
    isFullyLoading={isLoading}
    resourceName={{
      singular: "swatch",
      plural: "swatches"
    }}
  >
    <Layout>
      <Layout.Section>
        <AuthorizationBanner style={{marginBottom: "12px"}} scopes={["write_files"]} />
        <Card sectioned>
          <TextContainer>
            <p>Swatches are optional, and represent images or colors that can be applied to your facets/breadcrumbs. Image file names will automatically be interpreted as names (red.jpg will be Red). Specify each translated facet value (Red, Rouge). For grouped facet values, enter only the facet's display name (Enter Red for a group containing "maroon", "wine" and "burgundy").</p>
            <p>If you'd like to use these in your page in a custom manner, you can use <code>swatch-&lt;color_name&gt;</code> as a class for an element, and it will apply the facet correctly as a <code>background-color</code> or as a <code>background-image</code>, if you are using <code>spot.liquid</code>.</p>
            <p>This facet information can be accessed directly on the front-end by the metafield `shop.metafields.esafilters.swatches`, which is a json field that takes the following form: </p>
            <p><code>{'[{"names": ["red","maroon"], "image": "https://cdn.shopify.com/img.png"},{"names":["green"], "color": "00FF00"},...]'}</code></p>
          </TextContainer>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <div className='swatch-grid' style={{ display: "grid", gridTemplateColumns: "33% 33% 33%", columnGap: "10px", rowGap: "10px"}} >
            {swatches && swatches.filter((s) => !s.deleted).map((s, idx) => 
              <SwatchCard key={"swatch-" + s.id} swatch={s} setSwatch={(swatch) => { setSwatches(swatches.map((sw, idx2) => idx2 == idx ? swatch : sw).filter((sw) => !sw.deleted || sw.id)) }}/>
            )}
            <Card sectioned title="New Swatch">
              <Stack vertical distribution="fill" spacing="extraLoose">
                <DropZone accept="image/*" type="image" onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
                  setSwatches([...swatches, ...acceptedFiles.map((file) => { return { file: file, image: URL.createObjectURL(file), name: getDefaultNames(file), category: "facet", updated: true } })]);
                } }>
                  <DropZone.FileUpload type="image" />
                </DropZone>
                <div style={{textAlign: "center"}}>or</div>
                <Button fullWidth onClick={() => {
                  setSwatches([...swatches, { color: 'ff0000', name: [], updated: true, category: "facet" }])
                }}>Add new Solid Swatch</Button>
              </Stack>
            </Card>
          </div>
      </Layout.Section>
    </Layout>
  </Page>);
}

