import React, { useState, useEffect, useMemo } from "react";
import {
  Page as OldPage,
  Card,
  Link,
  Button,
  FormLayout,
  TextField,
  Pagination,
  Modal,
  Checkbox,
  TextContainer,
  Badge,
  Banner,
  Icon,
  Toast,
  Select,
  Tooltip,
  Spinner,
  Popover,
  toLocaleDateTime,
  DateTimePicker,
  ArrowLeftMinor,
  ViewMajor,
  DeleteMajor,
  DataVisualizationMajor
} from 'admin-frontend';
import { Auth, MultiTable, useRedirect, useCreateModal, Stack, rearrangeArray } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { useSpotAPI, SpotState } from "../components/API";
import { ProductContextualSearch } from "../components/Product";
import "../index.css";
import { ExternalLink, Page } from "../components/Page";
import { useLocation, useHistory } from "react-router-dom";
import { useThemes } from "../components/StatusContext";

export function SetupThemeSelector({
  showLive,
  disabled,
  disableLive,
  disable20,
  selectedTheme,
  setSelectedTheme,
  selectedConfiguration,
  setSelectedConfiguration,
  selectedMinification,
  setSelectedMinification,
  hideMinification,
  hideConfiguration,
  hideThemes,
  hidePreview,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [profile] = Auth.useProfile();
  const authFetch = useSpotFetch();
  const redirect = useRedirect();

  const themes = useThemes();

  const sessionTokenFetch = Auth.useFetchSessionToken();
  const isLocal = profile && profile.shop && profile.shop.local_only;


  useEffect(() => {
    if (((!selectedTheme && themes && themes.length > 0) || selectedTheme["2.0"] === undefined) && !isLoading && (selectedTheme || (themes && themes.length > 0))) {
      authFetch("/api/setup/themes/" + (selectedTheme || themes[0].id)).then((r) => {
      setIsLoading(true);
        setIsLoading(false);
        setSelectedTheme(
          r.theme.id,
          r.theme
        );
      });
    }
  }, [selectedTheme]);

  return (
    <Stack>
      {!hideThemes && !isLocal && (
        <Select
          disabled={isLoading || !themes || themes.length === 0}
          value={selectedTheme + ""}
          onChange={(theme_id) => {
            setIsLoading(true);
            authFetch("/api/setup/themes/" + theme_id).then((r) => {
              setIsLoading(false);
              setSelectedTheme(
                theme_id,
                r.theme
              );
            });
          }}
          options={
            themes && themes.length > 0
              ? themes
                  .filter((e) => showLive || e.role !== "main")
                  .map((e) => {
                    return {
                      label: e.name + " (" + e.role + ")",
                      value: e.id + "",
                    };
                  })
              : ["Loading..."]
          }
        />
      )}
      {!hideConfiguration && (
        <Select
          disabled={isLoading && (!isLocal && (!themes || themes.length === 0))}
          value={selectedConfiguration}
          onChange={setSelectedConfiguration}
          options={[
            { label: "Configurable (Recommended)", value: "configurable" },
            { label: "Agency", value: "agency" },
            { label: "Slim Agency", value: "slim-agency" },
          ]}
        />
      )}
      {!hideMinification && (
        <Select
          disabled={isLoading || (!isLocal && (!themes || themes.length === 0))}
          value={selectedMinification}
          onChange={setSelectedMinification}
          options={[
            { label: "Minified", value: "1" },
            { label: "Unminified", value: "0" },
          ]}
        />
      )}
      {!hidePreview && (
        <Button
          primary
          onClick={() => {
            sessionTokenFetch().then((sessionToken) => {
              window.open(
                "/api/setup/section" +
                  "?type=" +
                  encodeURIComponent(selectedConfiguration) +
                  (!isLocal ? ("&id=" +
                  selectedTheme) : "") +
                  "&token=" +
                  encodeURIComponent(sessionToken)
              );
            });
          }}
        >
          <Stack alignment="center"><div>View</div><Icon size="small" source={ViewMajor}/></Stack>
        </Button>
      )}
    </Stack>
  );
}

export function SetupFavorites() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [profile, , invalidateProfile] = Auth.useProfile();
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (profile) {
      setFavorites([...profile.user.favorites]);
      setIsLoading(false);
    }
  }, [profile]);

  if (!favorites)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);
    
  return (
    <Page
      isChanged={isChanged}
      loading={isLoading}
      onSave={() => {
        setIsLoading(true);
        authFetch('/api/global/merchandising', { json: { favorites: favorites } }).then((r) => {
          setIsLoading(false);
          setIsChanged(false);
          invalidateProfile();
        });
      }}
    >
      <Card>
        <Card.Section>
          <TextContainer>
            <p>In order to add favorites to this page, and to the sidebar menu in Shopify, simply navigate to the page you'd like to favorite, and hit the pin icon in the top right corner.</p>
          </TextContainer>
        </Card.Section>
        <Card.Section>
          <MultiTable
            resourceName={{ singular: "favorite", plural: "favorites" }}
            heading={["Name", "URL", ""]}
            onDragDrop={(src, dst) => { setIsChanged(true); setFavorites(rearrangeArray(favorites, src, dst)); }}
            rows={favorites.map((f) => [<TextField onChange={(val) => {
              setIsChanged(true);
              setFavorites(favorites.map((f2) => f2 == f ? { ...f, title: val } : f2));
            }} value={f.title} />, f.url, <Button onClick={() => {
              setIsChanged(true);
              setFavorites(favorites.filter((f2) => f2 != f));
            }}>
              <Icon source={DeleteMajor} color="base"/>
            </Button>])}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}


export function SetupTheme() {
  const themes = useThemes();
  const [selectedTheme, setSelectedTheme] = useState(
    themes && themes.length > 0 ? parseInt(themes[0].id) : 0
  );
  const [selectedConfiguration, setSelectedConfiguration] =
    useState("configurable");
  const [selectedMinification, setSelectedMinification] = useState("1");

  const [profile] = Auth.useProfile();


  useEffect(() => {
    if (selectedTheme === 0 && themes && themes.length > 0) {
      setSelectedTheme(parseInt(themes[0].id));
    }
  }, [themes, selectedTheme, setSelectedTheme]);


  return (
    <Page
      permission="setup"
    >
      <Card title="Theme Extension">
        <Card.Section>
          <p>If you have an online store 2.0 theme, the easiest, and fastest way to get up and running with Spot is to use our theme extension.</p>
          <p>Setting things up with theme extension is fairly simple. Please see our <a target="_blank" href="/docs/quickstart#spot-quickstart-theme-extension-installation">quickstart guide</a> for details on how to set it up.</p>
        </Card.Section>
      </Card>
      <Card title="Asset Integration">
        <Card.Section>
          <TextContainer>
            <p>Another option, if you're running an older theme, or if you want to be able to modify the individual components of the Spot code, you
            may want to simply manually integrate Spot's assets to your theme. Click below to view the snippet to include in your theme.</p>
            <p>
              Here, you can choose the theme we should generate the Spot snippet for.{" "}
            </p>
            <p>
              In addition to the{" "}
              <ExternalLink url={process.env.PUBLIC_URL + "/docs/sdk"}>
                Spot SDK
              </ExternalLink>
              , we offer two versions of the main Spot snippet, a{" "}
              <i>configurable</i> version, and an <i>agency</i> version. This
              snippet can be previewed by hitting the preview button below.
            </p>
            <ul>
              <li>
                <i>Configurable</i>: This is the recommended version. Comes as a
                theme section, with a control panel as part of the theme settings,
                allowing the merchant to easily tweak certain front-end settings.
                Should be added to `sections/spot.liquid`.
              </li>
              <li>
                <i>Agency</i>: Comes in the form of a normal snippet, with no
                control panel. This version should used if you are a developer
                that will be making significant modifications to the Spot front-end
                components. Should be added to `snippets/spot.liquid`.
              </li>
            </ul>
            <p>
              The snippet or section should work completely automatically, and without
              configuration on the following uncustomized themes:
            </p>
          </TextContainer>
          <br />
          <Stack>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/simple">
                Simple
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/minimal">
                Minimal
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/brooklyn">
                Brooklyn
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/narrative">
                Narrative
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/supply">
                Supply
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/venture">
                Venture
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/boundless">
                Boundless
              </ExternalLink>
            </Badge>
            <Badge>
              <ExternalLink url="https://themes.shopify.com/themes/debut/styles/default">
                Debut
              </ExternalLink>
            </Badge>
          </Stack>
          <br />
          Other themes may require additional configuration.
        </Card.Section>
        <Card.Section>
          <SetupThemeSelector
            showLive={true}
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            selectedConfiguration={selectedConfiguration}
            setSelectedConfiguration={setSelectedConfiguration}
            selectedMinification={selectedMinification}
            setSelectedMinification={setSelectedMinification}
          />
          <br />
        </Card.Section>
      </Card>
    </Page>
  );
}


export function SetupPreview() {
  return (
    <Page
      fullWidth
    >
      <ProductContextualSearch controls={true} externalSearch={true}/>
    </Page>
  );
}

export function SetupAudit() {
  const [profile] = Auth.useProfile();
  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState(null);
  const [users, setUsers] = useState(null);
  const authFetch = useSpotFetch();

  const perPageMax = 25;
  const location = useLocation();
  const redirect = useRedirect();
  const history = useHistory();

  const params = useMemo(() => {
    const search = new URLSearchParams(location.search)
    const hash = {};
    for (let v of search.entries()) {
      hash[v[0]] = v[1];
    }
    return hash;
  }, [location]);
  const setParams = function(params) {
    redirect('/setup/audit?' + Object.keys(params).sort().filter((p) => params[p] != null).map((p) => p + "=" + encodeURIComponent(params[p])).join("&"));
  };


  useEffect(() => {
    setIsLoading(true);
    authFetch('/api/setup/audits', { query: params }).then((r) => {
      setIsLoading(false);
      setAudits(r.audits);
      setUsers(r.users);
    })
  }, [setAudits, params]);

  const getUserName = function(user) {
    return [user.first_name, user.last_name].join(" ") + (user.is_support ? " (Modd Support)" : "");
  };
  const userHash = users && Object.fromEntries(users.map((u) => [u.id, u]));
  const createModal = useCreateModal();

  const objectTypes = {
    ["Collection"]: "Collection",
    ["Custom Field"]: "CustomField",
    ["Boost Rule"]: "BoostRule",
    ["Display Rule"]: "Ruleset",
    ["Default Display Rule"]: "RulesetDefault",
    ["Facet"]: "Facet",
    ["Facet Group"]: "FacetGroup",
    ["One Way Synonyms"]: "OneWaySynonym",
    ["Personalization"]: "Personalization",
    ["Product Split"]: "ProductSplit",
    ["Product Merge"]: "ProductMerge",
    ["Recommendation"]: "Recommendation",
    ["Redirects"]: "Redirect",
    ["Search Weights"]: "SearchWeight",
    ["Search Pin"]: "SearchBoost",
    ["Smarter Collection"]: "Collection",
    ["Sort Option"]: "SortOption",
    ["Synonym Group"]: "SynonymGroup",
    ["Swatch"]: "Swatch",
  };
  const inverseObjectTypes = Object.fromEntries(Object.keys(objectTypes).map((key) => [objectTypes[key], key]));

  return (profile &&
    <Page
      permission="audit"
      resourceName={{ plural: "audits", singular: "audit" }}
      onBack={true}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group condensed>
              <DateTimePicker label='Start' value={params.start_date} max={new Date()} onChange={(val) => setParams({ ...params, start_date: val ? val : undefined, page: 1 })}/>
              <DateTimePicker label='End' value={params.end_date || new Date()} min={params.start_date} max={new Date()} onChange={(val) => setParams({ ...params, end_date: val ? val : undefined, page: 1 })}/>
            </FormLayout.Group>
            <FormLayout.Group condensed>
              <Select
                key="user"
                value={params.user_id + ""}
                onChange={(v) => { setParams({ ...params, user_id: v != 0 ? v : undefined, page: 1 }); }}
                options={[
                  { label: "Any User", value: "0" },
                  ...(users ? users.map((u) => { return { label: getUserName(u), value: (u.id + "") } }) : [])
                ]}
                label="User"
              />
              <TextField key="object_id" label="Object ID" onChange={(val) => { setParams({ ...params, object_id: val ? val : undefined, page: 1 }) }} value={params.object_id}/>
              <Select
                key="object"
                label="Object Type"
                value={params.object_type || ""}
                onChange={(val) => { setParams({ ...params, object_type: val ? val : undefined, page: 1 }) }}
                options={[
                  { label: "Any Object Type", value: "" },
                  ...Object.keys(objectTypes).sort().map((t) => { return { label: t, value: objectTypes[t] } }),
                ]}
              />
              <Select
                key="action"
                label="Action"
                value={params.action_type}
                onChange={(val) => { setParams({ ...params, action_type: val != "" ? val : undefined, page: 1 }) }}
                options={[
                  { label: "Any Action", value: "" },
                  { label: "Create", value: "create" },
                  { label: "Update", value: "update" },
                  { label: "Delete", value: "delete" }
                ]}
              />
              <Select
                key="view"
                label="View"
                value={params.view}
                onChange={(val) => { setParams({ ...params, view: val != "" ? val : undefined, page: 1 }) }}
                options={[
                  { label: "Related", value: "" },
                  { label: "Strict", value: "strict" },
                ]}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <MultiTable
            headings={["User", "Time", "Action", "Type", "Object", ""]}
            loading={isLoading}
            resourceName={{ singular: "log", plural: "logs" }}
            rows={!isLoading && audits && audits.map((audit) => {
              const userName = audit.user_id && userHash && userHash[audit.user_id] ? getUserName(userHash[audit.user_id]) : null;
              return [
                (userName ? <Link onClick={() => { setParams({ ...params, user_id: audit.user_id }); }}>{userName}</Link> : "None"),
                toLocaleDateTime(audit.created_at),
                <Link onClick={() => { setParams({ ...params, action_type: audit.action }) }}>{audit.action}</Link>,
                <Link onClick={() => { setParams({ ...params, object_type: audit.object_type }) }}>{inverseObjectTypes[audit.object_type] || audit.object_type}</Link>,
                (audit.object_id ? (<Link onClick={() => { setParams({ ...params, object_type: audit.object_type, object_id: audit.object_id }) }}>{(audit.name ? (audit.name + " (#" + audit.object_id + ")") :  ("#" + audit.object_id))}</Link>) : "N/A"),
                (audit.details && (<Button onClick={() => {
                  createModal({
                    title: "Details for " + audit.action + " at " + toLocaleDateTime(audit.created_at) + (userName ? (" by " + userName) : ""),
                    render: () => {
                      return (<Modal.Section>
                        <pre>{JSON.stringify(audit.details, "", 2)}</pre>
                      </Modal.Section>);
                    }
                  });
                }}><Icon source={DataVisualizationMajor} color="base" /></Button>))
              ];
            })}
          />
        </Card.Section>

        {!isLoading && <Card.Section><Stack distribution="center">
          <Pagination
            hasPrevious={params.page && params.page > 1}
            onPrevious={() => {
              setParams({ ...params, page: params.page - 1 });
            }}
            hasNext={audits && audits.length == perPageMax}
            onNext={() => {
              setParams({ ...params, page: parseInt(params.page || 1) + 1 });
            }}
          />
        </Stack></Card.Section>}
      </Card>
    </Page>
  );
}

export function SetupClone() {
  const [profile] = Auth.useProfile();
  const [cloneFrom, setCloneFrom] = useState(null);
  const [cloneConfirm, setCloneConfirm] = useState(false);
  const [cloneConfirmationText, setCloneConfirmationText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const authFetch = useSpotFetch();
  const redirect = useRedirect();

  useEffect(() => {
    if (!cloneFrom && profile.user && profile.user.other_shops)
      setCloneFrom(profile.user.other_shops[Object.keys(profile.user.other_shops).sort()[0]].id);
  }, [profile, cloneFrom, setCloneFrom]);

  return (profile && cloneFrom &&
    <Page
      permission="setup"
    >
      <Modal
        open={cloneConfirm}
        onClose={() => { setCloneConfirm(false); setCloneConfirmationText(""); }}
        title="Are you sure?"
        primaryAction={{
          content: 'Clone Store',
          disabled: isLoading || cloneConfirmationText !== "delete",
          loading: isLoading,
          onAction: () => {
            setIsLoading(true);
            authFetch("/api/setup/clone", { json: { from: cloneFrom } }).then((r) => {
              redirect("/");
            }, (r) => {
              setError(r);
              setCloneConfirm(false);
              setIsLoading(false);
              setCloneConfirmationText("");
            });
          }
        }}
        secondaryActions={[{
          content: 'Cancel',
          disabled: isLoading,
          onAction: () => {  setCloneConfirm(false); setCloneConfirmationText(""); },
        }]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Cloning shop settings to the current shop will cause <b>almost all</b> settings on this store to be deleted, and overwritten with settings from your source store. Are you sure you want to continue?
            </p>

            <p>If so, please type the word "delete" into the box below, then hit "Clone Store".</p>
            <TextField onChange={setCloneConfirmationText} value={cloneConfirmationText}/>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Card>
        {error && (<Card.Section><Banner
          title="Error"
          status="critical"
        >
          <p>
            There was an error trying to clone store settings. Don't worry, no changes have been made to your store. See the error below, and contact support if you think it's erroneous.<br/>
            {error}
          </p>
        </Banner></Card.Section>)}
        <Card.Section>
          <TextContainer>
            <p>In this section, you can <b>clone the settings</b> of another store you've logged into recently into the current one. This will clone all Facets, Facet Groups, global Display Rules, Synonym Groups, One-Way Synonyms, Splits, Merges, Recommendations, Personalization settings,
            and other data into this store.</p>

            <p><b>It will wipe the settings of the current store</b>. You should generally only use this tool if your shop is either a development shop, or your site is currently unreleased.</p>
          </TextContainer>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Select disabled options={[{ label: profile.shop.shop_origin, value: profile.shop.id }]} value={profile.shop.id}/>
              <Icon source={ArrowLeftMinor} color="base" />
              <Select value={cloneFrom} onChange={(v) => setCloneFrom(v)} options={Object.keys(profile.user.other_shops).sort().map((s) => { return { label: s, value: profile.user.other_shops[s].id + "" }; })}/>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <Button primary disabled={cloneFrom == null} onClick={() => setCloneConfirm(true)} fullWidth>Clone</Button>
        </Card.Section>
      </Card>
    </Page>
  )
}

export function SetupStatus() {
  const [profile] = Auth.useProfile();
  const [cacheStatus, setCacheStatus] = useState(null);
  const [indexStatus, setIndexStatus] = useState(null);
  const [marketStatus, setMarketStatus] = useState(null);
  const [locationStatus, setLocationStatus] = useState(null);
  const authFetch = useSpotFetch();
  let locales = (
    <p>
      Spot multilingualism is <b>disabled</b>. You can enable it by creating
      locales in your store, as per Shopify's guide{" "}
      <ExternalLink url="https://help.shopify.com/en/manual/cross-border/multilingual-online-store">
        here
      </ExternalLink>
      .
    </p>
  );
  if (profile.shop.locales.length > 1) {
    locales = (
      <>
        <p>
          Spot multilingualism is <b>enabled</b>. Your locales are listed below.
        </p>
        <br />
        <MultiTable
          headings={["Locale", "Code", "Role"]}
          rows={profile.shop.locales.map((locale, idx) => [
            locale.name,
            locale.code,
            idx === 0
              ? "Primary"
              : locale.published
              ? "Published"
              : "Unpublished",
          ])}
        />
      </>
    );
  }

  useEffect(() => {
    if (!cacheStatus)
      authFetch("/api/setup/cache").then((r) => { setCacheStatus(r); }, (r) => { setCacheStatus(false); });
  }, [authFetch]);

  useEffect(() => {
    if (!marketStatus)
      authFetch("/api/setup/markets").then((r) => { setMarketStatus(r); },(r) => { setMarketStatus(false); });
  }, [authFetch]);

  useEffect(() => {
    if (!locationStatus)
      authFetch("/api/setup/locations").then((r) => { setLocationStatus(r); },(r) => { setLocationStatus(false); });
  }, [authFetch]);

  useEffect(() => {
    if (!indexStatus)
      authFetch("/api/setup/indices").then((r) => { setIndexStatus(r); }, (r) => { setIndexStatus(false); });
  }, [authFetch]);

  return (
    <Page
      permission="status"
    >
      <Card sectioned title="Multiligualism">
        {locales}
      </Card>
      <Card sectioned title="Locations" actions={!(locationStatus && (!locationStatus.locations || locationStatus.locations.length == 0) && !/inventory/.test(profile.shop.scope)) && [{ content: "Refresh", disabled: !locationStatus, onAction: () => {
        setLocationStatus(null);
        authFetch("/api/setup/locations", { json: { } }).then((r) => {
          setLocationStatus(r);
        });;
      } }]}>
        {(!locationStatus || (locationStatus.locations && locationStatus.locations.length > 0)) && (<MultiTable
          loading={!locationStatus}
          headings={["Location", "ID", "Active", "Fulfills Online?"]}
          rows={
            locationStatus && locationStatus.locations
              ? locationStatus.locations.map((location) => {
                  return [
                    location.name,
                    location.id,
                    location.active ? "Yes" : "No",
                    location.online ? "Yes" : "No"
                  ];
                })
              : []
          }
        />)}
        {locationStatus && (!locationStatus.locations || locationStatus.locations.length == 0) && !/inventory/.test(profile.shop.scope) && (<p>
          Spot multi-location integration is <b>disabled</b>. You can enable it by creating going to "Catalog Processing", under "Setup", or by clicking <Link url="/setup/catalog">here</Link>, and enabling Multi-Location inventory.
        </p>)}
        {locationStatus && (!locationStatus.locations || locationStatus.locations.length == 0) && /inventory/.test(profile.shop.scope) && (<p>
          Spot multi-location integration is <b>enabled</b>. But has not retrieved any location information yet. Please be patient as the system gets all its ducks in a row.
        </p>)}
      </Card>
      <Card sectioned title="Markets">
        {!marketStatus || marketStatus.markets.length > 0 && (<MultiTable
          loading={!marketStatus}
          headings={["Market", "Handle", "Status", "Locale", "Rate", "Currency", "Rounding"]}
          rows={
            marketStatus
              ? marketStatus.markets.map((market) => {
                  return [
                    market.name,
                    market.handle || "n/a",
                    market.primary ? "Primary" : "Secondary",
                    market.locale || "none",
                    market.rate || 1.0,
                    market.currency,
                    <Checkbox disabled checked={market.rounding || false}/>
                  ];
                })
              : []
          }
        />)}
        {marketStatus && marketStatus.markets.length == 0 && !/markets/.test(profile.shop.scope) && (<p>
          Spot market integration is <b>disabled</b>. You can enable it by creating going to "Catalog Processing", under "Setup", or by clicking <Link url="/setup/catalog">here</Link>.
        </p>)}
        {marketStatus && marketStatus.markets.length == 0 && /markets/.test(profile.shop.scope) && (<p>
          Spot market integration is <b>enabled</b>. But has not retrieved any market information yet. Please be patient as the system gets all its ducks in a row.
        </p>)}
      </Card>
      <Card sectioned title="Cache">
        <MultiTable
          loading={cacheStatus === null}
          headings={["Item", "Last Updated", "Most Recent Item", "Status"]}
          rows={
            cacheStatus
              ? Object.keys(cacheStatus.status).map((item) => {
                  return [
                    item,
                    toLocaleDateTime(cacheStatus.status[item].last_updated),
                    toLocaleDateTime(cacheStatus.status[item].last_internal_updated),
                    cacheStatus.status[item].sync_status || "N/A",
                  ];
                })
              : []
          }
        />
      </Card>
      <Card sectioned title="Indexing">
        <MultiTable
          headings={["Cluster", "Version", "Updated", "Verified"]}
          loading={!indexStatus}
          resourceName={{ singular: "index", plural: "indices" }}
          rows={
            indexStatus
              ? indexStatus.indices.map((index) => {
                  return [
                    index.cluster_id,
                    index.version || "Unknown",
                    toLocaleDateTime(index.flushed_at),
                    toLocaleDateTime(index.checked_at),
                  ];
                })
              : []
          }
        />
      </Card>
      <Card title="SDK Version">
        <Card.Section>
          <TextContainer>
            <p>Your Spot SDK version is determined by the value of the version string at the top of your primary theme SDK <b>spot.js</b>.</p>
          </TextContainer>
          <MultiTable
            headings={["Live", "Latest", "Status"]}
            rows={[[<b>{profile.shop.sdk_version || "SDK20231101"}</b>, <b>{profile.shop.latest_sdk_version}</b>, (!profile.shop.sdk_version || (profile.shop.sdk_version < profile.shop.latest_sdk_version) ? <Badge progress="complete" status="warning">Upgradable</Badge> : (<Badge progress="complete" status="success">Up to Date</Badge>))]]}
          />
        </Card.Section>
        <Card.Section>
          <TextContainer>
            <p>It is perfectly acceptable to have an older SDK, and in fact, can be preferable. However, your SDK version will determine which features you can access (without warnings) in Spot.</p>
            <p>If you require assistance upgrading your SDK version, please do not hesitate to reach out to support.</p>
          </TextContainer>
        </Card.Section>
      </Card>
    </Page>
  );
}
