import React, {useState, useEffect, useCallback, useMemo } from 'react';
import {
  Layout,
  Card,
  ChoiceList,
  Stack, 
  Spinner,
  Page as OldPage
} from 'admin-frontend';
import { useLocation } from "react-router-dom";
import { Auth, useRedirect } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"

export function CatalogProcessing() {
  const [profile] = Auth.useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState(null);
  const [autoUpdated, setAutoUpdated] = useState(false);
  const redirect = useRedirect();

  const authFetch = useSpotFetch();
  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!processing)
      authFetch("/api/setup/processing").then((r) => setProcessing(r));
  }, []);

  const updateProcessing = useCallback((hash) => {
    setIsLoading(true);
    return authFetch("/api/setup/processing", { json: { ...hash, updated_at: processing.updated_at } })
      .then((r) => {
        setProcessing(r);
        if (r.reauth)
          redirect(r.reauth);
        else
          setIsLoading(false);
    });
  }, [processing, setIsLoading]);
  
  useEffect(() => {
    if (params && processing && !autoUpdated) {
      setAutoUpdated(true);
      if (params.get("process_order_update")) {
        updateProcessing({ process_orders: /orders/.test(profile.shop.scope) }).then((r) => {
          redirect('/setup/catalog');
        });
      }
      if (params.get("storefront_access_update")) {
        updateProcessing({ storefront_access: /unauthenticated_read_product_listings/.test(profile.shop.scope) }).then((r) => {
          redirect('/setup/catalog');
        })
      }
      if (params.get("initialize_cache")) {
        updateProcessing({ "initialize_cache": true }).then((r) => {
          redirect('/setup/catalog');
        });
      }
    }
  // eslint-disable-next-line
  }, [params && processing, updateProcessing, autoUpdated]);
  if (!processing)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);

  const disabled = isLoading || !params || params.get("process_order_update") != null || params.get("storefront_access_update") != null || params.get("initialize_cache") != null;
  return (
    <Page
      disableThemes
      permission="setup"
    >
      <Layout>
        <Layout.Section>
          <Card sectioned title="Order Processing" primaryFooterAction={{
            content: processing?.process_orders ? "Disable Order Processing" : "Activate Order Processing",
            loading: processing?.process_orders != null && !params.get("process_order_update"),
            disabled: disabled,
            onAction: () => updateProcessing({ process_orders: !processing.process_orders })
          }}>
            <p>If you activate order processing Spot can provide extra functionality like sorting results by bestselling. Spot will not store this data in any way, though it will process it to determine the total of amount of sales that have ocurred on a per-product basis. Changing this setting will redirect you to authorize new permissions.</p>
          </Card>
          <Card sectioned title="Unpublished Collection Processing" primaryFooterAction={{
            content: processing.sync_unpublished_collections ? "Disable Unpublished Collection Processing" : "Activate Unpublished Collection Processing",
            disabled: disabled,
            onAction: () => updateProcessing({ sync_unpublished_collections: !processing.sync_unpublished_collections })
          }}>
            <p>If you do activate unpublished collection procesing, Spot will index your unpublished collections. This has the advantage of having your collections always be ready when you publish them, but the disadvantage of potentailly slowing down your overall indexing process.</p>
          </Card>
          <Card sectioned title="Storefront API Access" primaryFooterAction={{
            content: processing.storefront_access ? "Disable Storefront Access" : "Enable Storefront Access",
            disabled: disabled,
            loading: processing?.storefront_access != null && !params.get("storefront_access"),
            onAction: () => updateProcessing({ storefront_access: !processing.storefront_access })
          }}>
            <p>If supplied, will automatically query the Shopify storefront API, and update most variables on products with information that is currently the most up-to-date. Introduces an additional delay in requests, but will ensure that most properties are 100% up to date.</p>
          </Card>
          <Card title="Indexed Shopify Data">
            <Card.Section>
              <p>Please be aware that activating any of the follow options below may slow your synching/indexing time slightly. Only activate them if you need to.</p>
            </Card.Section>
            <Card.Section>
              <ChoiceList
                allowMultiple
                disabled={disabled}
                title="Select the Shopify Data that Spot should make available in search results"
                choices={[
                  {
                    label: 'Products',
                    value: 'products',
                    disabled: true,
                  },
                  {
                    label: 'Collections',
                    value: 'collections',
                    disabled: true,
                  },
                  {
                    label: 'Media',
                    value: 'media',
                  },
                  {
                    label: 'Standardized Product Types /  Categories',
                    value: 'category',
                  },
                  {
                    label: 'Multi-Location Inventory',
                    value: 'inventory',
                    helpText: 'Requires read_inventory, read_locations scope',
                  },
                  {
                    label: 'Catalogs',
                    value: 'catalogs',
                    helpText: 'Requires read_publications scope'
                  },
                  {
                    label: 'Markets / Price Lists',
                    value: 'priceLists',
                    helpText: 'Requires read_markets scope',
                  },
                  {
                    label: 'Blogs',
                    value: 'blogs',
                    helpText: 'Requires read_content scope',
                  },
                  {
                    label: 'Articles',
                    value: 'articles',
                    helpText: 'Requires read_content scope',
                  },
                  {
                    label: 'Pages',
                    value: 'pages',
                    helpText: 'Requires read_content scope',
                  },
                ]}
                selected={processing.targets}
                onChange={(value) => { setProcessing({ ...processing, targets: value }); return updateProcessing({ targets: value }) }}
              />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
