import { React, useState, useRef, useEffect, Children } from 'react';

import './Grid.css';

export function rearrange(array, from, to, length = 1) {
  if (from < to)
    return [...array.slice(0, from), ...array.slice(from + length, to + length), ...array.slice(from, from + length), ...array.slice(to + length)];
  return [...array.slice(0, to), ...array.slice(from, from + length), ...array.slice(to, from), ...array.slice(from + length)];
}


class GridClass {
  constructor(element, settings) {
    if (element == null)
      throw "Requires an element.";
    this.element = element;
    this.gap = settings.gap || { x: 0, y: 0 };
    this.itemsPerRow = settings.itemsPerRow || 3;
    if (settings.cellHeight == null)
      throw "Requires a cell height.";
    this.cellHeight = settings.cellHeight;
    this.maxSpeed = settings.maxSpeed || 15;
    this.cells = [];
    this.onDragDrop = settings.onDragDrop;
    this.dragCells = [];
    this.draggedOrders = null;

    this.selectedCallback = settings.selectedCallback;

    if (settings.live) {
      this.observer = new MutationObserver(() => { this.refresh(); });
      this.observer.observe(this.element, { childList: true });
    }


    this.mouseDownHandler = (e) => {
      if (!e.target.closest("input, select, button, .grid-ignore")) {
        const cellIdx = Array.from(this.element.children).indexOf(e.target.closest(".dnd-grid > *"));
        if (cellIdx != -1) {
          if (this.cells[cellIdx].element != this.element.children[cellIdx])
            throw "Sanity check failed.";
          const cells = (this.selectedCallback ? this.selectedCallback.call(this, cellIdx) : [this.cells[cellIdx]]).filter((e) => e != null).map((e) => typeof(e) == "number" ? this.cells[e] : e);
          this.primaryDragCell = this.cells[cellIdx];
          this.dragOrigin = { x: e.pageX, y: e.pageY };
          cells.forEach((cell) => {
            cell.element.classList.add('dragging');
            cell.origin = { x: cell.x, y: cell.y };
            this.dragCells.push(cell);
          });
          this.transientOrder = [...this.cells];
          if (this.observer)
            this.observer.disconnect();
          e.preventDefault();
        }
      }
    };
    this.mouseUpHandler = (e) => {
      if (this.dragCells.length > 0) {
        let source = this.cells.length, destination = this.cells.length;
        const length = this.dragCells.length;
        this.dragCells.forEach((cell) => {
          const newDestination = this.transientOrder.indexOf(cell);
          source = Math.min(this.cells.indexOf(cell), source);
          destination = Math.min(newDestination, destination);
          Object.assign(cell, this.computeProperties(newDestination));
          cell.element.classList.remove('dragging');
          cell.element.style.transform = `translate(${cell.x}px, ${cell.y}px)`;
          cell.origin = null;
        });
        if (this.scrollingInterval)
          clearInterval(this.scrollingInterval);
        this.dragOrigin = null;
        this.dragCells = [];
        this.primaryDragCell = null;
        if (this.observer)
          this.observer.observe(this.element, { childList: true });
        this.cells = this.transientOrder;
        this.transientOrder = null;
        if (source != destination) {
          if (this.onDragDrop)
            this.onDragDrop(source, destination, length);
        }
        e.preventDefault();
      }
    };
    this.mouseMoveHandler = (e) => {
      if (this.dragOrigin) {
        if (e.buttons > 0) {
          this.dragCells.forEach((cell) => {
            cell.x = cell.origin.x + (e.pageX - this.dragOrigin.x);
            cell.y = cell.origin.y + (e.pageY - this.dragOrigin.y);
            const closestIdx = this.computeClosestCell(cell.x, cell.y);
            const cellIdx = this.transientOrder.indexOf(cell);
            if (cellIdx != closestIdx) {
              this.transientOrder = rearrange(this.transientOrder, cellIdx, closestIdx);
              for (let idx = Math.min(closestIdx, cellIdx); idx <= Math.max(closestIdx, cellIdx); ++idx) {
                if (!this.dragCells.includes(this.transientOrder[idx])) {
                  const props = this.computeProperties(idx);
                  this.transientOrder[idx].element.style.transform = `translate(${props.x}px, ${props.y}px)`;
                  this.transientOrder[idx].x = props.x;
                  this.transientOrder[idx].y = props.y;
                }
              }
            }
            cell.element.style.transform = `translate(${cell.x}px, ${cell.y}px)`;
          });
          // If in the top or bottom 10%, linear scroll speed up to `maxSpeed`.
          let scrollingDegree = 0;
          const percentage = Math.min(Math.max(e.clientY, 0.0) / window.innerHeight, 1.0);
          if (percentage < 0.1)
            scrollingDegree = -(1.0 - (percentage / 0.1)) * this.maxSpeed;
          else if (percentage > 0.9)
            scrollingDegree = ((percentage - 0.9) / 0.1) * this.maxSpeed;
          else
            scrollingDegree = 0;
          if (this.scrollingInterval)
            clearInterval(this.scrollingInterval);
          document.scrollingElement.scrollTop = document.scrollingElement.scrollTop + scrollingDegree;
          this.scrollingInterval = setInterval(() => {
            if (scrollingDegree != 0)
              document.scrollingElement.scrollTop = document.scrollingElement.scrollTop + scrollingDegree;
          }, 50);
        } else {
          this.mouseUpHandler(e);
        }
      }
    };

    if (!settings.noconnect)
      this.connect();
    window.addEventListener('resize', () => {
      this.refresh();
    });
    this.refresh();
  }

  connect() {
    if (this.onDragDrop && !this.connected) {
      this.element.addEventListener('mousedown', this.mouseDownHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
      document.addEventListener('mousemove', this.mouseMoveHandler);
      this.connected = true;
    }
  }

  disconnect() {
    this.element.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    this.connected = false;
  }

  computeClosestCell(x, y) {
    if (this.uneven) {
      let minDistance = null;
      let closestIdx = 0;
      for (let i = 0; i < this.cells.length; ++i) {
        const tx = this.cells[i].origin ? this.cells[i].origin.x : this.cells[i].x;
        const ty = this.cells[i].origin ? this.cells[i].origin.y : this.cells[i].x;
        const distance = Math.min((tx - x) * (tx - x) + (ty - y)*(ty - y));
        if (minDistance == null || distance < minDistance) {
          minDistance = distance;
          closestIdx = i;
        }
      }
      return closestIdx;
    } else {
      const idxX = Math.max(Math.min(Math.round(x / this.cellWidth), this.itemsPerRow - 1), 0);
      const idxY = Math.max(Math.min(Math.round(y / this.cellHeight), Math.ceil(this.cells.length / this.itemsPerRow) - 1), 0);
      return idxY * this.itemsPerRow + idxX;
    }
  }

  computeProperties(i, element) {
    let cx = (i % this.itemsPerRow);
    let cy = Math.floor(i / this.itemsPerRow);
    const cw = element ? parseInt(element.getAttribute('data-columns') || 1) : 1;
    const ch = element ? parseInt(element.getAttribute('data-rows') || 1) : 1;
    const w = cw * this.cellWidth;
    const h = ch * this.cellHeight;

    if (cw != 1 || ch != 1)
      this.uneven = true;

    if (!this.uneven)
      return { cx: cx, x: cx * (this.cellWidth + this.gap.x), cy: cy, y: cy * (this.cellHeight + this.gap.y), idx: i, w: w, h: h, cw: cw, ch: ch };
    if (i == 0) {
      cx = 0;
      cy = 0;
    } else {
      let hasCollision = true;
      for (let y = this.cells[i - 1].cy; hasCollision; ++y) {
        for (let x = 0; x <= this.itemsPerRow - cw; ++x) {
          hasCollision = false;
          for (let j = Math.max(i - (this.itemsPerRow + 1), 0); j < i; ++j) {
            if (y < this.cells[j].cy + this.cells[j].ch && y + ch > this.cells[j].cy && x < this.cells[j].cx + this.cells[j].cw && x + cw > this.cells[j].cx) {
              hasCollision = true;
              break;
            }
          }
          if (!hasCollision) {
            cx = x;
            cy = y;
            break;
          }
        }
      }
    }
    return { cx: cx, x: cx * (this.cellWidth + this.gap.x), cy: cy, y: cy * (this.cellHeight + this.gap.y), idx: i, cw: cw, ch: ch, w: w, h: h };
  }

  refresh() {
    const width = Math.floor((this.element.clientWidth - this.gap.x*(this.itemsPerRow - 1)) / this.itemsPerRow);
    if (width != this.cellWidth || this.cells.length != this.element.children.length || this.cells.filter((e, idx) => e.element != this.element.children[idx]).length > 0) {
      this.cellWidth = width;
      this.cells = [];
      for (let i = 0; i < this.element.children.length; ++i) {
        const element = this.element.children[i];
        const cell = { element: element, ...this.computeProperties(i, element) };
        if (!element.classList.contains("dragging")) {
          element.style.transform = `translate(${cell.x}px, ${cell.y}px)`;
          element.style.width = `${cell.w}px`;
          element.style.height = `${cell.h}px`;
        }
        this.cells.push(cell);
      };
      // Assumes that if we have a difference in cells, we are simply appending.
      if (this.transientOrder)
        this.transientOrder = [...this.transientOrder.map((e) => this.cells[e.idx]), ...this.cells.slice(this.transientOrder.length)];
      if (this.dragCells) {
        this.dragCells = this.dragCells.map((e, idx) => {
          this.cells[e.idx].origin = e.origin;
          return this.cells[e.idx]
        });
      }
      this.cellWidth = Math.floor((this.element.clientWidth - this.gap.x*(this.itemsPerRow - 1)) / this.itemsPerRow);
      this.element.style.height = (Math.ceil(this.element.children.length / this.itemsPerRow)*this.cellHeight) + "px";
    }
  }
}

export function Grid({ onDragDrop, children, itemsPerRow, cellHeight, gap, selectedCallback, isLoading, className, ...props }) {
  const elementRef = useRef(null);
  const grid = useRef(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (grid.current)
      grid.current.disconnect();
    grid.current = new GridClass(elementRef.current, {
      itemsPerRow: itemsPerRow,
      cellHeight: cellHeight,
      gap: gap,
      selectedCallback: selectedCallback,
      onDragDrop: onDragDrop
    });
    setTimeout(() => {
      setLoaded(true);
    }, 0);
    return () => {
      grid.current.disconnect();
    };
  }, [elementRef, itemsPerRow, cellHeight, gap]);
  useEffect(() => {
    if (grid.current) {
      grid.current.onDragDrop = onDragDrop;
      if (!grid.current.onDragDrop)
        grid.current.disconnect();
      else
        grid.current.connect();
      grid.current.selectedCallback = selectedCallback;
      grid.current.refresh();
    }
  }, [children, onDragDrop, selectedCallback, grid]);
  return (<div ref={elementRef} className={'dnd-grid' + (onDragDrop ? ' draggable' : '') + (isLoading || !loaded ? ' loading' : '') + (className ? ' ' + className : '')} {...props}>
    {Children.map(children, (c) => c && c.type !== Grid.Item ? (<Grid.Item key={c.key}>{c}</Grid.Item>) : c)}
  </div>);
}

Grid.Item = function({ rows = 1, columns = 1, children, ...props }) {
  return <div className='dnd-grid-item' data-rows={rows} data-columns={columns} {...props}>{children}</div>
};
