import React, { useContext, useMemo, createContext, useState, useCallback, useEffect } from "react";
import { useFetch, Auth } from "admin-frontend";
import { QuickStartBar } from "../Tabs/QuickStart";

import { SpotState } from "./API";

export const StatusContext = createContext(null);

export const useThemes = () => {
  const status = useContext(StatusContext);
  return status && status[3];
};

export const useStatus = () => {
  const status = useContext(StatusContext);
  if (status)
    return status;
  return [];
};


export const useQuickStartStatus = () => {
  const status = useContext(StatusContext);
  if (status)
    return [status[4], status[5]];
  return [];
};

export const LocaleContext = createContext(null);
export const ThemeContext = createContext(null);

export const useLocale = () => {
  const locale = useContext(LocaleContext);
  return locale;
};

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  return theme;
};


export function StatusContextProvider({ children }) {
  const [status, setStatus] = useState(null);
  const [themes, setThemes] = useState(null);
  const [theme, setTheme] = useState(null);
  const [locale, setLocale] = useState(null);
  const [quickStartStatus, setQuickStartStatus] = useState(null);
  const [synchingInterval, setSynchingInterval] = useState(null);
  const authFetch = useFetch();
  const [profile] = Auth.useProfile();


  const locales = useMemo(() => ({ locale: locale, locales: profile && profile.shop.locales }), [profile, locale]);
  useEffect(() => {
    if (!themes && profile && profile.shop.ready && (profile.shop.subscriptions || []).length > 0) {
      authFetch("/api/setup/themes").then((r) => {
        setThemes(r.themes || []);
      }, () => {
          setThemes(false);
      });
    }
  }, [profile]);

  let refreshStatus;
  refreshStatus = useCallback(function() {
    authFetch('/api/setup/status').then((r) => {
      setStatus(r.status);
    }, () => { });
  }, [authFetch, setStatus]);

  useEffect(() => {
    if (!status)
      refreshStatus();
  }, [refreshStatus]);

  useEffect(() => {
    if (status)
      setQuickStartStatus({ ...(quickStartStatus || {}), ...status.quickstart });
  }, [status])

  const isSynching = status && status.synching;

  useEffect(() => {
    if (isSynching && !synchingInterval) {
      setSynchingInterval(setInterval(() => {
        refreshStatus();
      }, status.synching.interval*1000));
    } else if (!isSynching && synchingInterval) {
      clearInterval(synchingInterval);
      setSynchingInterval(null);
    }
  }, [isSynching]);

  const statusProviderValue = useMemo(() => {
    return [status, refreshStatus, setStatus, themes, quickStartStatus, setQuickStartStatus];
  }, [status, refreshStatus, setStatus, quickStartStatus, setQuickStartStatus, themes]);

  return (<SpotState id="global" allowNoState={profile == false}><LocaleContext.Provider value={locales}><ThemeContext.Provider value={theme}><StatusContext.Provider value={statusProviderValue}>
    {children}
    {quickStartStatus && quickStartStatus.active && profile && profile.shop && profile.shop.subscriptions.length > 0 && profile.shop.ready && <QuickStartBar/>}
  </StatusContext.Provider></ThemeContext.Provider></LocaleContext.Provider></SpotState>);
}
