import React, {useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  Card,
  Layout,
  Button,
  Select,
  FormLayout,
  Badge,
  Icon,
  Checkbox,
  TextField,
  Tooltip,
  Link,
  Banner,
  ButtonGroup,
  Thumbnail,
  TextContainer,
  OptionList,
  ChoiceList
} from 'admin-frontend';
import { MultiTableForm, MultiTable, Auth, useRedirect, PermissionGuard, rearrangeArray, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { localeFormat, LocalizedTextField, LocalizedBadgeList } from "../components/Localization";
import { Page, PlaceholderImage } from "../components/Page";
import { RiskMajor, ExternalMinor, SettingsMinor } from 'admin-frontend';
import { useSpot, useSpotAPI } from "../components/API";
import { ResourceTable, ResourcePickerButton } from "../components/ResourcePicker";
import { ProductCarousel, ProductList } from "../components/Product";
import { UpgradeBanner } from "../components/UpgradeBanner";
import { useLocation, useParams } from "react-router-dom";

export function RecommendationEditor({ currentTheme, currentLocale, displayRules, onPort, subtitle, title, recommendation, boostRules, sortOptions, merges, splits, isChanged, setRecommendation, error, setError, allowNoBoostRule }) {
  const redirect = useRedirect();
  const authFetch = useSpotFetch();
  const [profile] = Auth.useProfile();
  const [previewProduct, setPreviewProduct] = useState(null);
  // const [previewVariant, setPreviewVariant] = useState(null);
  const [previewCollection, setPreviewCollection] = useState(null);
  const [previewProductManualRecommendations, setPreviewProductManualRecommendations] = useState(null);
  const [products, setProducts] = useState([]);

  const spot = useSpot();

  useEffect(() => {
    if (spot && previewProduct && previewProductManualRecommendations) {
      authFetch("/api/setup/frontend_settings").then((r) => {
        new spot.spotDOM.Recommendation(recommendation, recommendation.boost_rule_id ? new spot.spotDOM.BoostRule(r.boost_rules[recommendation.boost_rule_id]) : null, new spot.spotDOM.SortOrder(sortOptions[recommendation.sort_option_id || 0]).getUniqueId()).getQuery({ product: spot.spotAPI.convertProduct(previewProduct), collection: previewCollection, manual_recommendations: previewProductManualRecommendations }).e().done(function(products) {
          setProducts(products);
        });
      });
    } else {
      setProducts([]);
    }
  }, [previewProduct, previewCollection, recommendation, boostRules,  authFetch, spot, previewProductManualRecommendations]);


  const defaultSortOrder = sortOptions.map((e, idx) => idx).filter((idx) => sortOptions[idx].property === "sales")[0] || 0;

  var captures = /(\d+)/.exec(profile?.shop?.created_at);

	return (<>
    <Layout>
      <Layout.Section>
        <Card title="Settings" sectioned>
          <FormLayout>
            <FormLayout.Group>
              <LocalizedTextField label="Name" error={error.name} onChange={(val) => {
                setError({ ...error, name: null });
                setRecommendation({ ...recommendation, name: val });
              }} value={recommendation.name} placeholder="Recommendation Name"/>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField multiline={4} label="Description" placeholder="Optional freeform description that describes this recommendation. Does not affect functionality." value={recommendation.description} onChange={(val) => {
                setRecommendation({ ...recommendation, description: val });
              }} value={recommendation.description}/>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField type="number" error={error.limit} label="Product Count" min="1" max="12" value={recommendation.limit !== null ? (recommendation.limit +"") : "4"} onChange={(val) => { setRecommendation({ ...recommendation, limit: val }); }} />
              <Select onChange={(v) => setRecommendation({ ...recommendation, sort_option_id: parseInt(v) })} options={sortOptions.map((s, idx) => { return { label: typeof(s.label) === "object" ? s.label[Object.keys(s.label).sort()[0]] : s.label, value: `${idx}` }; })} label="Sort Order" value={(recommendation.sort_option_id != null ? recommendation.sort_option_id : defaultSortOrder) + ""}/>
              <Select onChange={(v) => setRecommendation({ ...recommendation, split_id: (v != "0" ? v : null) })} options={[{ label: "Situation Default", value: "0" }, { label: "None", value: "none" }, ...splits.map((s) => { return { label: s.handle, value: s.handle } })]} label="Split" value={(recommendation.split_id != null ? recommendation.split_id : "0") + ""}/>
              <Select onChange={(v) => setRecommendation({ ...recommendation, merge_id: (v != "0" ? v : null) })} options={[{ label: "Situation Default", value: "0" }, { label: "None", value: "none" }, ...merges.map((m) => { return { label: m.handle, value: m.handle } })]} label="Merge" value={(recommendation.merge_id != null ? recommendation.merge_id : "0") + ""}/>
              <Select onChange={(v) => setRecommendation({ ...recommendation, relevance: v })} options={[{ label: "Show any Product", value: "any" }, { label: "Show Products Matching all Criteria", value: "and" }, { label: "Show Products Matching any Criteria", value: "or" }]} label="Relevance" value={(recommendation.relevance != null ? recommendation.relevance : "any") + ""}/>
            </FormLayout.Group>
          </FormLayout>
        </Card>
      </Layout.Section>
      <Layout.Section secondary>
        <Card sectioned title="Global Display Rules">
          <OptionList
            selected={recommendation.display_rule_ids.map((id) => ""+id)}
            onChange={(val) => { setRecommendation({ ...recommendation, display_rule_ids: val.map((id) => parseInt(id)) }); }}
            options={displayRules.map((dr, idx1) => { return { label: dr.handle, value: dr.id + ""} })}
            allowMultiple
          />
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Card title="Boost Rule">
          <Card.Section>
            <Link url="/merchandising/boost_rules">Boost rules</Link> are the primary way to order the products returned from a recommendation. While it is not mandatory to use one, without one, a recommendation will always show the same products across all pages on your site.
          </Card.Section>
          <Card.Section>
            <Select label={<Stack spacing="extraTight"><span>Boost Rule</span>{!recommendation.boost_rule_id ? (
              <Tooltip content="Without a boost rule, this recommendaiton will always show the same products wherever it is displayed on your site. Are you sure you want this?" dismissOnMouseOut>
                <Icon source={RiskMajor} color="warning"/>
              </Tooltip>
            ) : (
              <Link url={"/merchandising/boost_rules/" + recommendation.boost_rule_id + "?from=" + encodeURIComponent("/globals/recommendations/" + recommendation.id)}><Icon source={ExternalMinor} color="info"/></Link>
            )}</Stack>} onChange={(v) => setRecommendation({ ...recommendation, boost_rule_id: parseInt(v) || null })} options={[...(allowNoBoostRule ? [{ label: "None", value: "0" }] : []), ...boostRules.map((r) => { return { label: r.name, value: r.id + "" }; })]} value={recommendation.boost_rule_id + ""}/>
          </Card.Section>
        </Card>
        <Card title="Preview">
          {!allowNoBoostRule && !recommendation.boost_rule_id && <Card.Section>
            In order to preview a recommendation, you must first select a boost rule above.
          </Card.Section>}
          {(allowNoBoostRule || recommendation.boost_rule_id) && <>
            {!previewProduct && <Card.Section>
              Please select a product below for which to provide recommendations.
            </Card.Section>}
            <Card.Section>
              <FormLayout>
                <FormLayout.Group condensed>
                  <ResourcePickerButton 
                    label={previewProduct ? previewProduct.title : "Select a Product"}
                    onSelection={(payload) => {
                      setPreviewProduct(payload.selection[0]);
                      setPreviewProductManualRecommendations(null);
                      if (payload.selection[0]) {
                        authFetch("/api/recommendations/products?id=" + payload.selection[0].id).then((r) => {
                          setPreviewProductManualRecommendations(r.product_recommendations[0] ? r.product_recommendations[0].product_ids : []);
                        });
                      }
                    }}
                  />
                  <ResourcePickerButton 
                    resourceType={"collection"}
                    label={previewCollection ? previewCollection.title : "No Collection"}
                    onSelection={(payload) => { setPreviewCollection(payload.selection[0]) }}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          {(previewProduct || previewCollection) && <Card.Section>
            {products && <ProductList products={products}/>}
          </Card.Section>}</>}
        </Card>
      </Layout.Section>
    </Layout>
  </>);
}

export function ProductRecommendationEditor({ product, setProduct, recommendations, sortOptions, locale }) {
  const [isLoading, setIsLoading] = useState(true);
  const [profile] = Auth.useProfile();

  const [previewRecommendations, setPreviewRecommendations] = useState([]);
  const [previewCollection, setPreviewCollection] = useState(null);
  const [previewProducts, setPreviewProducts] = useState({});
  const [displayRuleOverride, setDisplayRuleOverride] = useState(null);
  const [displayRule, setDisplayRule] = useState(null);
  const [frontendSettings, setFrontendSettings] = useState(null);

  const [productData, setProductData] = useState(null);
  const [originalProduct, setOriginalProduct] = useState(null);

  const redirect = useRedirect();
  const authFetch = useSpotFetch();
  const spot = useSpot();
  const spotAPI = useSpotAPI();

  useEffect(() => {
    if (product && !productData && originalProduct != product) {
      setOriginalProduct(product);
      if (product.product_ids && product.product_ids.length > 0) {
        spotAPI.s().id("in", product.product_ids).paginate(1000).e().done((products) => {
          setIsLoading(false);
          setProductData({ ...(productData ? productData : {}), ...Object.fromEntries(products.map((p) => [p.id, p])) });
        })
      } else {
        setIsLoading(false);
        setProductData({});
      }
    }
  }, [spotAPI, productData, product, originalProduct]);

  useEffect(() => {
    if (!frontendSettings) {
      authFetch("/api/setup/frontend_settings").then((r) => {
        setFrontendSettings(r);
      });
    }
  }, [setFrontendSettings, frontendSettings]);

  const image = spotAPI.getSizedImage(spotAPI.getProductImage(product), "64x64");
  const title = spotAPI.getProductTitle(product)
  const productRow = [[
    image ? <img alt={title} style={{ maxHeight: "64px", maxWidth: "64px" }} src={image}/> : <PlaceholderImage/>,
    title
  ]];

  const defaultSortOrder = sortOptions.map((e, idx) => idx).filter((idx) => sortOptions[idx].property === "sales")[0] || 0;

  useEffect(() => {
    if (previewRecommendations) {
      previewRecommendations.filter((pr) => !previewProducts[pr.id]).forEach((previewRecommendation) => {
        new spot.spotDOM.Recommendation(previewRecommendation, previewRecommendation.boost_rule_id ? new spot.spotDOM.BoostRule(frontendSettings.boost_rules[previewRecommendation.boost_rule_id]) : null, new spot.spotDOM.SortOrder(sortOptions[previewRecommendation.sort_order_id != null ? previewRecommendation.sort_order_id : defaultSortOrder])).getQuery({ product: spotAPI.convertProduct(product), collection: previewCollection, manual_recommendations: product.product_ids }).e().done(function(products) {
          setPreviewProducts({ [previewRecommendation.id]: products, ...previewProducts });
        });
      })
    }
  }, [previewRecommendations, previewProducts]);

  useEffect(() => {
    if (frontendSettings) {
      setPreviewProducts({});
      const siteWideDefaultDisplayRule = frontendSettings.general_rule && frontendSettings.rules[frontendSettings.general_rule];
      const collectionRule = previewCollection ? null : null;
      const displayRule = (displayRuleOverride && frontendSettings.rules[displayRuleOverride]) || collectionRule || siteWideDefaultDisplayRule;
      setPreviewRecommendations(displayRule.recommendation_ids ? displayRule.recommendation_ids.map((r) => { return { id: r, ...frontendSettings.recommendations[r] }; }) : []);
    }
  }, [displayRuleOverride, previewCollection, frontendSettings, product]);


  return (
    <Layout>
      <Layout.Section>
        <Card title="Product-Specific Recommendation">
          <Card.Section title="Product">
            <MultiTable rows={productRow}/>
          </Card.Section>
          <Card.Section>
            <Select label="Display Rule Override" onChange={(val) => { setDisplayRuleOverride(val == "0" ? null : val); }} value={displayRuleOverride || "0"} options={[{ label: "Situation Default", value: "0" }, ...(frontendSettings ? Object.keys(frontendSettings.rules).sort().map((handle) => { return { label: handle, value: handle } }) :[])]}/>
          </Card.Section>
          <Card.Section title="Manually Recommended Products">
            <ResourcePickerButton beforeQueryRun={(q) => { return q.id("not_in", [product.id, ...product.product_ids]); }} label="Add Products" primary selectMultiple={10} onSelection={(ids) => {
              setProductData({ ...productData, ...Object.fromEntries(ids.selection.map((i) => [i.id, i])) })
              setProduct({ ...product, product_ids: [...product.product_ids, ...ids.selection.map((i) => i.id)] });
            }}/>
            <br/>
            <MultiTable
              loading={isLoading || !previewProducts || !productData}
              headings={["", "Title"]}
              bulkActions={[{
                content: "Delete products",
                onAction: (ids) => { var hash = Object.fromEntries(ids.map((i) => [i, true])); setProduct({ ...product, product_ids: product.product_ids.filter((e, idx) => !hash[idx]) }); }
              }]}
              onDragDrop={(source, destination) => { setProduct({ ...product, product_ids: rearrangeArray(product.product_ids, source, destination) }) }}
              rows={product.product_ids && productData ? product.product_ids.map((id) => {
                const p = productData[id];
                const image = p ? spotAPI.getSizedImage(spotAPI.getProductImage(p), "64x64") : null;
                const title = p ? spotAPI.getProductTitle(p) : id;
                return [
                  image ? <img alt={title} style={{ maxHeight: "64px", maxWidth: "64px" }} src={image}/> : <PlaceholderImage/>,
                  title
                ]
              }) : []}
            />
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Card title="Recommendation Preview">
          <Card.Section title="Context">
            <FormLayout>
              <ResourcePickerButton label={previewCollection ? previewCollection.title : "No Collection"} resourceType="collection" onSelection={(data) => {
                setPreviewProducts({});
                setPreviewCollection(data.selection[0]);
              }}/>
            </FormLayout>
          </Card.Section>
          {previewRecommendations && previewRecommendations.map((pr) => (<Card.Section title={localeFormat(locale, pr.name)}>
            {product.product_ids && product.product_ids.length > 1 && (!pr.boost_rule_id || frontendSettings.boost_rules[pr.boost_rule_id].rules.filter((r) => r.source_field == "manual-list").length == 0) && <Banner status="warning">Be aware, that though the product you're examining has a list of manually recommended products, this recommendation does not contain a rule that takes into account manually recommended products, and as such will not vary by modifying the list above.</Banner>}
            {previewCollection && (!pr.boost_rule_id || frontendSettings.boost_rules[pr.boost_rule_id].rules.filter((r) => r.source_field == "collection").length == 0) && <Banner status="warning">Be aware, that though you've selected a particular collection context, this recommendation does not contain a rule that takes into account collection information, and does not normally vary by collection.</Banner>}
            {previewProducts[pr.id] && <ProductCarousel products={previewProducts[pr.id]}/>}
          </Card.Section>))}
        </Card>
      </Layout.Section>
    </Layout>
  );
}

export function Recommendations() {
  const [profile] = Auth.useProfile();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [recommendations, setRecommendations] = useState(null);
  const [boostRules, setBoostRules] = useState(null);
  const [sortOptions, setSortOptions] = useState(null);
  const [splits, setSplits] = useState(null);
  const [merges, setMerges] = useState(null);
  const [recommendation, setRecommendation] = useState(null);
  const [staticProducts, setStaticProducts] = useState(null);
  const [product, setProduct] = useState(null);
  const [displayRules, setDisplayRules] = useState(null);
  const [error, setError] = useState({});

  const [items, setItems] = useState(null);
  const [manuallyChangedItems, setManuallyChangedItems] = useState({});
  const [manuallySetProductIds, setManuallySetProductIds] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const redirect = useRedirect();

  const authFetch = useSpotFetch();
  const { recommendationId } = useParams();

  const rulesetHash = displayRules && Object.fromEntries(displayRules.map((r) => [r.id, r]));
  const boostRuleHash = boostRules && Object.fromEntries(boostRules.map((br) => [br.id, br]));

  useEffect(() => {
    if (!recommendations) {
      authFetch("/api/recommendations").then((r) => {
        setIsLoading(sortOptions && boostRules && displayRules && recommendations);
        setRecommendations(r.recommendations);
        setManuallySetProductIds(r.manually_set_product_ids);
      });
    }
  }, [recommendations, authFetch]);
  useEffect(() => {
    if (!displayRules) {
      authFetch("/api/global/rulesets").then((r) => {
        setIsLoading(sortOptions && boostRules && displayRules && recommendations);
        setDisplayRules(r.rulesets.filter((r) => !r.theme_id));
        setBoostRules(r.boost_rules);
        setSortOptions(r.sort_options);
        setSplits(r.splits);
        setMerges(r.merges);
      });
    }
  }, [displayRules, authFetch]);
  useEffect(() => {
    if (displayRules && recommendations && recommendations.filter((r) => !r.display_rule_ids).length > 0)
      setRecommendations(recommendations.map((r) => { return { ...r, display_rule_ids: displayRules.filter((dr) => dr.recommendation_ids && dr.recommendation_ids.includes(r.id)).map((dr) => dr.id) } }));
  }, [displayRules, recommendations]);

  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const id = params.get("id");
  const spotAPI = useSpotAPI();

  useEffect(() => {
    if (id && (!product || product.id != id)) {
      setIsLoading(true);
      spotAPI.s().id("==", id).e().done((products) => {
        if (products.length > 0) {
          authFetch("/api/recommendations/products", { query: { id: products[0].id } }).then((r) => {
            setIsLoading(false);
            setProduct({ ...products[0], ...(r.product_recommendations.length > 0 ? r.product_recommendations[r.product_recommendations.length - 1] : {}) });
          });
        }
      });
    } else if (!id && product) {
      setProduct(null);
    }
  }, [id, spotAPI, setIsLoading]);

  const canUseRecommendations = profile?.shop?.subscriptions && profile?.shop?.subscriptions[0].features?.recommendations?.value;
  var captures = /(\d+)/.exec(profile?.shop?.created_at);
  const allowNoBoostRule = (captures && parseInt(captures[1]) >= 2023) || profile?.shop?.shop_origin == "spotstaginginstall.myshopify.com";

  const beforeQueryRun = useCallback((sq) => {
    if (appliedFilters['manual']) {
      if (appliedFilters['manual'] == 'manual')
        return sq.id("in", manuallySetProductIds);
      else
        return sq.id("not_in", manuallySetProductIds);
    }
    return sq;
  }, [appliedFilters]);


  const needsToRedirect = recommendations && ((recommendationId && (!recommendation || (recommendationId != "new" && recommendation.id != recommendationId) || (recommendationId == "new" && recommendation.id)) || (!recommendationId && recommendation)));

  useEffect(() => {
    if (needsToRedirect && boostRules && sortOptions && displayRules && splits && merges && recommendations.filter((r) => !r.display_rule_ids).length == 0) {
      const selectedRecommendation = recommendationId && recommendationId != "new" && recommendations.filter((r) => r.id == recommendationId)[0];
      setRecommendation(selectedRecommendation ? selectedRecommendation : (recommendationId ? {  rules: [], boost_rule_id: (allowNoBoostRule ? null : boostRules[0].id), limit: "4", display_rule_ids: [] } : null));
    }
  }, [recommendationId, needsToRedirect, boostRules, boostRules, sortOptions, displayRules, splits, merges, recommendations]);

  const isFullyLoading = !boostRules || !recommendations || !sortOptions || !displayRules || (id && !product) || needsToRedirect;

  return (<PermissionGuard permission="boost-rules"><Page
    isFullyLoading={isFullyLoading}
    resourceName={{singular: "Recommendation", plural: "Recommendations"}}
    audit={recommendation && recommendation.id && {resource: "Recommendation", id: recommendation.id}}
    isChanged={isChanged}
    isLoading={isLoading || isFullyLoading}
    onPort={() => { }}
    onBack={(recommendation && (() => { redirect("/merchandising/recommendations"); })) || (product && (() => {
      redirect("/merchandising/recommendations");
      setStaticProducts(null);
    }))}
    onSave={(recommendation && ((theme) => {
      if (typeof(recommendation.name) != "object" && /^\s*$/.test(recommendation.name)) {
        setError({ ...error, name: "Requires a name." });
        return;
      }
      if (recommendation.limit.length === 0) {
        setError({ ...error, limit: "You must specify a limit." });
        return;
      }
      setIsLoading(true);
      authFetch("/api/recommendations" + (recommendation.id ? "/" + recommendation.id : ""), { json: recommendation }).then((r) => {
        setIsLoading(false);
        setError({});
        setIsChanged(false);
        setRecommendations(!recommendation.id ? [...recommendations, { ...r, applied: 0 }] : recommendations.map((e,idx) => e.id === r.id ? { ...r, applied: e.applied } : e));
        redirect('/merchandising/recommendations');
      });
    })) || (product && ((theme) => {
      setIsLoading(true);
      authFetch("/api/recommendations/products", { json: { product_recommendations: [{ product_id: product.id, product_ids: product.product_ids, ruleset_id: product.ruleset_id }] } }).then((r) => {
        setIsLoading(false);
        setProduct(null);
        setStaticProducts(null);
        setIsChanged(false);
      });
    })) || ((theme) => {
      setIsLoading(true);
      authFetch("/api/recommendations/products", { json: { product_recommendations: Object.values(manuallyChangedItems) } }).then((r) => {
        setIsLoading(false);
        setIsChanged(false);
        const hash = Object.fromEntries(r.product_recommendations.map((p) => [p.product_id, p]));
        setItems(items.map((i) => {
          return hash[i.id] ? { ...i, ...hash[i.id] } : i;
        }));
        setManuallyChangedItems({});
      });
    })}
    overrideThemes
    localization
    disableThemes
    spotContext
  >
    {(currentTheme, currentLocale) => (!allowNoBoostRule && (!boostRules || boostRules.length === 0)) ? (<Card sectioned>
      <p>In order to set up a recommendation, you must first define a <Link url="/globals/boost_rules">boost rule</Link>.</p>
    </Card>) : (<>
      {!canUseRecommendations && [<UpgradeBanner singular="Recommendation"/>,<br/>]}
      {recommendation && (<RecommendationEditor
        error={error}
        setError={setError}
        boostRules={boostRules}
        splits={splits}
        merges={merges}
        sortOptions={sortOptions}
        displayRules={displayRules}
        allowNoBoostRule={allowNoBoostRule}
        setRecommendation={(recommendation) => {
          setRecommendation(recommendation);
          setIsChanged(true);
        }}
        recommendation={recommendation}

      />)}
      {product && (<ProductRecommendationEditor
        products={staticProducts}
        locale={currentLocale}
        recommendations={recommendations}
        sortOptions={sortOptions}
        setProducts={(products) => { setStaticProducts(products); setIsChanged(true); }}
        product={product}
        setProduct={(product) => {
          setProduct(product);
          setIsChanged(true);
        }}
      />)}
      {!recommendation && !product && (<><MultiTableForm
        isFullyLoading={isFullyLoading}
        bulkActions={[{
          content: "Delete recommendations",
          onAction: (ids) => {
            authFetch("/api/recommendations", { method: "DELETE", json: { ids: ids.map((idx) => recommendations[idx].id) }}).then((r) => {
              let deleted = Object.fromEntries(ids.map((i) => [i,1]));
              setRecommendations(recommendations.filter((e, idx) => !deleted[idx]));
            });
          }
        }]}
        onNew={canUseRecommendations ? (() => { redirect("/merchandising/recommendations/new"); }) : null}
        resourceName={{singular: "recommendation", plural: "recommendations"}}
        headings={[
          "Name",
          "Boost Rule",
          "Applied Display Rules",
          (!currentLocale && profile.shop.locales.length > 1 && "Locales")
        ]}
        loading={isLoading || isFullyLoading}
        onRowClick={(row, idx) => { redirect("/merchandising/recommendations/" + recommendations[idx].id); }}
        rows={recommendations && recommendations.map((r) => [
          localeFormat(currentLocale, r.name),
          (<LocalizedBadgeList locale={currentLocale} values={boostRuleHash[r.boost_rule_id] ? [boostRuleHash[r.boost_rule_id].name] : []} emptyTooltip="Without a boost rule, this recommendaiton will always show the same products wherever it is displayed on your site. Are you sure you want this?"/>),
          (<LocalizedBadgeList locale={currentLocale} values={r.rulesets.map((id) => rulesetHash[id] ? rulesetHash[id].handle : id)} emptyTooltip="Because this recommendation is not part of any display rules, it will never be shown on the customer-facing part of your website. To use this recommendation, click on it, and then add it to a display rule, using the pane labelled 'Global Display Rules' on the right hand side."/>),
          (!currentLocale && profile.shop.locales.length > 1 && (<Stack>{(r.locales || profile.shop.locales.map((l) => l.code)).map((l, idx) => (<Badge key={`badge-${idx}`}>{l}</Badge>))}</Stack>))
        ])}
      />
      {displayRules && <Card sectioned title="Product Specific Recommendations">
        <ResourceTable
          selectMultiple={0}
          items={items}
          appliedFilters={appliedFilters['manual'] ? [
            {
              key: 'manual',
              label: appliedFilters['manual'] == 'manual' ? "Has Manual Settings" : "Doesn't have Manual Settings",
              onRemove: () => {
                setAppliedFilters({ ...appliedFilters, manual: null });
              }
            }
          ] : []}
          beforeQueryRun={beforeQueryRun}
          filters={[{ key: "manual-recommendations", label: "Manual Recommendations", hideClearButton: true, onClearAll: () => { setAppliedFilters({}); }, filter: (<ChoiceList
            selected={appliedFilters['manual']}
            choices={[{ label: "Has Manual Settings", value:"manual" }, { label: "Doesn't have Manual Settings", value: "not-manual" }]}
            onChange={(value) => {
              setAppliedFilters({ ...appliedFilters, manual: value })
            }}
          />) }]}
          setItems={(items) => {
            authFetch("/api/recommendations/products", { query: { id: items.map((i) => i.id) } }).then((r) => {
              const hash = Object.fromEntries(r.product_recommendations.map((p) => [p.product_id, p]));
              setItems(items.map((i) => {
                return { ...i, ruleset_id: hash[i.id] ? hash[i.id].ruleset_id : null, product_ids: hash[i.id] ? hash[i.id].product_ids : [], ...(manuallyChangedItems[i.id] ? manuallyChangedItems[i.id] : {}) }
              }));
            });
          }}
          headings={["", "", "Title", "Display Rule", "Manual Products", ""]}
          row={(product) => {
            const image = spotAPI.getSizedImage(spotAPI.getProductImage(product), "64x64");
            const rulesetId = manuallyChangedItems[product.id] != null ? manuallyChangedItems[product.id].ruleset_id : product.ruleset_id;
            return [
              (image ? (<Thumbnail
                source={image}
                size="medium"
                alt={spotAPI.getProductTitle(product)}
              />) : <PlaceholderImage/>),
              spotAPI.getProductTitle(product),
              <Select value={rulesetId ? rulesetId + "" : "0"} onChange={(val) => {
                setManuallyChangedItems({ ...manuallyChangedItems, [product.id]: {
                  ruleset_id: val != "0" ? parseInt(val) : null,
                  product_id: product.id
                } });
                setIsChanged(true);
              }} options={[{label: "Situation Default", value: "0" }, ...(displayRules ? displayRules.map((dr) => { return { label: dr.handle, value: dr.id + "" } }) : [])]}/>,
              product.product_ids.length,
              <Button square onClick={() => {
                setProduct(product);
                redirect("/merchandising/recommendations?id=" + product.id);
              }}>
                <Icon source={SettingsMinor}/>
              </Button>
            ];
        }}/>
      </Card>}
      </>)}
    </>)}
  </Page></PermissionGuard>);
}

