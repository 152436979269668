import React, {useState, useEffect, useCallback} from 'react';
import {
  Layout,
  Checkbox,
  TextField,
  Form,
  Spinner,
  Icon,
  Tag,
  Button,
  FormLayout,
  Select,
  Badge,
  Modal,
  EmptyState,
  Page as OldPage,
  TextContainer,
  Tooltip,
  Link
} from 'admin-frontend';
import { ArrowRightMinor, DeleteMajor, CircleInformationMajor } from "admin-frontend";
import { MultiTableForm, Auth, rearrangeArray, MultiTable, useRedirect, Stack, Card, RangeSlider, RadioButton } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page, PlaceholderImage } from "../components/Page";
import { LocalizedTextField, localeFormat, LocalizedBadgeList } from "../components/Localization";
import { FieldSpecifier, convertToFrontend, convertFromFrontend, titleCase } from "../components/Fields";
import { RulesetSidebar } from "../components/RulesetSidebar";
import { Prompt } from "react-router-dom";
import { ResourcePickerButton } from "../components/ResourcePicker";
import { useSpotAPI } from "../components/API";
import { useParams } from "react-router-dom";

function SynonymEditor({ endpoint, singular, plural, subtitle, groups, resource }) {
  const [loadingState, setLoadingState] = useState(true);
  const [loadedSynonyms, setLoadedSynonyms] = useState(false);
  const [synonyms, setSynonyms] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [isEditingSynonymOpen, setIsEditingSynonymOpen] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [editingEnabled, setEditingEnabled] = useState(true);
  const [editingSearchTerm, setEditingSearchTerm] = useState(null);
  const [editingSearchTermList, setEditingSearchTermList] = useState([]);
  const [editingSynonym, setEditingSynonym] = useState('');
  const [editingSynonymList, setEditingSynonymList] = useState([]);
  const [editingError, setEditingError] = useState({});
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!loadedSynonyms) {
      authFetch("/api/search/" + endpoint)
        .then((r) => {
            setSynonyms(r.synonyms)
            setLoadedSynonyms(true);
            setLoadingState(false);
            setUpdatedAt(r.updated_at);
        });
      }
  }, [loadedSynonyms, authFetch, endpoint]);

  const submitCallback = useCallback(() => {
    if (!groups) {
      if (editingSearchTerm != '') {
        setEditingSearchTermList([...editingSearchTermList, editingSearchTerm]);
        setEditingSearchTerm('');
      }
    }
    if (editingSynonym.length === 0)
      return;
    const lowerCase = editingSynonym.toLowerCase();
    if (editingSynonymList.filter((s) => s.toLowerCase() === lowerCase).length > 0) {
      setEditingError({ ...editingError, term: "Synonym already exists." });
      return;
    }
    if (synonyms.filter((s) => s.id !== editingId).flatMap((s) => s.terms).filter((t) => t.toLowerCase() === lowerCase).length > 0) {
      setEditingError({ ...editingError, duplicate: editingSynonym });
      return;
    }
    setEditingSynonymList(editingSynonymList.concat([editingSynonym]));
    setEditingSynonym('');
  }, [editingSynonym, editingSynonymList, synonyms, editingError, editingId]);

  const normalSynonyms = synonyms && synonyms.filter((synonym) => !synonym.ai);
  const aiSynonyms = synonyms && synonyms.filter((synonym) => synonym.ai);

  return (
  <Page
    title={plural}
    disableThemes
    subtitle={subtitle}
    permission="synonyms"
    audit={{resource: resource}}
  >
    {isEditingSynonymOpen && <Prompt message={(location, action) => "You're still editing a synonym. Are you sure you want to discard changes?"}/>}
    <Layout>
      <Layout.Section>
        <Modal
          open={editingError.duplicate}
          onClose={() => { setEditingError({ ...editingError, duplicate: null }); }}
          title="Duplicate Synonym Detected"
          primaryAction={{
            content: 'Add Anyway',
            onAction: () => {
              setEditingSynonymList(editingSynonymList.concat([editingSynonym]));
              setEditingSynonym('');
              setEditingError({ ...editingError, duplicate: null });
            }
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => { setEditingError({ ...editingError, duplicate: null }); },
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                The word {editingError.duplicate} is already assigned to another synonym group. Adding it to a second group will force both groups to be returned in your users search results. Are you sure you want to add this synonym to a second group?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <MultiTableForm
          editingForm={[
            (!groups && <Form onSubmit={submitCallback}>
              <TextField disabled={loadingState} error={editingError.search_term} onChange={(val) => {
                if (/,/.test(val)) {
                  setEditingSearchTerm(val.replace(/,/, ""));
                  setEditingError({ ...editingError, search_term: null });
                  submitCallback();
                } else {
                  setEditingSearchTerm(val);
                  setEditingError({ ...editingError, search_term: null });
                }
              } } value={editingSearchTerm} label="Search Term"/>
            </Form>),
            (!groups && (<Stack>{editingSearchTermList.map((synonym) => <Tag disabled={loadingState} key={synonym} onRemove={() => { setEditingSearchTermList(editingSearchTermList.filter((e) => e !== synonym))}}>{synonym}</Tag>)}</Stack>)),
            (<Checkbox disabled={loadingState} label="Enabled" onChange={setEditingEnabled} checked={editingEnabled}/>),
            (<Form onSubmit={submitCallback}>
              <TextField disabled={loadingState} error={editingError.term} onChange={(val) => {
                if (/,/.test(val)) {
                  setEditingSynonym(val.replace(/,/, ""));
                  setEditingError({ ...editingError, term: null });
                  submitCallback();
                } else {
                  setEditingSynonym(val);
                  setEditingError({ ...editingError, term: null });
                }
              } } value={editingSynonym} label="Synonyms"/>
            </Form>),
            (<Stack>{editingSynonymList.map((synonym) => <Tag disabled={loadingState} key={synonym} onRemove={() => { setEditingSynonymList(editingSynonymList.filter((e) => e !== synonym))}}>{synonym}</Tag>)}</Stack>)
          ]}
          onNew={() => {
            setEditingId(null);
            setEditingEnabled(true);
            setEditingSearchTerm('');
            setEditingSynonymList([]);
            setEditingSearchTermList([]);
            setEditingSynonym('');
            setIsEditingSynonymOpen(true);
            setEditingError({ });
          }}
          onDiscard={() => setIsEditingSynonymOpen(false)}
          onSave={() => {
            let synonymList = editingSynonymList;
            let searchTermList = editingSearchTermList;
            if (editingSearchTerm != null && editingSearchTerm.length > 0) {
              searchTermList = [...editingSearchTermList, editingSearchTerm];
              setEditingSearchTermList(searchTermList);
              setEditingSearchTerm('');
            }
            if (editingSynonym.length > 0) {
              synonymList = synonymList.concat([editingSynonym])
              setEditingSynonymList(synonymList);
              setEditingSynonym('');
            }

            if (groups && synonymList.length <= 1) {
              setEditingError({ ...editingError, term: "Requires at least two synonyms." });
              return;
            } else if (!groups && synonymList.length === 0) {
              setEditingError({ ...editingError, term: "Requires at least one synonym." });
              return;
            } else if (!groups && searchTermList.length === 0) {
              setEditingError({ ...editingError, search_term: "Please enter a search term." });
              return;
            } else if (!groups) {
              const lowerCase = editingSearchTerm.toLowerCase();
              if (synonyms.map((s) => s.searchterm.toLowerCase()).filter((s) => s === lowerCase).length > 0) {
                setEditingError({ ...editingError, search_term: "Search terms must be unique." });
                return;
              }
            }
            setLoadingState(true);
            authFetch("/api/search/" + endpoint, { json: { synonyms: [{
              id: editingId,
              searchterm: (!groups ? searchTermList.join(",") : undefined),
              terms: synonymList,
              active: editingEnabled,
            }], updated_at: updatedAt }, method: "POST" })
              .then((r) => {
                  r.synonyms.forEach((synonym) => {
                    let target = editingId ? synonyms.filter((e) => e.id === editingId)[0] : null;
                    if (!target) {
                      synonyms.push({});
                      target = synonyms[synonyms.length-1];
                    }
                    Object.assign(target, synonym);
                  });
                  setLoadingState(false);
                  setUpdatedAt(r.updated_at);
                  setIsEditingSynonymOpen(false);
              });
          }}
          isEditing={isEditingSynonymOpen}
          onRowClick={(row, idx) => {
            let synonym = normalSynonyms[idx];
            if (!synonym.ai) {
              setEditingId(synonym.id);
              setEditingEnabled(synonym.active);
              if (synonym.searchterm) {
                setEditingSearchTerm('');
                setEditingSearchTermList(synonym.searchterm.split(/\s*,\s*/));
              }
              setEditingSynonymList(synonym.terms);
              setEditingSynonym('');
              setIsEditingSynonymOpen(true);
            }
          }}
          resourceName={{
            singular: singular,
            plural: plural
          }}
          bulkActions={[{
            content: "Delete " + plural,
            onAction: (deletingSynonyms) => {
              setLoadingState(true);
              authFetch("/api/search/" + endpoint, { json: { ids: deletingSynonyms.map((idx) => synonyms[idx].id) }, method: "DELETE" })
                .then(() => {
                  let synonymHash = Object.fromEntries(deletingSynonyms.map((e) => [e, 1]))
                  setSynonyms(synonyms.filter((e, idx) => !synonymHash[idx]));
                  setLoadingState(false)
                });
            }
          }, {
            content: "Toggle " + plural,
            onAction: (togglingSynonyms) => {
              setLoadingState(true);
              let synonymHash = Object.fromEntries(togglingSynonyms.map((e) => [e, 1]));
              authFetch("/api/search/" + endpoint, { json: { synonyms: synonyms.filter((e, idx) => synonymHash[idx]).map(function(e) {
                return {
                  ...e,
                  active: !e.active
                };
              }) }, method: "POST" })
                .then((r) => {
                    setSynonyms(synonyms.filter((e, idx) => !synonymHash[idx]).concat(r.synonyms));
                    setLoadingState(false);
                });
            }
          }]}
          headings={!groups ? [
            'Search Term',
            '',
            'Synonyms',
            'State'
          ] : [
            'Synonyms',
            'State'
          ]}
          disabled={loadingState}
          loading={!loadedSynonyms}
          rows={synonyms && synonyms.filter((synonym) => !synonym.ai).map((synonym, idx) => !groups ? [
              (<Stack>{synonym.searchterm.split(/\s*,\s*/).map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
              (<Icon source={ArrowRightMinor} />),
              (<Stack>{synonym.terms.map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
              (synonym.active ? "Enabled" : "Disabled")
          ] : [
              (<Stack>{synonym.terms.map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
              (synonym.active ? "Enabled" : "Disabled")
          ])}
        />
        {aiSynonyms && aiSynonyms.length > 0 && <Card sectioned title="AI Controlled Synonyms">
          <MultiTable
            headings={!groups ? [
              'Search Term',
              '',
              'Synonyms',
            ] : [
              'Synonyms',
            ]}
            rows={aiSynonyms.map((synonym, idx) => !groups ? [
                (<Stack>{synonym.searchterm.split(/\s*,\s*/).map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
                (<Icon source={ArrowRightMinor} />),
                (<Stack>{synonym.terms.map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
            ] : [
                (<Stack>{synonym.terms.map((e) => <Badge key={e}>{e}</Badge>)}</Stack>),
            ])}
          />
        </Card>}
      </Layout.Section>
    </Layout>
  </Page>);
}


export function SynonymGroups() {
  return (
       <SynonymEditor
         endpoint="synonym_groups"
         plural="Synonym Groups"
         singular="Synonym Group"
         resource={"SynonymGroup"}
         groups={true}
       />
  );
}

export function OneWaySynonyms() {
  return (
     <SynonymEditor
       endpoint="one_way_synonyms"
       plural="One-Way Synonyms"
       singular="One-Way Synonym"
       resource={"OneWaySynonym"}
       groups={false}
     />
  );
}

function SearchWeight({ label, weight, onChange }) {
  const [searchWeight, setSearchWeight] = useState(weight);

  return (<Stack>
    <RangeSlider
      output
      label={label}
      value={searchWeight}
      suffix={<div>{searchWeight}</div>}
      min={0}
      max={100}
      step={1}
      onChange={(w) => { setSearchWeight(w); onChange(w); }}
    />
  </Stack>)
}

export function SearchWeights() {
  const [isChanged, setIsChanged] = useState(false);
  const [searchWeights, setSearchWeights] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(null);
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!searchWeights) {
      authFetch("/api/search/weights")
        .then((r) => {
            setSearchWeights(r.search_weights);
            setUpdatedAt(r.updated_at);
        });
    }
  }, [searchWeights, authFetch]);

  function saveWeights() {
    setIsLoading(true);
    authFetch("/api/search/weights", { method: "POST", json: {"search_weights": searchWeights, updated_at: updatedAt } })
      .then((r) => {
        setIsChanged(false);
        setIsLoading(false);
        setSearchWeights(r.search_weights);
        setUpdatedAt(r.updated_at);
    });
  }

  return (
    <Page
      disableThemes
      audit={{resource:"SearchWeight"}}
      isFullyLoading={!searchWeights}
      isChanged={isChanged}
      isLoading={isLoading}
      permission="product-and-search-weights"
      onSave={() => saveWeights()}
    >{() => (<Layout>
        <Layout.Section>
          <Card sectioned title="Product Level Fields">
            <Stack distribution="spaceBetween">
              {Object.keys(searchWeights).sort().filter((e) => searchWeights[e].level === "product").map((e) => {
                return <SearchWeight key={searchWeights[e].label} weight={searchWeights[e].weight} label={searchWeights[e].label === "Priority" ? "Product Weight" : searchWeights[e].label} onChange={(weight) => {
                  searchWeights[e].weight = weight;
                  setIsChanged(true);
                }}/>
              })}
            </Stack>
          </Card>
          <Card sectioned title="Variant Level Fields">
            <Stack distribution="spaceBetween">
              {Object.keys(searchWeights).sort().filter((e) => searchWeights[e].level === "variant").map((e) => {
                return <SearchWeight key={searchWeights[e].label} weight={searchWeights[e].weight} label={searchWeights[e].label} onChange={(weight) => {
                  searchWeights[e].weight = weight;
                  setIsChanged(true);
                }}/>
              })}
            </Stack>
          </Card>
          <Card sectioned title="Specific Fields">
            {searchWeights.specifics && searchWeights.specifics.length > 0 && <MultiTable
              heading={["Field", "Weight", ""]}
              rows={searchWeights.specifics && searchWeights.specifics.map((s, idx1) => {
                const field = convertToFrontend(s.field);
                return [
                  <FieldSpecifier
                    type={["string", "list"]}
                    chooseFromList={true}
                    specifiersOnly={true}
                    field={field}
                    onChange={(val) => {
                      setSearchWeights({ ...searchWeights, specifics: searchWeights.specifics.map((e, idx2) => (idx1 === idx2 ? { field: convertFromFrontend(val), weight: e.weight } : searchWeights.specifics[idx2])) });
                      setIsChanged(true);
                    } }
                  />,
                  <RangeSlider
                    output
                    value={s.weight}
                    suffix={<div>{s.weight}</div>}
                    min={0}
                    max={100}
                    step={1}
                    onChange={(w) => {
                      setSearchWeights({ ...searchWeights, specifics: searchWeights.specifics.map((e, idx2) => idx1 === idx2 ? { field: e.field, weight: w } : searchWeights.specifics[idx2])  })
                      setIsChanged(true);
                    } }
                  />,
                  (<Button onClick={() => { setIsChanged(true); setSearchWeights({ ...searchWeights, specifics: searchWeights.specifics.filter((e, idx2) => idx1 !== idx2)}) }}><Icon source={DeleteMajor} color="base" /></Button>)
                ];
              })}
            />}
            <Button primary onClick={() => {
              setSearchWeights({ ...searchWeights, specifics: [...(searchWeights.specifics || []), { field: {"tag":""}, weight: 1 }] });
              setIsChanged(true);
            }}>Add a Specific Field Weight</Button>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card sectioned>
            <MultiTable
              headings={[
                'Property',
                'Weight',
              ]}
              rows={Object.keys(searchWeights).sort((a,b) => searchWeights[b].weight < searchWeights[a].weight ? -1 : (searchWeights[b].weight > searchWeights[a].weight ? 1 : 0)).map((e) => [searchWeights[e].label, searchWeights[e].weight])}
            />
          </Card>
        </Layout.Section>
      </Layout>
    )}</Page>
  );
}

export function SearchConstants() {
  const [isChanged, setIsChanged] = useState(false);
  const [searchConstants, setSearchConstants] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(null);
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!searchConstants) {
      authFetch("/api/search/constants")
        .then((r) => {
            setSearchConstants(r.search_constants);
            setUpdatedAt(r.updated_at);
        });
    }
  }, [searchConstants, authFetch]);

  function saveConstants() {
    setIsLoading(true);
    authFetch("/api/search/constants", { method: "POST", json: {"search_constants": searchConstants, updated_at: updatedAt } })
      .then((r) => {
        setIsChanged(false);
        setIsLoading(false);
        setSearchConstants(r.search_constants);
        setUpdatedAt(r.updated_at);
    });
  }

  const tooltips = {
    "bigram_match": "A bigram match occurs when two words occur near each other, in order. For example, a bigram match would trigger if you search 'Eagles Jersey', and you have a product that has the words 'Eagles Jersey' in the title.",
    "exact_match": "An exact match occurs for a single word when it exactly matches something on a document. Like the user searching 'Eagles', and that word being contained exactly on a product's title.",
    "partial_match": "A partial, or fuzzy match occurs when the user types a word, and it matches the beginning of another word. As an example, if the user typed 'Eag', and the word 'Eagles' was contained on a product, this would partially match that.",
    "partial_short_match": "The same as above, however, for two or less characters; searches this short tend to mean less.",
    "stem_match": "A stem match occurs when a user enters a variant of a word, such as a pluralization, and we match that to the original word. As an example, if the user typed 'Eagles', but your products only contained the word 'Eagle', this would be a stem match.",
    "synonym_match": "A synonym match occurs when the original term has been placed through a synonym list. As an example, if you have a synonym of 'Eagle' set to 'Falcon', and we match 'Falcon' on a product when you type 'Eagle'.",
    "alternate_match": "An alternate match occurs in a number of different, unlikely ways, that are usually tried as a last resort to find a match. For example, if you type something like EAGLES12345, if we don't find any matches for the original search, we'll split between the word and the numbers, and search for 'EAGLES', and 12345, as an alternate match."
  };

  return (
    <Page
      disableThemes
      isFullyLoading={!searchConstants}
      isChanged={isChanged}
      isLoading={isLoading}
      permission="product-and-search-weights"
      onSave={() => saveConstants()}
    >{() =>
      Object.keys(searchConstants).sort().map((e) =>
         (
          <Card title={titleCase(e)}>
            <Card.Section>
              <TextContainer>
                <p>{tooltips[e]}</p>
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <RangeSlider
                output
                value={searchConstants[e]}
                suffix={<div>{searchConstants[e]}</div>}
                min={0}
                max={10}
                step={0.02}
                onChange={(w) => { setSearchConstants({ ...searchConstants, [e]: w }); setIsChanged(true); }}
              />
            </Card.Section>
          </Card>
        )
      )
    }</Page>
  );
}

export function Redirects() {
  const [isLoading, setIsLoading] = useState(true);
  const [redirects, setRedirects] = useState(null);
  const [error] = useState({});
  const [locale, setLocale] = useState(null);
  const [editingRedirect, setEditingRedirect] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const authFetch = useSpotFetch();

  useState(() => {
    if (!redirects) {
      authFetch("/api/search/redirects")
        .then((r) => {
          setIsLoading(false);
          setRedirects(r.redirects);
          setUpdatedAt(r.updated_at);
        })
    }
  }, [redirects]);

  const saveRedirects = useCallback((redirects) => {
    setIsLoading(true);
    return authFetch("/api/search/redirects", { json: { redirects: redirects, updated_at: updatedAt } }).then((r) => {
      setIsLoading(false);
      setRedirects(r.redirects);
      setEditingRedirect(null);
      setUpdatedAt(r.updated_at);
    })
  }, [authFetch, updatedAt]);

  const visibleRedirects = redirects && redirects.filter((r) => !r.locale || r.locale === locale);

  return (
    <Page
      disableThemes
      locale={locale}
      localization
      audit={{resource: "Redirect"}}
      setLocale={setLocale}
      permission="redirects"
      isFullyLoading={isLoading && !redirects}
      getResourceCount={() => { return 0; }}
      resourceName={{singular: "redirect", plural: "redirects"}}
      onBack={editingRedirect && (() => {
        setEditingRedirect(null);
      })}
      isLoading={isLoading}
      isChanged={isChanged}
      onSave={editingRedirect && (() => {
          if (editingRedirect.idx != null) {
            visibleRedirects[editingRedirect.idx] = { ...editingRedirect, idx: undefined };
            saveRedirects([...redirects.filter((option) => (option.locale || null) !== locale && locale !== null), ...visibleRedirects]);
          } else {
            saveRedirects([...redirects, editingRedirect]);
          }
        })
      }
    >
      {(currentTheme, currentLocale) => {
          return (<Layout>
            <Layout.Section>
              <MultiTableForm
                 breadcrumbs={editingRedirect ? [{content: 'Redirects', onAction: () => {
                  setEditingRedirect(null);
                }}] : []}
                resourceName={{singular: "redirect", plural: "redirects"}}
                isEditing={editingRedirect}
                hideTable={editingRedirect}
                editingForm={(editingRedirect && [
                  (<TextField error={error && error.term} key="term" label="Term" value={editingRedirect.term} onChange={(val) => { setIsChanged(true); setEditingRedirect({ ...editingRedirect, term: val }); }}/>),
                  (<Select value={editingRedirect.type} label="Match Type" onChange={(val) => { setIsChanged(true); setEditingRedirect({ ...editingRedirect, type: val }); }} options={[{ label: "Exact", value: "exact" }, { label: "Word", value: "word" }, { label: "Substring", value: "substring" }]}/>),
                  (<TextField error={error && error.url} key="url" label="Redirect URL" value={editingRedirect.url} onChange={(val) => { setIsChanged(true); setEditingRedirect({ ...editingRedirect, url: val }); }}/>),
                ])}
                onDragDrop={(source, destination) => {
                  let newRedirects = rearrangeArray(visibleRedirects, source, destination);
                  saveRedirects([...redirects.filter((option) => (option.locale || null) !== currentLocale), ...newRedirects]);
                }}
                onNew={() => {
                  setEditingRedirect({ term: "", type: "exact", url: "/", locale: currentLocale });
                  setIsChanged(true);
                }}
                bulkActions={[{
                  content: "Delete redirects",
                  onAction: (ids) => {
                    let redirectsHash = Object.fromEntries(ids.map((i) => [i,1]));
                    let targets = visibleRedirects.filter((e, idx) => redirectsHash[idx]);
                    saveRedirects([...redirects.filter((e) => targets.filter((i) => i === e).length === 0)]);
                  }
                }]}
                onRowClick={(row, idx) => {
                  setEditingRedirect({ ...visibleRedirects[idx], idx: idx });
                  setIsChanged(false);
                }}
                headings={[
                  "Term",
                  "Type",
                  "URL"
                ]}
                rows={visibleRedirects && visibleRedirects.map((r) => [
                  r.term,
                  r.type.charAt(0).toUpperCase() + r.type.slice(1),
                  r.url
                ])}
              />
            </Layout.Section>
          </Layout>);
      }}
    </Page>
    );
}


export function SearchPin({ setSearchPin, locale, searchPin, setIsChanged }) {
  const isLoadingDetails = searchPin && searchPin.products[0] && searchPin.products[0].length === 2;
  const spotAPI = useSpotAPI();
  useEffect(() => {
    if (isLoadingDetails) {
      spotAPI.s().paginate(1000).id("in", searchPin.products.map((p) => p[0])).e().done((results) => {
        const itemHash = Object.fromEntries(results.map((p) => [p.id, p]));
        setSearchPin({ ...searchPin, products: searchPin.products.map((p) => [p[0], p[1], itemHash[p[0]] ? itemHash[p[0]].images[0] : null, itemHash[p[0]] ? itemHash[p[0]].title : null  ]) });
      });
    }
  }, [isLoadingDetails, setSearchPin, searchPin, spotAPI]);
   return (<Layout>
    <Layout.Section>
      <Card title="Rules" sectioned>
        <FormLayout>
          <LocalizedTextField disabled={searchPin.ai} value={searchPin.term} onChange={(val) => { setIsChanged(true); setSearchPin({ ...searchPin, term: val })} } locale={locale} label="Term"/>
          <Select disabled={searchPin.ai} value={searchPin.type} label="Match Type" onChange={(val) => { setIsChanged(true); setSearchPin({ ...searchPin, type: val }); }} options={[{ label: "Exact", value: "exact" }, { label: "Word", value: "word" }, { label: "Stem", value: "stem" }]}/>
        </FormLayout>
      </Card>
      <Card>
        {isLoadingDetails && <Stack><Spinner size="large"/></Stack>}
        {!isLoadingDetails && (<><Card.Section>
          <ResourcePickerButton disabled={searchPin.ai} selectMultiple={10} primary label="Add product(s)" fullWidth={false} resourceType="product" onSelection={({ selection }) => {
            setSearchPin({ ...searchPin, products: [...searchPin.products, ...selection.map((p) => [p.id, 10.0, p.images[0], p.title])] })
            setIsChanged(true);
          }}/>
        </Card.Section>
        <Card.Section>
          <MultiTable
            newable={true}
            resourceName={{singular: "product pin", plural: "product pins"}}
            headings={["Image", "Title", "Weight", ""]}
            rows={searchPin.products.map((p, idx1) => [
              (p[2] ? <img alt={p[3]} src={spotAPI.getSizedImage(p[2], "64x64")}/> : <PlaceholderImage/>),
              (<ResourcePickerButton disabled={searchPin.ai} primary label={p[3] || p[0]} fullWidth={false} resourceType="product" onSelection={({ selection }) => {
                setIsChanged(true);
                setSearchPin({ ...searchPin, products: searchPin.products.map((e, idx2) => idx2 === idx1 ? [selection[0].id, e[1], selection[0].images[0], selection[0].title] : e) });
              }}/>),
              (<RangeSlider
                value={p[1]}
                disabled={searchPin.ai}
                min="1"
                max="100"
                onChange={(v) => { setIsChanged(true);  setSearchPin({ ...searchPin, products: searchPin.products.map((e, idx2) => idx2 === idx1 ? [e[0], v, e[2], e[3]] : e) })}}
                suffix={<div>{p[1]}</div>}
                output
              />),
              (<Button disabled={searchPin.ai} onClick={() => { setIsChanged(true); setSearchPin({ ...searchPin, products: searchPin.products.filter((e, idx2) => idx2 !== idx1) })}}><Icon source={DeleteMajor} color="base" /></Button>)
            ])}
          />
        </Card.Section></>)}
      </Card>
    </Layout.Section>
  </Layout>);
}

export function SearchPins() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [searchPins, setSearchPins] = useState(null);
  const [editingSearchPin, setEditingSearchPin] = useState(null);
  const [locale, setLocale] = useState(null);

  const authFetch = useSpotFetch();

  useState(() => {
    if (!searchPins) {
      authFetch("/api/search/boosts")
        .then((r) => {
          setIsLoading(false);
          setSearchPins(r.search_boosts);
        })
    }
  }, [searchPins, setSearchPins]);


  const normalSearchPins = searchPins && searchPins.filter((searchPin) => !searchPin.ai);
  const aiSearchPins = searchPins && searchPins.filter((searchPin) => searchPin.ai);

  return (
    <Page
      disableThemes
      locale={locale}
      audit={editingSearchPin && editingSearchPin.id && { resource: "SearchBoost", id: editingSearchPin.id }}
      localization
      permission="rules"
      setLocale={setLocale}
      isChanged={isChanged}
      isFullyLoading={isLoading && !searchPins}
      getResourceCount={() => { return 0; }}
      resourceName={{singular: "search pin", plural: "search pins"}}
      onBack={editingSearchPin && (() => {
        setEditingSearchPin(null);
        setIsChanged(false);
      })}
      isLoading={isLoading}
      onSave={editingSearchPin && !editingSearchPin.ai && (() => {
        authFetch("/api/search/boosts", { json: { search_boost: editingSearchPin } })
          .then((r) => {
            setSearchPins([...searchPins.map((e) => e.id === r.search_boost.id ? r.search_boost : e), ...(!editingSearchPin.id ? [r.search_boost] : [])]);
            setEditingSearchPin(null);
          });
      })}
    >
      {(currentTheme, currentLocale) => {
          if (editingSearchPin)
            return <SearchPin setIsChanged={setIsChanged} searchPin={editingSearchPin} setSearchPin={setEditingSearchPin}/>
          return (<Layout>
            <Layout.Section>
              <MultiTableForm
                 breadcrumbs={editingSearchPin ? [{content: 'Search Pins', onAction: () => {
                  setEditingSearchPin(null);
                }}] : []}
                resourceName={{singular: "search pin", plural: "search pins"}}
                onNew={() => {
                  setEditingSearchPin({ term: "", type: "exact", products: [] });
                }}
                bulkActions={[{
                  content: "Delete boosts",
                  onAction: (ids) => {
                    let searchPinHash = Object.fromEntries(ids.map((i) => [i,1]));
                    let targets = normalSearchPins.filter((e, idx) => searchPinHash[idx]);
                    setIsLoading(true);
                    authFetch("/api/search/boosts", { method: "DELETE", json: { ids: targets.map((p) => p.id) } }).then((r) => {
                      setIsLoading(false);
                      setSearchPins(normalSearchPins.filter((e, idx) => !searchPinHash[idx]));
                    });
                  }
                }]}
                onRowClick={(row, idx) => {
                  setEditingSearchPin(normalSearchPins[idx]);
                }}
                headings={[
                  "Type",
                  "Term",
                  "Products"
                ]}
                rows={normalSearchPins && normalSearchPins.map((r) => [
                  localeFormat(currentLocale, r.term),
                  r.type.charAt(0).toUpperCase() + r.type.slice(1),
                  r.products.length
                ])}
              />
              {aiSearchPins && aiSearchPins.length > 0 && (<Card sectioned title="AI Controlled Search Pins">
              <MultiTable
                headings={[
                  "Type",
                  "Term",
                  "Products"
                ]}
                onRowClick={(row, idx) => {
                  setEditingSearchPin(aiSearchPins[idx]);
                }}
                rows={aiSearchPins.map((r) => [
                  localeFormat(currentLocale, r.term),
                  r.type.charAt(0).toUpperCase() + r.type.slice(1),
                  r.products.length
                ])}
              /></Card>)}
            </Layout.Section>
          </Layout>);
      }}
    </Page>
    );
}


export function SearchOverride({ setSearchOverride, locale, rulesets, searchOverride }) {
   return (<Layout>
    <Layout.Section>
      <Card title="Rules" sectioned>
        <FormLayout>
          <LocalizedTextField value={searchOverride.term} onChange={(val) => setSearchOverride({ ...searchOverride, term: val })} locale={locale} label="Term"/>
          <Select value={searchOverride.type} label="Match Type" onChange={(val) => setSearchOverride({ ...searchOverride, type: val })} options={[{ label: "Exact", value: "exact" }, { label: "Word", value: "word" }, { label: "Stem", value: "stem" }]}/>
          <Select value={(searchOverride.ruleset_id || 0) + ""} label="Display Rule" onChange={(val) => setSearchOverride({ ...searchOverride, ruleset_id: val })} options={rulesets.map((r) => { return { label: r.handle, value: r.id + "" }; })}/>
        </FormLayout>
      </Card>
    </Layout.Section>
  </Layout>);
}


export function SearchOverrides() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [searchOverrides, setSearchOverrides] = useState(null);
  const [rulesets, setRulesets] = useState(null);
  const [editingSearchOverride, setEditingSearchOverride] = useState(null);
  const [locale, setLocale] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const redirect = useRedirect();

  const authFetch = useSpotFetch();
  const rulesetHash = rulesets && Object.fromEntries(rulesets.map((r) => [r.id, r]));

  useState(() => {
    if (!searchOverrides) {
      authFetch("/api/search/overrides")
        .then((r) => {
          setSearchOverrides(r.search_overrides);
          setIsLoading(!!r.rulesets);
          setUpdatedAt(r.updated_at);
        })
    }
  }, [searchOverrides, setSearchOverrides]);

  useState(() => {
    if (!rulesets) {
      authFetch("/api/global/rulesets")
        .then((r) => {
          setRulesets(r.rulesets);
          setIsLoading(!!r.search_overrides);
        });
    }
  });

  return (
    <Page
      disableThemes
      locale={locale}
      localization
      permission="rules"
      setLocale={setLocale}
      isFullyLoading={isLoading && !searchOverrides}
      getResourceCount={() => { return 0; }}
      resourceName={{singular: "search overrides", plural: "search overrides"}}
      onBack={editingSearchOverride && (() => {
        setEditingSearchOverride(null);
      })}
      isChanged={isChanged}
      isLoading={isLoading}
      onSave={() => {
        if (editingSearchOverride) {
          if (editingSearchOverride.index == null)
            searchOverrides.push(editingSearchOverride);
          else
            searchOverrides[editingSearchOverride.index] = editingSearchOverride;
        }
        authFetch("/api/search/overrides", { json: { search_overrides: searchOverrides, updated_at: updatedAt } })
          .then((r) => {
            setSearchOverrides(r.search_overrides);
            setEditingSearchOverride(null);
            setUpdatedAt(r.updated_at);
            setIsChanged(false);
          });
      }}
    >
      {(currentTheme, currentLocale) => {
          if (editingSearchOverride)
            return <SearchOverride rulesets={rulesets} searchOverride={editingSearchOverride} setSearchOverride={(val) => { setIsChanged(true); setEditingSearchOverride(val); }}/>
          if (rulesets && rulesets.length === 0)
            return (<Card sectioned><EmptyState heading={"No display rules set up"} action={{ content: "Set up a display rule", onAction: () => { redirect('/merchandising/rulesets') } }}>
              <p>Search overrides only work with display rules. To set up overrides, you must first set up a display rule.</p>
            </EmptyState></Card>);
          return (<Layout>
            <Layout.Section>
              <MultiTableForm
                resourceName={{singular: "search override", plural: "search overrides"}}
                onNew={() => {
                  setEditingSearchOverride({ term: "", type: "exact", ruleset_id: rulesets[0].id, updated_at: new Date().toISOString() });
                  setIsChanged(true);
                }}
                bulkActions={[{
                  content: "Delete overrides",
                  onAction: (ids) => {
                    let searchOverrideHash = Object.fromEntries(ids.map((i) => [i,1]));
                    let targets = searchOverrides.filter((e, idx) => !searchOverrideHash[idx]);
                    setIsLoading(true);
                    authFetch("/api/search/overrides", { json: { search_overrides: targets, updated_at: updatedAt } }).then((r) => {
                      setIsLoading(false);
                      setSearchOverrides(r.search_overrides);
                      setUpdatedAt(r.updated_at);
                    });
                  }
                }]}
                onDragDrop={(source, destination) => {
                  setSearchOverrides(rearrangeArray(searchOverrides, source, destination));
                  setIsChanged(true);
                }}
                onRowClick={(row, idx) => {
                  setEditingSearchOverride({ ...searchOverrides[idx], index: idx });
                }}
                headings={[
                  "Type",
                  "Term",
                  "Display Rule"
                ]}
                rows={searchOverrides && searchOverrides.map((r) => [
                  titleCase(r.type),
                  localeFormat(currentLocale, r.term),
                  rulesetHash && rulesetHash[r.ruleset_id] ? rulesetHash[r.ruleset_id].handle : "?"
                ])}
              />
            </Layout.Section>
          </Layout>);
      }}
    </Page>
    );
}



export function Personalizations() {
  const [profile] = Auth.useProfile();
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [boostRules, setBoostRules] = useState(null);

  const [personalizations, setPersonalizations] = useState(null);
  const [personalization, setPersonalization] = useState(null);
  const [rulesets, setRulesets] = useState(null);

  const authFetch = useSpotFetch();
  const redirect = useRedirect();
  const { personalizationId } = useParams();

  useEffect(() => {
    if (!rulesets) {
      setIsLoading(true);
      authFetch("/api/global/rulesets", { query: theme ? { theme_id: theme.id } : {} }).then((r) => {
        setBoostRules(r.boost_rules);
        setRulesets(r.rulesets);
        setPersonalizations(r.personalizations);
        setIsLoading(false);
      });
    }
  }, []);


  const needsToRedirect = personalizations && ((personalizationId && (!personalization || (personalizationId != "new" && personalization.id != personalizationId) || (personalizationId == "new" && personalization.id)) || (!personalizationId && personalization)));

  useEffect(() => {
    if (needsToRedirect && boostRules && rulesets && personalizations) {
      const selectedPersonalization = personalizationId && personalizationId != "new" && personalizations.filter((p) => p.id == personalizationId)[0];
      setPersonalization(selectedPersonalization ? selectedPersonalization : (personalizationId ? { add_product_to_cart_weight: 5, view_collection_weight: 1, view_product_weight: 1, view_variant_weight: 1, view_search_weight: 1, enable_facet_weight: 3, duration: "session", boost_rule_id: boostRules[0].id } : null));
    }
  }, [personalizationId, boostRules, rulesets, personalizations]);


  const rulesetHash = rulesets && rulesets.reduce((hash, r) => { hash[r.personalization_id] = hash[r.personalization_id] || []; hash[r.personalization_id].push(r); return hash; }, {});
  const boostRuleHash = boostRules && Object.fromEntries(boostRules.map((br) => [br.id, br]));
  const personalizationHash = personalizations && Object.fromEntries(personalizations.map((br) => [br.id, br]));

  return (
    <Page
      isFullyLoading={isLoading && !boostRules}
      disableThemes
      audit={personalization && personalization.id && {resource: "Personalization", id: personalization.id}}
      permission="boost-rules"
      getResourceCount={() => { return 0; }}
      setTheme={(theme) => {
        setTheme(theme);
        setBoostRules(null);
      }}
      theme={theme}
      resourceName={{singular: "personalization", plural: "personalizations"}}
      isChanged={isChanged}
      isLoading={isLoading}
      onBack={personalization && (() => { redirect("/search/personalizations"); })}
      onSave={personalization && (() => {
        setIsLoading(true);
        authFetch("/api/search/personalizations", { query: theme ? { theme_id: theme.id } : {}, json: { personalization: personalization } })
          .then((r) => {
            setIsLoading(false);
            setIsChanged(false);
            setPersonalization(null);
            if (personalization.id)
              setPersonalizations(personalizations.map((p) => p.id == personalization.id ? r.personalization : p));
            else
              setPersonalizations([...personalizations, r.personalization]);
            redirect("/search/personalizations");
          })
      })}
    >
      {(currentTheme, currentLocale) => {
        if (!profile || !personalization) {
          return (<MultiTableForm
            resourceName={{singular: "personalization", plural: "personalizations"}}
            onNew={boostRules.length > 0 && (() => {
              redirect('/search/personalizations/new');
            })}
            bulkActions={[{
              content: "Delete personaliazations",
              onAction: (ids) => {
                let personalizationOverrideHash = Object.fromEntries(ids.map((i) => [i,1]));
                let targets = personalizations.filter((e, idx) => personalizationOverrideHash[idx]);
                setIsLoading(true);
                authFetch("/api/search/personalizations", { json: { ids: targets.map((t) => t.id) }, method: "DELETE" }).then((r) => {
                  setIsLoading(false);
                  setPersonalizations(personalizations.filter((p, idx) => !personalizationOverrideHash[idx]));
                });
              }
            }]}
            onRowClick={(row, idx) => { redirect('/search/personalizations/' + personalizations[idx].id); }}
            headings={[
              "Name",
              "Boost Rule",
              "Display Rule(s)"
            ]}
            rows={personalizations && personalizations.map((p) => [
              p.name,
              boostRuleHash[p.boost_rule_id] ? boostRuleHash[p.boost_rule_id].name : "?",
              (<LocalizedBadgeList locale={currentLocale} maxToDisplay={5} values={rulesetHash && rulesetHash[p.id] && rulesetHash[p.id].map((r) => r.handle)}/>)
            ])}
          />);
        }
        return (<Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextField label="Name" value={personalization.name} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, name: val });  }}/>
                  <FormLayout.Group>
                    <Select label="Boost Rule" onChange={(r) => { setIsChanged(true); setPersonalization({ ...personalization, boost_rule_id: parseInt(r) }); }} options={
                      boostRules.map((rule) => { return { label: rule.name, value: rule.id + "" }; })
                    } value={(personalization.boost_rule_id || boostRules[0].id) + ""}/>
                    <Select label="Duration" onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, duration: val }); }} value={personalization.duration} options={[{ label: "Session", value: "session" }, { label: "Stored", value: "storage" }]}/>
                    <Select label="Segmentation" onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, segmentation: val }); }} value={personalization.segmentation || "segmented"} options={[{ label: "Segmented", value: "segmented" }, { label: "Filtered", value: "filtered" }, { label: "Unsegmented", value: "unsegmented" }]}/>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <RangeSlider output label="Search View" value={personalization.view_search_weight} suffix={<div>{personalization.view_search_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, view_search_weight: val }); }} />
                    <RangeSlider output label="Product View" value={personalization.view_product_weight} suffix={<div>{personalization.view_product_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, view_product_weight: val }); }} />
                    <RangeSlider output label="Variant View" value={personalization.view_variant_weight} suffix={<div>{personalization.view_variant_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, view_variant_weight: val }); }} />
                    <RangeSlider output label="Collection View" value={personalization.view_collection_weight} suffix={<div>{personalization.view_collection_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, view_collection_weight: val }); }} />
                    <RangeSlider output label="Enable Facet" value={personalization.enable_facet_weight} suffix={<div>{personalization.enable_facet_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, enable_facet_weight: val }); }} />
                    <RangeSlider output label="Product Add to Cart" value={personalization.add_product_to_cart_weight} suffix={<div>{personalization.add_product_to_cart_weight}</div>} min={0} max={100} step={1} onChange={(val) => { setIsChanged(true); setPersonalization({ ...personalization, add_product_to_cart_weight: val }); }} />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <p>A personalization scheme depends upon a <Link url="/merchandising/boost_rules">boost rule</Link> to function.<br/><br/></p>
                <p>Used as a base, the boost rule will look at the various fields that are returned in particular collections or searches that a customer is performing.<br/><br/></p>
                <p>If any of the rules match returned products, they will be used to change the order of search results, and recommendations presented to the user.
                Likewise, if a user clicks on particular items that also contain properties of the boost rule, they will also be augmented.</p>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <RulesetSidebar
              rulesets={rulesets}
              resourceName={{ singular: "personalization" }}
              selected={personalization.ruleset_ids}
              setSelected={(ids) => { setPersonalization({ ...personalization, ruleset_ids: ids }); setIsChanged(true); }}
              element={personalization}
              getElement={(ruleset) => { return ruleset.personalization_id && personalizationHash[ruleset.personalization_id]; }}
            />
          </Layout.Section>
        </Layout>);
      }}
    </Page>
    );
}

export function PopularSearches() {
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [addingTerm, setAddingTerm] = useState("");
  const [profile] = Auth.useProfile();
  const [updatedAt, setUpdatedAt] = useState(null);

  const [popularSearches, setPopularSearches] = useState(null);
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!popularSearches) {
      authFetch("/api/search/popular").then((r) => {
        setPopularSearches(r.popular_searches || {})
        setUpdatedAt(r.updated_at);
      });
    }
  }, [authFetch]);

  const addTerm = useCallback((localeCode) => {
    if (!popularSearches[localeCode])
      popularSearches[localeCode] = { manual: [] };
    popularSearches[localeCode].manual = [...(popularSearches[localeCode].manual || []), addingTerm.replace(/(^\s*|\s*$)/g, "")];
    setPopularSearches({ ...popularSearches });
    setIsChanged(true);
    setAddingTerm("");
  }, [popularSearches, addingTerm]);

  return (<Page
      disableThemes
      permission="popular-searches"
      isFullyLoading={!popularSearches || !profile}
      localization
      noAllLanguages
      getResourceCount={() => { return 0; }}
      resourceName={{singular: "popular search", plural: "popular searches"}}
      isChanged={isChanged}
      isLoading={isLoading}
      onSave={(() => {
        setIsLoading(true);
        authFetch("/api/search/popular", { json: { popular_searches: popularSearches, updated_at: updatedAt } }).then((r) => {
          setPopularSearches(r.popular_searches)
          setIsLoading(false);
          setIsChanged(false);
          setUpdatedAt(r.updated_at);
        });
      })}
    >
      {(currentTheme, currentLocale) => {
        const localeCode = currentLocale ? currentLocale.code : profile.shop.locales[0].code;
        return (<>
          <Card sectioned title="Computation Type">
            <Stack vertical>
              <RadioButton
                label="Based on your customer's search behvaiour"
                helpText="Will use actual searches your customers make that exactly match terms in your catalog, weighted by how often they make them. Will add in manual terms as highest priority."
                checked={!popularSearches.type || popularSearches.type === "search"}
                id="search"
                name="search"
                onChange={() => { setPopularSearches({ ...popularSearches, type: "search" }); setIsChanged(true); }}
              />
              <RadioButton
                label="Based on your product catalog"
                helpText="Will use your product catlaog as the only means of generating popular searches. Will add in manual terms as highest priority."
                id="products"
                name="products"
                checked={popularSearches.type === "products"}
                onChange={() => { setPopularSearches({ ...popularSearches, type: "products" }); setIsChanged(true); }}
              />
              <RadioButton
                label="Based on your manual terms only"
                helpText="Will only use your manual terms."
                id="manual"
                name="manual"
                checked={popularSearches.type === "manual"}
                onChange={() => { setPopularSearches({ ...popularSearches, type: "manual" }); setIsChanged(true); }}
              />
            </Stack>
          </Card>
          <Card title="Manual Terms">
            <Card.Section>
              <Form onSubmit={() => addTerm(localeCode)}>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField onChange={setAddingTerm} value={addingTerm} placeholder="search terms" />
                    <Button primary submit={true}>Add term</Button>
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card.Section>
            <Card.Section>
              {!popularSearches[localeCode] || !popularSearches[localeCode].manual || popularSearches[localeCode].manual.length === 0 ? (<EmptyState
                heading="You don't have any manual terms."
              >
                <p>Use the text box above to add some.</p>
              </EmptyState>) : (<Stack>{popularSearches[localeCode].manual.map((search, idx1) => {
                return (<Tag key={search} onRemove={() => {
                  popularSearches[localeCode].manual = popularSearches[localeCode].manual.filter((t, idx2) => idx1 !== idx2);
                  setPopularSearches({ ...popularSearches });
                  setIsChanged(true);
                }}>
                  {search}
                </Tag>);
              })}</Stack>)}
            </Card.Section>
          </Card>
        </>)
      }}
    </Page>
    );
}
