import React, {useState, useEffect, useCallback, useMemo, isValidElement } from 'react';
import { Auth, Stack, PermissionGuard, useRedirect, canRead, useRoute, useCreateModal, Page as AdminPage, Modal, Button, Icon, Spinner, Page as OldPage, Badge, Select, FormLayout, TextField, Prompt } from "admin-frontend";
import {
  LanguageMinor,
  ThemesMajor,
  PinMajor,
  ExternalSmallMinor
} from 'admin-frontend';
import { titleCase } from "./Fields.jsx"
import { SpotState } from "./API"
import { getLocaleSwitcherOption } from "./Localization.jsx"
import { useLocation, useHistory } from "react-router-dom";
import { useStatus, useThemes } from "./StatusContext";
import { ActivityProvider, ActivityBanner } from "./Activity"
import { useSpotFetch } from "../useSpotFetch";

export function PlaceholderImage({ size, ...props }) {
  return (<svg {...props} className={`placeholder--image${size && (' modd-size-' + size) || ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5"><path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path><path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path></svg>);
}

export function ExternalLink({ url, children })  {
	return <a href={url} rel="noreferrer" target="_blank"><Stack alignment="center">{children}<Icon source={ExternalSmallMinor} color="base"/></Stack></a>
}

export function ThemeOverrideModal({ title, description, primaryAction, secondaryActions = [], active, onClose }) {
  return (
    <div>
      <Modal
        open={active}
        onClose={onClose}
        title={title}
        primaryAction={primaryAction}
        secondaryActions={secondaryActions}
      >
        <Modal.Section>
          {description}
        </Modal.Section>
      </Modal>
    </div>
  );
}

export function stripPageUrl(location) {
  const params = new URLSearchParams(location.search);
  params.delete('timestamp');
  params.delete('shop');
  params.delete('hmac');
  params.delete('host');
  params.delete('locale');
  params.delete('embedded');
  params.delete('not_embedded');
  params.delete('id_token');
  params.delete('session');
  return location.pathname + (params.toString() ? `?${params.toString()}` : '');
}

export function Page({
  resourceName,
  resourceId,
  title,
  subtitle,
  overrideTitle,
  isChanged = true,
  shouldBlockNavigation = true,
  isLoading,
  isFullyLoading,
  isLiveCallback,
  onBack,
  audit,
  breadcrumbs,
  children,
  onSave,
  fullWidth,
  onPort,
  onDelete,
  noPin,
  tertiaryActions,
  getResourceCount,
  localization,
  noAllLanguages,
  overrideThemes,
  setTheme,
  primaryAction,
  secondaryActions,
  disableLocales,
  disableThemes,
  permission,
  spotState
}) {
  const [profile, profileError, invalidateProfile] = Auth.useProfile();
  const [currentLocale, setCurrentLocale] = useState(null);

  const themes = useThemes();
  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const themeId = params.get("theme_id");
  const [currentTheme, _setCurrentTheme] = useState(themeId && themes && themes.filter((t) => t.id === parseInt(themeId))[0]);
  const redirect = useRedirect();
  const createModal = useCreateModal();

  const setCurrentTheme = useCallback((theme) => {
    _setCurrentTheme(theme);
    if (setTheme)
      setTheme(theme);
  }, [setTheme]);

  const [changeModalThemeId, setChangeModalThemeId] = useState(null);
  const [isBackportModalActive, setBackportModalActive] = useState(false);
  const [backportModalThemeId, setBackportModalThemeId] = useState(0);
  const [isDeleteModalActive, setDeleteModalActive] = useState(false);

  const history = useHistory();
  const authFetch = useSpotFetch();

  useEffect(() => {
    if(profile && !profileError){
      if (themes && themeId && currentTheme === null)
        setCurrentTheme(themes.filter((t) => t.id === parseInt(themeId))[0]);
      else if (themes && themeId)
        setTheme(themes.filter((t) => t.id === parseInt(themeId))[0]);
      if (noAllLanguages && profile.shop.locales.length > 0)
        setCurrentLocale(profile.shop.locales[0]);
    }
  }, [themes, themeId, currentTheme, setTheme, setCurrentTheme, profile, profileError, noAllLanguages]);

  if (profile && canRead(profile.user, "audit")) {
    if (!tertiaryActions && audit)
      tertiaryActions = [];
    if (audit) {
      tertiaryActions = [...tertiaryActions, {
        content: 'View Audit Log',
        onAction: (() => { redirect(`/setup/audit?object_type=${audit.resource}${audit.id ? "&object_id=" + audit.id : ""}`); })
      }];
    }
  }

  let saveButton;
  if (onSave) {
    const actions = [];
    if (!currentTheme) {
      saveButton = (<Button
        primary
        loading={isLoading}
        disabled={!isChanged || isLoading}
        secondaryActions={tertiaryActions}
        onClick={() => onSave(null)}
      >
        Save
      </Button>)
    } else {
      if (onPort) {
        actions.push({
          content: 'Port into Target',
          onAction: () => setBackportModalActive(true)
        });
      }
      if (onDelete) {
        actions.push({
          content: 'Delete Override',
          onAction: () => setDeleteModalActive(true)
        });
      }
      (tertiaryActions || []).forEach((a) => actions.push(a));
      saveButton = (<Button
        primary
        disabled={!isChanged || isLoading}
        loading={isLoading}
        connectedDisclosure={actions.length > 0 && {
          accessibilityLabel: 'Other Actions',
          actions: actions,
        }}
        onClick={() => onSave(currentTheme)}
      >
        Save
      </Button>)
    }
  } else if (currentTheme) {
    const actions = [];
    if (onDelete) {
      actions.push({
        content: 'Delete Override',
        onAction: () => setDeleteModalActive(true)
      });
    }
    (tertiaryActions || []).forEach((a) => actions.push(a));
    if (onPort) {
      saveButton = (<Button
        primary
        disaled={!isChanged || isLoading}
        loading={isLoading}
        connectedDisclosure={actions.length > 0 && {
          accessibilityLabel: 'Other Actions',
          actions: actions,
        }}
        onClick={() => {
          setBackportModalActive(true)
        }}
      >
        Port
      </Button>);
    }
  }

  let actionGroups = []
  let actualSecondaryActions = secondaryActions || [];

  const liveTheme = isLiveCallback && themes && themes.filter((t) => isLiveCallback(t))[0];
  shouldBlockNavigation = onSave && isChanged && shouldBlockNavigation;
  const blockNavigationText = "You have unsaved changes. Are you sure you want to discard them?";

  const shouldHandleActivities = resourceId && resourceName;

  const [route, parentRoute] = useRoute();
  const [status] = useStatus();
  const isSynching = status && status.synching;
  const [activity, setActivity] = useState(null);

  const [favoriteDialog, setFavoriteDialog] = useState(null);

  if (saveButton && shouldHandleActivities)
    saveButton = (<ActivityProvider update="read" objectType={resourceName.singular.replaceAll(/ /g, "_")} activity={activity} setActivity={setActivity}><Stack alignment="center"><ActivityBanner activity={activity && activity[resourceId]} objectId={resourceId}/>{saveButton}</Stack></ActivityProvider>);

  const pageUrl = stripPageUrl(history.location);
  const hasFavorite = profile && profile.user.favorites.filter((f) => f.url == pageUrl).length > 0;
    
  const favoriteModal = (<Modal open={favoriteDialog} onClose={() => {
    setFavoriteDialog(null); 
  }} title={"Pin to Sidebar?"} primaryAction={{content: "Pin", onAction: () => {
    authFetch('/api/global/merchandising', { json: { favorites: [...profile.user.favorites, { ...favoriteDialog }] } }).then((r) => {
      invalidateProfile();
    }).finally(() => { 
      setFavoriteDialog(null);
    });
  } }} secondaryActions={[{ content: "Close", onAction: () => { setFavoriteDialog(null); } }]}>
    <Modal.Section>
      <FormLayout>
        <TextField label="Name" value={favoriteDialog && favoriteDialog.title} onChange={(val) => { setFavoriteDialog({ ...favoriteDialog, title: val }) }}/>
        <TextField label="URL" value={favoriteDialog && favoriteDialog.url} disabled/>
      </FormLayout>
    </Modal.Section>
  </Modal>);

  if (!noPin) {
    const favorites = profile.user.favorites || [];
    actualSecondaryActions = [...actualSecondaryActions, {
      content: (<Icon source={PinMajor} color={hasFavorite ? "primary" : "base"}/>),
      onAction: () => { 
        if (hasFavorite) {
          authFetch('/api/global/merchandising', { json: { favorites: profile.user.favorites.filter((f) => f.url != pageUrl) } }).then((r) => {
            invalidateProfile();
          });
        } else {
          setFavoriteDialog({ title: isValidElement(title) ? title.innerText : (title || route?.name), url: pageUrl });
        }
      }
    }];
  }
  if (overrideThemes || setTheme || disableThemes) {
    const button = (<Stack alignment="center">
      <Icon source={ThemesMajor}/>
      <span key='theme_id' style={{"lineHeight": "1.5em"}}>{(currentTheme ? currentTheme.name + (getThemeRole(currentTheme) !== "Live" ? " (" +  getThemeRole(currentTheme) + ")" : "") : "Global")}</span>
      {(((!currentTheme && !liveTheme) || (currentTheme && liveTheme === currentTheme.id)) && (<Badge progress="complete" status={isSynching ? "attention" : "success"}>{isSynching ? "Synching" : "Live"}</Badge>))}
    </Stack>);
    if (!disableThemes) {
      actionGroups.push({
        title: button,
        actions: [null, ...(themes || [{ name: "Loading...", role: "loading", id: 0 }])].filter((theme) => theme !== currentTheme).map((theme) => {
          return {
            content: (theme ? ("Override " + theme.name + " (" + getThemeRole(theme) + ")") : ("Global" + (!liveTheme ? " (Live)" : ""))),
            onAction: () => {
              if (theme) {
                if (!onPort || getResourceCount(theme.id) > 0) {
                  setCurrentTheme(theme);
                } else {
                  setChangeModalThemeId(theme.id);
                }
              } else {
                setCurrentTheme(null);
              }
            },
            disabled: disableThemes
          }
        })
      });
    } else {
      actualSecondaryActions.push({ content: button, onAction: () => { }, disabled: disableThemes });
    }
  }
  if (localization) {
    const localeSwitcher = getLocaleSwitcherOption({ locale: currentLocale, setLocale: setCurrentLocale, disabled: disableLocales });
    if (localeSwitcher)
      actionGroups.push(localeSwitcher);
  }
  function getThemeRole(theme) {
    if (isLiveCallback && isLiveCallback(theme))
      return "Live";
    return titleCase(theme.role);
  }

  const overrideContents = !disableThemes && onPort && themes && (
    <div>
      <Select
        label="Override Target"
        value={backportModalThemeId + ""}
        onChange={(id) => { setBackportModalThemeId(parseInt(id)); }}
        options={
          [{
            label: "Global" + (!liveTheme ? " (Live)" : "") ,
            value: "0"
          }, ...themes.filter((theme) => !currentTheme || theme.id !== currentTheme.id).map((theme) => { return {
            label: theme.name + " (" + getThemeRole(theme) + ")",
            value: "" + theme.id
          } })]
        }
      />
      <br/>
      This will port all currently visible {resourceName.plural} to the above target. This will override all settings for that target. Are you sure you want to do this?
    </div>
  );
  
  if (isFullyLoading)
    return (<Page><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></Page>);
  
  let oldPage = (<AdminPage
    title={overrideTitle || title || route?.name}
    subtitle={subtitle || route?.description}
    fullWidth={fullWidth}
    primaryAction={primaryAction || saveButton}
    secondaryActions={actualSecondaryActions}
    breadcrumbs={breadcrumbs || ((onBack || route?.parent?.path) ? [{content: (resourceName ? resourceName.plural : "Back"), onAction: () => {
      if (typeof(onBack) == 'function')
        onBack();
      else if (onBack)
        history.goBack();
      else
        redirect(route.parent.path);
    } }] : [])}
    actionGroups={actionGroups}
  >
    {shouldBlockNavigation && <Prompt
      message={(location, action) => blockNavigationText}
      key={"prompt"}
    />}
    {typeof(children) == "function" ? children(currentTheme, currentLocale, themes) : children}
    {!disableThemes && (<ThemeOverrideModal
      active={changeModalThemeId ? true : false}
      primaryAction={{
        content: "Port",
        onAction: () => {
          onPort(null, changeModalThemeId, true);
          setCurrentTheme(themes.filter((theme) => theme.id === changeModalThemeId)[0]);
          setChangeModalThemeId(null);
        }
      }}
      secondaryActions={[{
        content: "Cancel",
        onAction: () => setChangeModalThemeId(null)
      }, {
        content: "Start Empty",
        onAction: () => {
          setCurrentTheme(themes.filter((theme) => theme.id === changeModalThemeId)[0]);
          setChangeModalThemeId(null);
        }
      }]}
      title={onPort && ("Port " + resourceName.singular + "?")}
      description={onPort && ("You haven't created " + resourceName.plural + " for this theme before. Do you want to copy over all existing " + resourceName.plural + " from the global " + resourceName.singular + " list?")}
      onClose={() => setChangeModalThemeId(null)}
    />)}

    {!disableThemes && onPort && (
      <ThemeOverrideModal
        active={isBackportModalActive}
        primaryAction={{
          content: "Move",
          onAction: () => {
            onPort(currentTheme.id, backportModalThemeId ? backportModalThemeId : null, false);
            setCurrentTheme(themes.filter((t) => t.id === backportModalThemeId)[0]);
            setBackportModalActive(false);
          }
        }}
        secondaryActions={[{
          content: "Cancel",
          onAction: () => setBackportModalActive(false)
        }, {
          content: "Copy",
          onAction: () => {
            onPort(currentTheme.id, backportModalThemeId ? backportModalThemeId : null, true);
            setCurrentTheme(themes.filter((t) => t.id === backportModalThemeId)[0]);
            setBackportModalActive(false);
          }
        }]}
        title={"Port " + resourceName.plural + "?"}
        description={overrideContents}
        onClose={() => setBackportModalActive(false)}
      />
    )}
    {favoriteModal}
    {!disableThemes && onDelete && (
      <ThemeOverrideModal
        active={isDeleteModalActive}
        primaryAction={{
          content: "Confirm",
          onAction: () => {
            onDelete(currentTheme.id);
            setCurrentTheme(null);
            setDeleteModalActive(false);
          }
        }}
        secondaryActions={[{
          content: "Cancel",
          onAction: () => setDeleteModalActive(false)
        }]}
        title="Confirm Deletion?"
        description={"This will delete all " + resourceName.plural + " associated to this particular theme, reverting it to the default global " +  resourceName.plural + ". Are you sure you want to do this?"}
        onClose={() => setDeleteModalActive(false)}
      />
    )}
  </AdminPage>);
  if (spotState) 
    oldPage = (<SpotState init={(spotAPI, spotDOM, spotDefault) => { 
      if (currentLocale?.code != null && spotDOM.locale() != (currentLocale?.code))
        spotDOM.locale(currentLocale?.code); 
      }} 
    id={title} externalSearch={spotState == "external"}>{oldPage}</SpotState>);
  if (permission)
    return (<PermissionGuard permission={permission}>{oldPage}</PermissionGuard>);
  return oldPage;
}
