import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
  TextField,
  Select,
  Icon,
  Button,
  ButtonGroup,
  Stack,
  getPolarisId,
  Popover,
  ActionList,
  SearchMinor,
  CircleCancelMinor
} from 'admin-frontend';
import { useSpotFetch } from "../useSpotFetch";
import { Auth } from "admin-frontend";

export const productFields = [{
  "name": "Handle",
  "handle": "handle",
  "level": "product",
  "type": "string",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Title",
  "handle": "title",
  "level": "product",
  "type": "string",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Custom Product Type",
  "handle": "product_type",
  "level": "product",
  "type": "string",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Category",
  "handle": "category",
  "level": "product",
  "type": "string",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Created",
  "handle": "created",
  "level": "product",
  "type": "numeric",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Vendor",
  "handle": "vendor",
  "level": "product",
  "type": "string",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Manual Product Weight",
  "handle": "priority",
  "level": "product",
  "type": "numeric",
  "specifiers": [],
  "sortable": true,
  "public": false
}, {
  "name": "Sales",
  "handle": "sales",
  "level": "product",
  "type": "numeric",
  "specifiers": [],
  "sortable": true,
  "public": false
},{
  "name": "Inventory Quantity",
  "handle": "inventory_quantity",
  "level": "variant",
  "type": "numeric",
  "nullable": true,
  "specifiers": [],
  "sortable": false,
  "public": true
},{
  "name": "Online Inventory Quantity",
  "handle": "inventory_quantity_online",
  "level": "variant",
  "type": "numeric",
  "nullable": true,
  "specifiers": [],
  "sortable": false,
  "public": true
}, {
  "name": "Available",
  "handle": "available",
  "level": "variant",
  "type": "bool",
  "specifiers": [],
  "sortable": false,
  "public": true
}, {
  "name": "Tag",
  "handle": "tag",
  "level": "product",
  "type": "list",
  "specifiers": [],
  "sortable": false,
  "public": true
}, {
  "name": "Option 1",
  "handle": "option1",
  "level": "variant",
  "type": "both",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Option 2",
  "handle": "option2",
  "level": "variant",
  "type": "both",
  "specifiers": [],
  "sortable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "Option 3",
  "handle": "option3",
  "level": "variant",
  "type": "both",
  "specifiers": [],
  "sortable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "Option",
  "handle": "option",
  "level": "variant",
  "type": "both",
  "specifiers": ["name"],
  "sortable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "SKU",
  "handle": "sku",
  "level": "variant",
  "type": "both",
  "specifiers": [],
  "sortable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "Barcode",
  "handle": "barcode",
  "level": "variant",
  "type": "both",
  "specifiers": [],
  "sortable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "Product Metafield",
  "handle": "product-metafield",
  "level": "product",
  "type": "both",
  "specifiers": ["namespace", "key"],
  "sortable": true,
  "mandatory": false,
  "public": true
},{
  "name": "Product Custom Field",
  "handle": "product-custom-field",
  "level": "product",
  "type": "both",
  "specifiers": ["handle"],
  "sortable": true,
  "public": true
}, {
  "name": "Variant Metafield",
  "handle": "variant-metafield",
  "level": "variant",
  "type": "both",
  "specifiers": ["namespace", "key"],
  "sortable": true,
  "nullable": true,
  "mandatory": false,
  "public": true
}, {
  "name": "Variant Custom Field",
  "handle": "variant-custom-field",
  "level": "variant",
  "type": "both",
  "nullable": true,
  "specifiers": ["handle"],
  "sortable": true,
  "public": true
}, {
  "name": "Price",
  "handle": "price",
  "level": "variant",
  "type": "numeric",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Collection",
  "handle": "collection",
  "level": "product",
  "type": "collection",
  "specifiers": [],
  "sortable": false,
  "public": false
}, {
  "name": "Compare at Price",
  "handle": "compare_at_price",
  "level": "variant",
  "type": "numeric",
  "nullable": true,
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Published Date",
  "handle": "published_at",
  "level": "product",
  "type": "date",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Created Date",
  "handle": "created_at",
  "level": "product",
  "type": "date",
  "specifiers": [],
  "sortable": true,
  "public": true
}, {
  "name": "Product",
  "handle": "id",
  "level": "product",
  "type": "product",
  "specifiers": [],
  "public": true
}, {
  "name": "Variant",
  "handle": "variant_id",
  "level": "variant",
  "type": "variant",
  "specifiers": [],
  "public": true
}, {
  "name": "Inventory Level",
  "handle": "inventory_level",
  "level": "variant",
  "type": "numeric",
  "specifiers": ["location_id"],
  "sortable": true,
  "public": true
}, {
  "name": "Split Product",
  "handle": "split_id",
  "level": "product",
  "type": "product",
  "specifiers": [],
  "public": true
}];

export function titleCase(title) {
  if (title === null || title === undefined)
    return title;
  return title.split(/[\s_]+/).map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(" ");
}

/* Resuable dialog, specifies a field to be specified. */
export function FieldSpecifier({ fields = productFields, label, disabled, publicOnly = false, field = null, sortable = null, level = "both", specifiersOnly = false, chooseFromList = false, chooseValue = false, type = "both", idx = 0, extraFields = [], split = null, merge = null, onChange }) {
  let validFields = fields.filter((e) => level === "both"|| e.level === "both" || e.level === level).filter((e) => type === "both" || e.type === "both" || e.type === type || (typeof(type) == "object" && type.includes(e.type)))
    .filter((e) => sortable === null || e.sortable === sortable).concat(extraFields).filter((e) => !specifiersOnly || (e.type === "list" && chooseFromList) || e.specifiers.length > 0).filter((e) => !publicOnly || e.public);

	const currentField = validFields.filter((e) => field && e.handle === field[0])[0];
	const [visiblePopovers, setVisiblePopovers] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [loaded, setLoaded] = useState(false);
	const [sections, setSections] = useState([])
	const [metafields, setMetafields] = useState([])
	const [customFields, setCustomFields] = useState([])

	const authFetch = useSpotFetch();
	const [profile] = Auth.useProfile();

	const OPTIONS_INCRIMENT = 5; 

	const handleOnOpenPopover = (fieldId) => {
		handleSearch('')
		setVisiblePopovers((prev) => ({
		  ...prev,
		  [fieldId]: true,
		}));
	  };
	const handleOnClosePopover = (fieldId) => {
		setVisiblePopovers((prev) => ({
			...prev,
			[fieldId]: false,
		}));
	};
	
	const filterItems = useCallback(
    (section, length = null) => {
      var filteredItems = section;

      if (searchValue && filteredItems.length > 0) {
        // Filter for array of strings
        filteredItems = section.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      if (filteredItems.length > 0) {
        if (length) filteredItems = filteredItems.slice(0, length);
        return filteredItems;
      } else return [];
    },
    [searchValue]
  );
  
	const getAction = useCallback(
    (iField, handle, fillValues) => {
      const targetField = validFields.filter((e) => e.handle === handle)[0];
      const specifierLength =
        targetField.specifiers.length +
        (((targetField.type === "list" && chooseFromList) || chooseValue) ? 1 : 0);

      const changeField = [handle].concat(
        Array(specifierLength)
          .fill(null)
          .map((_, idx) => {
            if (!fillValues) return null;
            const specifier = targetField.specifiers[idx]
              ? targetField.specifiers[idx] === "key"
                ? "metaKey"
                : targetField.specifiers[idx]
              : null;
            return specifier ? iField[specifier] : null;
          })
      );
      return () => {
        onChange(changeField);
        handleOnClosePopover(0);
      };
    },
    [chooseFromList, onChange, validFields]
  );

	const createSection = useCallback(
    (sectionFields, properties) => {
      if (!sectionFields.length > 0) {
        return {};
      }
      const { title, prefix, handle: handleOverride, fillValues, custom } = properties;
      const section = { title, items: [] };
      const validHandles = new Set(validFields.map((field) => field.handle));
      if (handleOverride && !validHandles.has(handleOverride)) return {}; // some pages may not include variant-custom-fields, ex

      sectionFields.forEach((iField) => {
        const { handle, name } = iField;
        const content = prefix ? `(${prefix}) ${name}` : name;
        const actionFunction = getAction(
          iField,
          handleOverride || handle,
          fillValues
        );

        section.items.push({
          ...iField,
          content,
          onAction: actionFunction,
        });
      });
	  if(custom){
		const specifierLength = validFields.filter((field) => field.handle === handleOverride)[0].specifiers.length;
		var customArray = Array(1+specifierLength).fill(null);
		customArray[0] = handleOverride
		section.items.push({content: `[Manual ${title}]`, onAction: ()=>{onChange(customArray); handleOnClosePopover(0); }})
	  }
      return section;
    },
    [validFields, getAction, onChange]
  );

	const handleSearch = useCallback((searchString) => {
		setSearchValue(searchString)
		//first we filter the field sections based on search string. Then organize them into section objects. Then join valid sections for selection
		const productFields = validFields.filter((field) => !field.handle.includes("metafield") && !field.handle.includes("custom-field"))
		const filteredFields = filterItems(productFields);
		const filteredMetafields = filterItems(metafields, OPTIONS_INCRIMENT);
		const filteredProductCustomFields = filterItems(customFields.filter(field => field.level === "product"));
		const filteredVariantCustomFields = filterItems(customFields.filter(field => field.level === "variant"));

		const metafieldProperties = { title: "Product Metafield", handle: "product-metafield", fillValues: true, custom: true }
		const productCustomFieldProperties = { title: "Product Custom Field", handle: "product-custom-field", fillValues: true }
		const variantCustomFieldProperties = { title: "Variant Custom Field", handle: "variant-custom-field", fillValues: true }

		const detailsSection = createSection(filteredFields, {title: "Product Details"});
		const metaSection = createSection(filteredMetafields, metafieldProperties);
		const productCustomSection = createSection(filteredProductCustomFields, productCustomFieldProperties);
		const variantCustomSection = createSection(filteredVariantCustomFields, variantCustomFieldProperties);

		const validSections = [];
		for(let section of [detailsSection, metaSection, productCustomSection,variantCustomSection]){
			if (section.title) validSections.push(section)
		}
		setSections(validSections);
	}, [customFields, filterItems, metafields, validFields, createSection])

	const getFieldName = useCallback((currentField) => {
		if(!currentField || metafields.length === 0 || customFields.length === 0) return ""
		var fieldReturn = currentField.name;
		if(currentField.handle.includes("metafield")){
			const metaFiltered = metafields.filter((metafield) => field[1]===metafield.namespace && field[2]===metafield.metaKey)
			fieldReturn = metaFiltered[0] && metaFiltered[0].name ? `(MF) ${metaFiltered[0].name}` : fieldReturn
		}
		if(currentField.handle.includes("custom-field")){
			const customFiltered = customFields.filter((customField) => field[1]===customField.name);
			fieldReturn = customFiltered[0] && customFiltered[0].name ? `(CF) ${customFiltered[0].name}` : fieldReturn
		}
		return fieldReturn || "";

	}, [customFields, field, metafields])

	useEffect(() => {
		if (!loaded) {
			setMetafields(
				profile.shop.extra_shopify.metafield_definitions.sort((a, b) => {
					if (a.collection_condition === b.collection_condition) return 0;
					return a.collection_condition ? -1 : 1;
				})
				.map(({ key, ...rest }) => ({
					...rest, 
					metaKey: key, // Rename `key` to `metaKey` since it is reserved
				  }))
			);
			authFetch("/api/global/custom_fields").then((r) => {
				setCustomFields(r.custom_fields.map((field) => ({...field, name: field.handle})));
			});
			setLoaded(true);
		}
	}, [authFetch, handleSearch, loaded, profile.shop.extra_shopify.metafield_definitions]);

  useEffect(() => {
    if (currentField) {
      const specifierLength = currentField.specifiers.length + (((currentField.type === "list" && chooseFromList) || chooseValue) ? 1 : 0);
      if (!field || field.length !== specifierLength + 1) {
        let array = [currentField.handle];
        for (var i = 0; i < specifierLength; ++i)
          array.push(null);
        onChange(array)
      }
    } else {
      onChange([validFields[0].handle]);
    }
  }, [field, onChange, validFields, chooseFromList, chooseValue, currentField]);

	if (!field)
		return <></>;
	if (!currentField)
		throw new TypeError("Invalid field selected: " + field[0]);

	validFields.map((e) => {
        return { content: e.name, onAction: () => {} };
      })

	const activator = (
    <Button
      onClick={() => {
        handleOnOpenPopover(0);
      }}
      disclosure={false}
      fullWidth={false}
      distribution="flexStart"
      className='modd-action-list'
      style={{whiteSpace: "nowrap"}}
    >
      {getFieldName(currentField)}
    </Button>
  );
  let filledSpecifiers = 0;
  if (currentField.handle.includes("metafield") && metafields.filter((metafield) => field[1]===metafield.namespace && field[2]===metafield.metaKey).length > 0)
    filledSpecifiers = 2;
	else if (currentField.handle.includes("custom"))
    filledSpecifiers = 1;
	const showInputs = field.length > 1+filledSpecifiers;

	return (
		<Stack fill={true} wrap={false}>
			<Popover
				active={visiblePopovers[0]}
				activator={activator}
				onClose={() => {
					handleOnClosePopover(0);
				}}
				preferredAlignment="left"
				preventCloseOnChildOverlayClick
				key={"spec-" + idx + "-" + 0}
				>
				<div style={{ padding: "16px", width: "300px", overflowY: "auto", maxHeight: "500px", }} >
					<SearchField value={searchValue} onChange={handleSearch} placeholder="Search to find more options"/>
					<ActionList style={{ marginTop: "8px" }} sections={sections} />
				</div>
			</Popover>
			{
				showInputs && field.slice(1+filledSpecifiers).map((e1, idx1) => 
					<TextField
					placeholder={currentField.specifiers[idx1 + filledSpecifiers]}
					label={
						label &&
						titleCase(currentField.specifiers[idx1 + filledSpecifiers])
					}
					key={"spec-" + idx + "-" + idx1}
					value={e1}
					onChange={(value) => {
						onChange(
						field.map((e2, idx2) => (idx1 + filledSpecifiers + 1 === idx2 ? value : e2))
						);
					}}
					/>
				)
			}
		</Stack>
	  );
	}

export function convertToFrontend(properties) {
  return properties != null && typeof(properties) == "object" ? [Object.keys(properties)[0], ...convertToFrontend(Object.values(properties)[0])] : [properties];
}

export function convertFromFrontend(properties) {
  if (properties.length === 1)
    return properties[0];
  var object = {};
  var current = object;
  properties.slice(0, -2).forEach((p, idx) => { current[p] = {}; current = current[p]; });
  current[properties[properties.length - 2]] = properties[properties.length - 1];
  return object;
}

export function getOperators(field) {
  let quantitativeOperators = ["equal to", "not equal to", "less than", "greater than", "greater than or equal to", "less than or equal to"];
  let qualitativeOperators = ["starts with", "ends with", "contains", "does not contain"];
  const exactOperators = ["equal to", "not equal to"];
  const listOperators = ["equal to", "not equal to", "starts with"];
  const type = typeof(field) === "object" ? field.type : field;
  if (typeof(field) === "object" && field.mandatory === false) {
    quantitativeOperators = quantitativeOperators.concat(["exists", "doesn't exist"]);
    qualitativeOperators = qualitativeOperators.concat(["exists", "doesn't exist"]);
  }
  if (typeof(field) === "object" && field.nullable === true) {
    quantitativeOperators = quantitativeOperators.concat(["is not empty", "is empty"]);
    qualitativeOperators = qualitativeOperators.concat(["is not empty", "is empty"]);
  }
  if (type === "numeric")
    return quantitativeOperators;
  else if (type === "string")
    return exactOperators.concat(qualitativeOperators);
  else if (type === "list")
    return listOperators;
  else if (type === "collection" || type === "product" || type === "variant")
    return exactOperators;
  else if (type === "bool")
    return ["is true", "is false"];
  return Object.keys(Object.fromEntries([...quantitativeOperators, ...qualitativeOperators].map((e) => [e, 1])));
}

export function CachedTextField({ value, idle, onChange, ...props }) {
  const [originalValue, setOriginalValue] = useState(value);
  const [internalValue, setInternalValue] = useState(value);
  const idleTimeout = useRef(null);
  useEffect(() => {
    if (value != internalValue || value != originalValue)
      onChange(value);
    setInternalValue(value);
    setOriginalValue(value);
  }, [value]);
  const onChangeReset = (type, event, value) => {
    const ret = onChange(value != null ? value : internalValue, type, event);
    if (ret != null && !ret)
      setInternalValue(originalValue);
    else
      setOriginalValue(value != null ? value : internalValue);
  };
  return (<TextField 
    {...props}
    value={internalValue}
    onFocus={(e) => {
      if (e.target.type != "number")
        e.target.setSelectionRange(0, e.target.value.length);
    }}
    onChange={(value, e) => {
      setInternalValue(value);
      if (idle) {
        if (idleTimeout.current)
          clearTimeout(idleTimeout.current);
        idleTimeout.current = setTimeout(() => {
          onChangeReset("idle", e, value);
        }, idle*1000);
      }
    }}
    onKeyPress={(e) => {
      if (e.nativeEvent.keyCode == 13) { // Fucking react sets this to 0 on their SyntheticEvent.
        onChangeReset("enter", e);
      }
    }}
    onKeyDown={(e) => {
      if (e.nativeEvent.keyCode == 9) {
        onChangeReset(e.nativeEvent.shiftKey ? "shift+tab" : "tab", e);
      }
    }}
    onBlur={(e) => {
      onChangeReset("blur", e);
    }}
  />);
}

export function SearchField({ value, onChange, ...props }) {
  return <CachedTextField
    idle={0.2}
    prefix={<Icon source={SearchMinor} />}
    postfix={value != "" && value != null && <Button plain square onClick={() => { onChange(""); }}><Icon source={CircleCancelMinor} /></Button>}
    value={value}
    onChange={onChange}
    {...props}
  />
}

export function JSONField({ value, onChange, ...props }) {
  const [internalValue, setInternalValue] = useState((value == null ? "" : (typeof(value) == 'object' ? JSON.stringify(value) : value)));
  useEffect(() => {
    setInternalValue((value == null ? "" : (typeof(value) == 'object' ? JSON.stringify(value) : value)));
  }, [value]);
  let jsonError = null;
  let parsedJson = null;
  if (!/^\s*$/.test(internalValue)) {
    try {
      parsedJson = JSON.parse(internalValue);
    } catch (e) {
      jsonError = e.toString();
    }
  }
  return <TextField error={jsonError} value={internalValue} onBlur={(value) => { 
    if (parsedJson) {
      onChange(parsedJson);
    } else if (/^\s*$/.test(internalValue)) {
      onChange(null);
    }
  }} onChange={setInternalValue} {...props}/>
}
